import React from 'react';


import Slider from "react-slick";

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Container } from './styles';

interface ISlider {
  autoplay?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  arrows?: boolean;
  easing?: string;
  list: Array<JSX.Element>;
}

const SliderContainer: React.FC<ISlider> = ({
  autoplay = false,
  speed = 2000,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrows = true,
  easing = 'easeOutElastic',
  children,
  list
}) => {

  const settings = {
    infinite: false,
    autoplay,
    autoplaySpeed: speed,
    speed,
    slidesToShow: list.length >= 4 ? 4 : list.length,
    slidesToScroll,
    dots: false,
    arrows: true,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 5200,
        settings: {

          slidesToShow: list.length >= 1 ? 1 : list.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {

          slidesToShow: list.length >= 1 ? 1 : list.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: list.length >= 1 ? 1 : list.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: list.length >= 1 ? 1 : list.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Button = ({ type, onClick }) => {

    if (type === 'PREV') {
      return <button onClick={onClick} className='directionButtonLeft'><FaArrowLeft /></button>
    }
    if (type === 'NEXT') {
      return <button onClick={onClick} className='directionButtonRight'><FaArrowRight /></button>
    }
    return <></>

  }
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];


  return (
    <Container>
      <Slider   {...settings}>{list}</Slider>
    </Container>

  )
};

export default SliderContainer;
