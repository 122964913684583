import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaSpotify, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { Container } from './styles';

interface IProps {
  url: string;
  text: string;
  imageUrl: string;
}

interface ISocial {
  list: Record<string, any>[];
}

export const SocialShareElements: React.FC<ISocial> = ({ list }) => {

  const types: Record<string, any> = {
    facebook: <FaFacebook />,
    instagram: <FaInstagram />,
    youtube: <FaYoutube />,
    linkedin: <FaLinkedin />,
    spotify: <FaSpotify />
  }


  return (
    <Container className='row'  >

      {list?.map(item => {
        return <nav><a href={item?.url} tabIndex={0} title={`VISITAR O ${item?.type?.toUpperCase()}`} target="_blank" rel="noopener noreferrer">{types?.[item?.type]}</a></nav>

      })}

    </Container>
  );
}

function SocialShare({ url, text = '', imageUrl }: IProps) {
  // URLs de compartilhamento formatadas
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text + " " + url)}`;
  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}&summary=${encodeURIComponent(text)}&source=${encodeURIComponent(url)}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;

  return (
    <Container>


      <nav><a href={whatsappUrl} tabIndex={0} title="Compartilhar no Whatsapp" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a></nav>
      <nav><a href={linkedInUrl} tabIndex={0} title="Compartilhar no Linkedin" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></nav>
      <nav><a href={facebookUrl} tabIndex={0} title="Compartilhar no Facebook" target="_blank" rel="noopener noreferrer"><FaFacebook /></a></nav>

    </Container>
  );
}

export default SocialShare;


