import React, { Children } from 'react';

import { Container } from './styles';

interface IVideoBackgroundProps {
  path: string;
  thumb?: string;
  background?: string;
}

const VideoBackground: React.FC<IVideoBackgroundProps> = ({
  children,
  path = '',
  thumb = '',
  background = '',
}) => {
  return (
    <Container background={background}>
      <video autoPlay loop muted poster={thumb}>
        <source src={path} type="video/mp4" />
        Ops, seu navegador não suporta Vídeos
      </video>
      <img src={thumb} alt="" />
      <aside />

      {children}
    </Container>
  );
};

export default VideoBackground;
