import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useModal } from '../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();

  const handleSendMail = async (id: string): Promise<void> => {
    try {
      const response = await api.post(`/v3/send-ticket-sale-mail/${id}`);


      addToast({ title: 'Enviado com sucesso!', type: 'success' });
      removeModal('send')
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const handleSend = () => {

    addModal({
      key: 'send',
      title: 'Enviar ingresso?', theme: 'whiteModalMini', content: <> <button onClick={() => handleSendMail(lineData?.ticket_hash)} className='greenButton2 buttonColored'>
        Enviar</button></>
    })
  }

  const moduleUpdate = (id: string): JSX.Element => (
    lineData.ticket_hash && lineData.payment_status_id === 'paid' && lineData.client_id_response?._id ? <button className="lineIcon" title="Enviar e-mail com o Ingresso" type="button" onClick={() => handleSend()}>
      <FiSend />
    </button> : <></>
  );

  return moduleUpdate(lineData.ticket_hash);
}
