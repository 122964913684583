import React, { useState } from 'react';
import { FaChalkboardTeacher, FaFileExcel, FaSpinner } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  
 const ExportSurveyList : React.FC<ICreateModule> = ({
    lineData,
  } ) => {

    const {user} = useAuth();
    const [loading, setLoading] = useState(false);

    const getDownload = async (data) => {
      setLoading(true);
      await downloadit({url : `${apiUrl}/get-research-list/${data._id}`});
      setLoading(false);
    }

  const moduleList  = ({data}): JSX.Element => { 

    return <> { user && user.profile === 1 ? loading ? <FaSpinner style={{margin:'5px', cursor:'pointer'}} className='rotateAnimation' size={20} /> : <FaFileExcel style={{margin:'5px', cursor:'pointer'}} color="#333" onClick={() => getDownload(data)} size={30}/>    : <></>}
     </>;
  }
     
  

  return moduleList({data: lineData});
}

export default ExportSurveyList;