import React from 'react';
import FadeInOnScroll from '../../../../../../components/FadeInOnScroll';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import { ParagraphHighlightUI, TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import RenderPageImages from "../../modules/RenderPageImages";

const TextHighlightWithImagePageTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;

  return (
    <>

      <ContainerLineUI className='full-width row-to-column pad-container-computer ' id={hash} style={{ display: 'flex', position: 'relative', alignItems: 'stretch', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : '#fff', minHeight: '60vh' }} >
        {pageContent?.image ? <><div className="full-width computerOnly " style={{ position: 'absolute', opacity: 0.5, top: '0px', left: 0, right: 0, bottom: 0, zIndex: 0, background: `url("${urlWebsite}/${pageContent?.image}")`, backgroundSize: 'cover', minHeight: '30vh' }} /> <div className="full-width mobileOnly " style={{ background: `url("${urlWebsite}/${pageContent?.image}")`, backgroundSize: 'cover', minHeight: '30vh' }} /> </> : <></>}
        {pageContent?.theme_data?.hide_side_content !== 'yes' ? <div className='pad-xl  column max-600 gap' style={{ margin: '0px auto', background: pageContent?.theme_data?.background, zIndex: 100, alignItems: 'flex-start' }}>
          {pageContent?.theme_data?.show_title !== 'no' ? <FadeInOnScroll style={{ display: 'flex', justifyContent: 'center' }}><TitleHighlightUI color={pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333'} background={pageContent?.theme_data?.title_background ? pageContent?.theme_data?.title_background : ''} style={{ textAlign: 'left', width: 'auto' }} dangerouslySetInnerHTML={{ __html: pageContent?.title }} /></FadeInOnScroll> : <></>}
          <FadeInOnScroll><ParagraphHighlightUI style={{ textAlign: 'left' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} className='midTitle max-600  ' dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} /></FadeInOnScroll>

          {pageContent?.files && pageContent?.files.map((item: Record<string, any>) => {
            return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
          })}

          {pageContent?.buttons?.length > 0 ? <div style={{ margin: '0px auto' }}>
            {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
              return <a href={`${itemButton.link}`} ><FadeInOnScroll><button className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title}</button></FadeInOnScroll></a>
            })}
          </div> : <></>}
        </div> : <></>}

        {pageContent?.map_link ? <div style={{ width: '100%', maxWidth: '400px', borderRadius: '15px', overflow: 'hidden' }}  > <iframe src={pageContent?.map_link} width="600" height="450" style={{ 'border': 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> </div> : <></>}

        {pageContent?.video ? <VideoBackground path={pageContent?.video} /> : <></>}


      </ContainerLineUI>
    </>
  );


}

export default TextHighlightWithImagePageTemplate;