import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';


import { useConfiguration } from '../../../../hooks/Configuration';

import { useAuth } from '../../../../hooks/Auth';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const SaleReport: React.FC = () => {
  const endpoint = 'v3/sales-report';
  const title = 'Relatório de vendas';
  const { user } = useAuth();
  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    email: { model: 'input', type: 'text', name: 'email', label: 'E-mail' },


    with_finance: {
      model: 'selectSimple',
      name: 'with_finance',
      label: 'Com financeiro',
      defaultValue: '',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ],
    },
    with_new_users: {
      model: 'selectSimple',
      name: 'with_new_users',
      label: 'Com novos usuários',
      defaultValue: '',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ],
    },
  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'Protocolo', show: false },
    project_id: {
      ref: 'project_id',
      column: 'project_id',
      label: 'Projeto',

    },

    name: {
      ref: 'name',
      column: 'name',
      label: 'Nome',
      show: true,
    },
    email: {
      ref: 'email',
      column: 'email',
      label: 'E-mail',
      show: true,
    },

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: user.email === 'guilherme@encontrodigital.com.br' ?
      [


        (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchema,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),

      ] : [

      ],
  };

  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default SaleReport;
