import styled from "styled-components";

export const ContentContainer = styled.div`

 display: flex ;
flex-direction: row;
align-items: flex-start;
justify-content: center;
width: 100%;

@media (max-width: 750px) {
    flex-direction: column;
align-items:center ;
justify-content: flex-start;
  }

`;
