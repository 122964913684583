import React from 'react';
import { useParams } from 'react-router-dom';
import format from '../../../../../../utils/format';

import calculateFinalPrice from '../../Functions/calculateFinalPrice';

interface IProps {
    coupon?: Record<string, any>;
    paymentMethod: string;
    cart: Array<Record<string, any>>;
    installmentValue: number;

}

const DisplayCartPrice: React.FC<IProps> = ({
    coupon,
    paymentMethod,
    cart,
    installmentValue

}) => {
    const { product_hash } = useParams<Record<string, any>>();
    return <> {product_hash !== 'courtesy' ? <h2 style={{ margin: '10px 0px', color: '#333', width: '100%', textAlign: 'center' }}>Total: R$ {format(calculateFinalPrice({ cart, coupon, paymentMethod: paymentMethod, installments: installmentValue })
    )}</h2> : <></>}


    </>

}

export default DisplayCartPrice;
