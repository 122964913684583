import React from 'react';
import Marquee from 'react-fast-marquee';

import { ContainerLineUI } from '../../../../../../components/UIComponents/ContainerLine/styles';

import { urlWebsite } from '../../../../../../config';
import { BallListContainer } from './styles';
interface IImage {
    url: string;
    title?: string;
    subtitle?: string;
    link?: string;
}

interface IProps {
    images: Array<IImage>,
    template?: string;
}
const RenderIcons: React.FC<IProps> = ({ images }) => {

    return <>
        <div className='full-width' style={{ padding: '50px 15px' }}>
            <Marquee pauseOnHover gradientWidth={30} speed={40} style={{ width: '100%' }}>
                {images.map(image => {
                    return <ContainerLineUI className='pad-sm'> <BallListContainer className='column max-150 pad' style={{ background: `url("${urlWebsite}/${image.url}")` }}>

                    </BallListContainer></ContainerLineUI>
                })}

            </Marquee>
        </div>

    </>

}
const RenderPageImagesSponsors: React.FC<IProps> = ({ images, template = 'icons' }) => {

    const items = images?.reduce((prev: JSX.Element[], image) => {
        prev.push(<img src={`${urlWebsite}/${image.url}`} className="full-width" />);
        return prev;
    }, [] as JSX.Element[]);


    const templates: Record<string, any> = {
        default: () => items?.length > 0 ? <div style={{ width: '100%', maxWidth: '400px', borderRadius: '15px', overflow: 'hidden' }} className={"ImageSlider"}>{images?.length === 1 ? <img src={`${urlWebsite}/${images?.[0]?.url}`} /> : <img src={`${urlWebsite}/${images?.[0]?.url}`} />
        } </div> : <></>,
        icons: () => <RenderIcons images={images} />
    }


    if (!images || !templates?.[template] || images.length < 1) {
        return <></>
    }

    return <>{templates?.[template]()}</>


}

export default RenderPageImagesSponsors;