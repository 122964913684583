import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";
import styled from "styled-components";

export const BallListContainer = styled(ContainerLineUI)`

width: 150px !important;
    height: 100px;
    background-size: contain !important;
    background-position: center  !important;
    background-repeat: no-repeat  !important;
    padding: 15px   !important;

 

p{
color: #606060;
font-size: 16px;
}

h2{
    color: #606060;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
}

`;