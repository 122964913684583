
import React, { useState, createContext, useContext, useCallback } from 'react';


import ModalContainer from './components/ModalContainer';

export interface IModalMessage {
  id: string;
  title: string;
  content: JSX.Element;
  key?: string;
  theme?: string;
  background?: string;
  className?: string;
  closeButton?: boolean;
}

export interface IModalContextData {
  addModal(message: Omit<IModalMessage, 'id'>): void;
  removeModal(id: string): void;

}

const ModalContext = createContext<IModalContextData>({} as IModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IModalMessage[]>([]);
  const [className, setClassName] = useState('');

  const [closeButton, setCloseButton] = useState(true);


  const addModal = useCallback(
    ({ title, content, key = 'modal-key', theme = '', closeButton = true, background = '' }: Omit<IModalMessage, 'id'>) => {
      const id = key;
      const newMessage: IModalMessage = {
        id,
        title,
        content,
        closeButton,
        theme,
        key,
        background
      };

      setMessages(state => [...state, newMessage]);

      return id;
    },
    [],
  );
  const removeModal = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, removeModal }}>
      {children}
      <ModalContainer messages={messages} className={className} closeButton={closeButton} />
    </ModalContext.Provider>
  );
};

function useModal(): IModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { useModal, ModalProvider };
