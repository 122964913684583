import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaCheck, FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({
  lineData
}: ICreateModule): JSX.Element {



  const moduleCreate = (): JSX.Element => (

    <>
      {lineData?.ticket?.toUpperCase().indexOf('VIP') >= 0 ? <div style={{ width: '30px', height: '30px', minWidth: '30px', minHeight: '30px', background: 'green', borderRadius: '50%', alignSelf: 'center', color: '#fff', fontSize: '9px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>VIP</div> : <></>}
      {lineData?.ticket?.toUpperCase().indexOf('GOLD') >= 0 ? <div style={{ width: '30px', height: '30px', minWidth: '30px', minHeight: '30px', background: 'gold', borderRadius: '50%', alignSelf: 'center', color: '#000', fontSize: '9px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >GOLD</div> : <></>}
    </>
  );

  return moduleCreate();
}
