import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite, Paragraph } from './styles';
import { FaWhatsapp } from 'react-icons/fa';

import { urlWebsite } from '../../../../../../config';
import IframePlayer from '../../../../../../components/UIComponents/IframePlayer';

import ImageGallery from '../../../../../../components/ImageGallery';
import Paginas from '../../index';


const SinglePageTemplate: React.FC<Record<string, any>> = ({ theme, pageContent, clean = 'no' }) => {
  const { hash } = pageContent;
  return (
    <>

      <div style={{ position: 'relative', zIndex: 1, background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : 'none' }} className={`column full-width  container-pad `}>
        {pageContent?.image ? <div className={`backgroundImage ${pageContent?.theme_data?.fixed_image === 'yes' ? 'backgroundImageFixed' : ''}`} style={{ background: `url("${urlWebsite}/${pageContent?.image}")`, backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: pageContent?.theme_data?.background_opacity ? pageContent?.theme_data?.background_opacity : 1 }}></div> : <></>}

        <Container className='full-width max-1400' style={{ position: 'relative', flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', }} >




          <div className={`max-800  fadeIn pad-xl `} style={{ width: '100%' }}>
            <h2 className='titleH2 fadeIn' style={{ color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333', background: pageContent?.theme_data?.title_background ? pageContent?.theme_data?.title_background : 'none', maxWidth: pageContent?.theme_data?.text_max_width ? pageContent?.theme_data?.text_max_width : '600px', marginBottom: '24px' }} dangerouslySetInnerHTML={{ __html: pageContent?.title }} />
            <Paragraph className=' fadeIn' style={{ background: pageContent?.theme_data?.text_background ? pageContent?.theme_data?.text_background : 'none', maxWidth: pageContent?.theme_data?.text_max_width ? pageContent?.theme_data?.text_max_width : '600px' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} />

            {pageContent?.files && pageContent?.files.map((item: Record<string, any>) => {
              return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
            })}

            {pageContent?.buttons?.length > 0 ? <div style={{ margin: '20px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', maxWidth: pageContent?.theme_data?.text_max_width ? pageContent?.theme_data?.text_max_width : '600px' }}>
              {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
                return <a href={`${itemButton.link}`} style={{ width: 'auto', display: 'inline-block', margin: '0px' }} ><button className={itemButton.class_name || 'greenButton2 shadow'} >{itemButton.class_name?.indexOf('whatsapp') >= 0 ? <><FaWhatsapp style={{ fontSize: '18px', minWidth: '20px', marginRight: '5px' }} /> </> : <></>}<h2 style={{ fontSize: '18px', lineHeight: '22px', margin: '0px' }}>{itemButton.title}</h2></button></a>
              })}
            </div> : <></>}
          </div>
          {pageContent?.images?.length > 0 ? <ImageGallery data={pageContent?.images} /> : <></>}

          {pageContent?.video ? <div className='full-width'><IframePlayer key={`${pageContent?.url}-video`} id={pageContent?.video} /></div> : <></>}
          {pageContent?.map_link ? <div style={{ width: '100%', minHeight: '50vh', }}  > <iframe src={pageContent?.map_link} style={{ 'border': 0, width: '100%', height: '50vh' }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> </div> : <></>}


        </Container>

        {
          pageContent?.additional_pages?.map((page: Record<string, any>) => {
            return <Paginas hash={page?.hash} />
          })
        }
      </div >
    </>
  );
};
export default SinglePageTemplate;
