import styled from 'styled-components';


export const Container = styled.div`
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  padding: 0px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1000000;


  .white-full-modal {
    background : url('/assets/Fundo.jpg') no-repeat center !important;
    background-size: cover !important;
    width: 100% !important;
    max-width: 100% !important;
    min-height: 100vh;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);

 

    >h2{
      color: #333;
      display: none ;
    }
 

    >div {
      width: 100%;
      max-height: 100vh;
      //max-width: 800px;
      padding: 0px;
     
    }

     
  }
`;

export const Modal = styled.div`
 
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 300;
 
  @media (max-width: 1200px) {
    position: fixed;
  }

  
 
`;
