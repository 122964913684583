import styled, { css } from "styled-components";

interface IProps {
    themeValue?: 'dark' | 'light';
    background?: string;
    color?: string;
}

export const TitleHighlightUI = styled.h2<IProps>`

font-size: 26px;
line-height: 32px;

p,strong,span,h2,h3{
    font-size: 26px; 
    color: #fff !important;
}

color: #fff !important;

@media (max-width: 750px) {
    font-size: 22px;
line-height: 28px;

p,strong,span,h2,h3{
    font-size: 22px; 
    color: #fff !important;
}

  }

${props => props?.themeValue === 'dark' && css`
color: #fff;
`}

${props => props?.color && css`

color: ${props?.color} !important;
p,strong,span,h2,h3{
   
    color:  ${props?.color} !important;
}
`}

${props => props?.background && css`

display: flex ;
align-items: center;
flex-direction: column;
gap: 5px;

p,strong,span,h3,h2{
background: ${props?.background} !important;
}

p,h2,h3{
    display: inline-block ;
    padding: 5px 10px;
    text-transform: uppercase;
}

`}

`

export const ParagraphHighlightUI = styled.p<IProps>`

font-size: 20px;
line-height: 24px;

p,strong,span{
    font-size: 20px; 
    color: #fff !important;
}

color: #fff !important;

${props => props?.themeValue === 'dark' && css`
color: #fff;
`}

${props => props?.color && css`

color: ${props?.color} !important;
`}
 

`
