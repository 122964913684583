import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { usePageCreatorContext } from '../../hooks/PageCreatorContext';
import ImageSingleGalleryInput from '../../../../core/components/Forms/ImageSingleGalleryInput';

interface IEditStyleProps {
    containerIndex: number;
    sectionIndex?: number;
    rowIndex?: number;
    contentIndex?: number;
    closeEdit: () => void;
    properties: any;
}

const RenderType = ({ property, handleChange, styles, type }) => {


    if (property?.inputType === 'image') {
        return <Form style={{ width: '100%' }} initialData={{ [`${type}-${property?.style}`]: styles?.[type]?.[property?.style] }} onSubmit={(data) => handleChange(type, property.style, data?.[`${type}-${property?.style}`])}>

            <ImageSingleGalleryInput placeholder='' name={`${type}-${property?.style}`} list={[
                { label: 'Imagem', name: 'url' },

            ]} />

            <button className=''>Salvar</button>

        </Form>

    }
    else if (property?.inputType === 'select') {
        return <select style={{ padding: '5px', width: '100%', border: '1px solid #ddd', borderRadius: '5px' }} onChange={(e) => handleChange(type, property.style, e.target.value)}
            value={styles?.[type]?.[property?.style] || ''}
            name={`${type}-${property?.style}`}>
            {property?.options?.map(optionValue => <option selected={styles?.[type]?.[property?.style] === optionValue} value={optionValue}>{optionValue}</option>)}
        </select>

    }
    else if (property?.inputType === 'color') {
        return <><div style={{ background: styles?.[type]?.[property.style] || 'none', width: '30px', minWidth: '30px', cursor: 'pointer', height: '30px', position: 'relative', border: '1px solid #ddd', borderRadius: '5px' }}> <input name={`${type}-${property?.style}`} style={{ padding: '5px', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', opacity: 0, }}
            type={property.inputType}
            value={styles?.[type]?.[property.style] || ''}
            onChange={(e) => handleChange(type, property.style, e.target.value)}
        />



        </div><input name={`${type}-${property?.style}-text`} style={{ padding: '5px', width: '100%' }}
            type={'text'}
            value={styles?.[type]?.[property.style] || ''}
            onChange={(e) => handleChange(type, property.style, e.target.value)}
            /></>
    }

    return <input name={`${type}-${property?.style}`} style={{ padding: '5px', width: '100%', border: '1px solid #ddd', borderRadius: '5px' }}
        type={property.inputType}
        value={styles?.[type]?.[property.style] || ''}
        onChange={(e) => handleChange(type, property.style, e.target.value)}
    />




}


const EditStyle: React.FC<IEditStyleProps> = ({ containerIndex, sectionIndex, rowIndex, contentIndex, closeEdit, properties }) => {
    const { updateStyle, containers } = usePageCreatorContext();
    const [styles, setStyles] = useState<any>({ computer: {}, mobile: {} });

    useEffect(() => {


        if (containerIndex !== undefined) {
            if (sectionIndex !== undefined) {
                if (rowIndex !== undefined) {
                    if (contentIndex !== undefined) {
                        setStyles(containers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex].style)

                    } else {
                        setStyles(containers[containerIndex].sections[sectionIndex].rows[rowIndex].style)

                    }
                } else {
                    setStyles(containers[containerIndex].sections[sectionIndex].style)

                }
            } else {
                setStyles(containers[containerIndex].style)
            }
        }



    }, [containerIndex, sectionIndex, rowIndex, contentIndex])

    const handleChange = (device: 'computer' | 'mobile', key: string, value: string) => {

        setStyles((prev: any) => ({
            ...prev,
            [device]: prev?.[device] ? {
                ...prev[device],
                [key]: value
            } : { [key]: value }
        }));


    };

    useEffect(() => {

        handleSave();

    }, [styles])

    const handleSave = () => {
        updateStyle({ containerIndex, sectionIndex, rowIndex, contentIndex }, styles?.computer || {}, 'computer');
        updateStyle({ containerIndex, sectionIndex, rowIndex, contentIndex }, styles?.mobile || {}, 'mobile');

    };

    return (
        <div key={`${containerIndex || '0'}-${sectionIndex || '-1'}-${rowIndex || -1}-${contentIndex || -1}`}>
            <h3>Personalizar</h3>
            <div className='full-width'>

                {properties.map((property: any) => (
                    <div className='column'>

                        <p style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '5px' }}>{property.label}</p>

                        <div className=' full-width' style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>

                            <div style={{ width: '50%' }}  >
                                <p style={{ fontSize: '12px', color: '#777' }}>Computador</p>

                                <RenderType handleChange={handleChange} property={property} type={'computer'} styles={styles} />
                            </div>
                            <div style={{ width: '50%' }} >
                                <p style={{ fontSize: '12px', color: '#777' }}>Mobile</p>

                                <RenderType handleChange={handleChange} property={property} type={'mobile'} styles={styles} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <button onClick={handleSave}>Save</button>
        </div>
    );
};

export default EditStyle;