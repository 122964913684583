import usePaymentRequest from './paymentRequest';

export default function usePaymentWithBoleto() {

  const { paymentRequest } = usePaymentRequest()

  const payWithBoleto = async ({ paymentContext, moveToCart }) => {

    await paymentRequest({ paymentContext, moveToCart });

    return false;

  }

  return { payWithBoleto };

}