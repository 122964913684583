import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useModal } from '../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { FaMailBulk, FaPaperPlane } from 'react-icons/fa';
import Whatsapp from '../../../../core/components/Whatsapp';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule() {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { configuration } = useConfiguration();

  const CheckTickets = async (): Promise<void> => {

    const response = await api.get(`/sales-products`, { params: { limitItems: 100000, where: { project_id: configuration?.url } } });
    const students = await api.get(`/students`, { params: { limitItems: 100000 } });

    const items: Array<Record<string, any>> = [];

    response?.data?.rows?.map(item => {

      if (item?.payment_status_id === 'paid') {

        const student = students?.data?.rows?.find(i => i?._id?.toString() === item?.client_id)




        items?.push({ ...item, name: student?.name, phone: student?.phone, email: student?.email, count: 1 })





      }

    })

    let x = 0;

    while (x < items?.length) {
      const item = items?.[x];
      if (item?.client_id) {
        await api.post(`/v3/send-ticket-sale-mail/${item?.ticket_hash}`);
        addToast({ title: items[x]?.client_id_response?.name, type: 'info' })
      }

      x += 1;
    }




    const content = <table>

      <tbody>
        <tr>
          <td>Ingresso </td>
          <td>Status</td>
          <td>Compra</td>
          <td>Nome</td>
          <td>Telefone</td>
          <td>E-mail</td>
          <td>Comprador</td>
        </tr>
        {items?.map((itemValue, index) => {

          return <tr>
            <td>{itemValue?.product_id_response?.title}</td>
            <td>{itemValue?.payment_status_id}</td>
            <td>{itemValue?.ticket_hash}</td>
            <td>{itemValue?.name}</td>
            <td>{itemValue?.phone}</td>
            <td>{itemValue?.email}</td>
            <td>{itemValue?.payer_id_response?.name}</td>
          </tr>
        })}

      </tbody>


    </table>;

    addModal({ title: 'Lista', content, theme: 'whiteModal' });



  };



  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" title="Visualizar ingressos não atribuidos" type="button" onClick={() => CheckTickets()}>
      <FaPaperPlane />
    </button>
  );

  return moduleUpdate();
}
