import styled from 'styled-components';
import { defaultTheme } from '../../../../../../config';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: auto;

  height: auto;
  z-index: 100;


  .iconButton {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 0;
      >svg{
        font-size: 12px;
      }

      background: ${defaultTheme.defaultBackground};
      color: #fff;
    }}

    .iconTrash {
      background: #555;
      border-radius: 0 4px 4px 0;
      align-items: center;
      height: auto;
    }

.saveButton {
  margin-top: 10px;
  padding: 5px;
  border-radius: 4px;

}

input, select {
  width: 100%;
  padding: 10px;
}


textarea {
  min-height: 50px;
}

  nav {
    cursor: pointer;
    color: white;
    font-size: 16px;
    opacity: 1;
    transition: opacity 0.2s;
    font-weight: bold;

    & + nav {
      margin-left: 50px;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  min-width: 300px;

  > label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
  }

  > div {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
  }

  .voteOption {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > input {
      margin-right: 10px;
    }
  }
`;
