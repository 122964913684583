/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { ProductsContent } from './styles';
import { ActiveButton, BuyContext } from '../../index';
import api from '../../../../services/api';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import { useLanguage } from '../../../../hooks/Language';
import { Link, useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { defaultProjectHash, urlWebsite } from '../../../../config';
import { FaCheckCircle, FaChevronRight, FaInfoCircle, FaStar } from 'react-icons/fa';
import { useModal } from '../../../../hooks/Modal';
import { useAuth } from '../../../../hooks/Auth';
import { rgb } from 'polished';

interface ProductProps {
  id: string;
  _id: string;
  title: string;
  position?: number;
  description: string;
  price: number;
  formattedPrice?: string;
  max_selectable_courses: number;
  type: 'free' | 'paid';
  benefits: string[];
  quantity: number;
  category_id?: number;
  available_quantities: number;
  limited_quantities: string;
  before_price: number;
  formattedPriceFull?: string;
  hash_link: string;
  group_products?: Array<ProductProps>;
  total_quantities: number;
  sold_quantities?: number;
  waiting_quantities?: number;
  group?: string;
  any_price?: string;
  is_extra?: string;
  benefits_title?: string;
  benefits_description: string;
}

interface CoursesProps {
  id: string;
  title: string;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId?: string;
}

const ProductsContainer: React.FC<ComponentProps> = ({
  step,
  setStep,
  buyContext,
  setBuyContext,
  productId = '',
}) => {
  const { eventHash, configuration } = useConfiguration();
  const { projectHash = defaultProjectHash, } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const { groupId } = useParams<Record<string, any>>();
  const { translate, language } = useLanguage();
  const [productList, setProductList] = useState<ProductProps[]>([]);
  const [productGroup, setProductGroup] = useState<Record<string, any>>({});
  const { addModal } = useModal();

  const { getTrackingCupom } = useAuth();

  const [couponInfo, setCouponInfo] = useState<Record<string, any>>({});




  useEffect(() => {
    const loadInfo = async (): Promise<void> => {

      if (!projectHash) {
        return;
      }
      const responseProducts = productId
        ? await api.get(`/product/${productId}/${projectHash}`)
        : groupId ? await api.get(`/products-list/${projectHash}/group/${groupId}`) : await api.get(`/products-list/${projectHash}`);

      if (!responseProducts) {
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
      }

      if (responseProducts?.data?.error === 'finished') {
        setLoading(false);
        setFinished(true);
        return;
      }

      if (productId && responseProducts.data) {
        responseProducts.data.rows = [responseProducts.data];
      }
      else if (productId && !responseProducts.data) {
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
        return;
      }

      const formatProducts: Array<ProductProps> = responseProducts.data.rows.map(
        (productValue: ProductProps) => {
          const prices = {
            ...productValue,
            formattedPrice: format(productValue.price),
          };

          if (productValue.before_price) {
            prices.formattedPriceFull = productValue.before_price
              ? format(productValue.before_price)
              : '0';
          }

          return prices;
        },
      );

      let group = {};

      formatProducts.map(p => {

        if (p.group) {
          if (!group[p.group]) {
            group[p.group] = ((p.sold_quantities || 0) + (p.waiting_quantities || 0));
          }
          else {
            group[p.group] = group[p.group] + (((p.sold_quantities || 0) + (p.waiting_quantities || 0)));
          }
        }
      }

      )

      formatProducts.sort((a, b) => {

        if (configuration?.current_event_id_response?.product_order === 'asc') {
          return a.price > b.price ? 1 : a.price < b.price ? -1 : 1;
        }
        else if (configuration?.current_event_id_response?.product_order === 'position') {

          return (a?.position || 1) > (b?.position || 1) ? 1 : (a?.position || 1) < (b?.position || 1) ? -1 : 1;
        }
        else {
          return a.price < b.price ? 1 : a.price > b.price ? -1 : 1;
        }


      })




      setProductGroup(group);
      setProductList(formatProducts?.filter(p => p?.is_extra !== 'Sim'));

      setLoading(false);
    };

    loadInfo();
  }, [addToast, projectHash, eventHash, configuration, groupId]);

  const coupon = getTrackingCupom();


  const checkCupon = async (couponValue) => {

    if (!couponValue) {

      return;
    }

    const couponV = couponValue?.toUpperCase();




    try {
      const response = await api.post(`/check-general-coupon/${projectHash}`, {
        hash: couponV,

      });

      const data = response.data;

      if (!data?._id) {

        setLoading(false);
        return;
      }


      setCouponInfo(response?.data);

      setLoading(false);
    }
    catch (err) {
      return
    }





  }

  useEffect(() => {

    if (coupon) {

      checkCupon(coupon)
    }


  }, [coupon])

  const handleProduct = useCallback(
    productInfo => {
      window.location.href = `/app/${projectHash}/registro/${productInfo.hash_link}`
    },
    [buyContext, setBuyContext, setStep, projectHash],
  );

  if (configuration?.current_event_id_response?.open_status === 'closed') {
    return <AnimatedDiv visible={step[1] === 1}  >

      <ProductsContent className='shadow' >
        <><p style={{ background: 'rgb(0,100,150)', color: '#fff', padding: '15px' }}>Inscrições encerradas</p></>
      </ProductsContent></AnimatedDiv>
  }


  if (configuration?.current_event_id_response?.open_status === 'waiting') {
    return <AnimatedDiv visible={step[1] === 1} >

      <ProductsContent className='shadow' >
        <><p style={{ background: 'rgb(0,100,150)', color: '#fff', padding: '15px' }}>Aguardando abertura de inscrições</p></>
      </ProductsContent></AnimatedDiv>
  }

  return (
    <>
      {loading ? (
        <Loader message="Carregando" />
      ) : (
        <AnimatedDiv visible={step[1] === 1} >

          <ProductsContent className='shadow' >

            <a href={`/app/${projectHash}/login`}><button className='greenButton2 buttonColored' >{translate('Meus ingressos')}</button></a>

            <ActiveButton step={1} />

            {configuration?.current_event_id_response?.product_header && <p style={{ maxWidth: '400px', fontSize: '12px', padding: '15px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.[`product_header_${language}`] ? configuration?.current_event_id_response?.[`product_header_${language}`] : configuration?.current_event_id_response?.product_header || '' }} />}

            {couponInfo?.hash ? <nav style={{ marginTop: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff', color: 'rgb(0,150,0', border: '2px solid rgb(0,150,0)', padding: '10px 16px', borderRadius: '10px', fontSize: '12px', fontWeight: 'bold' }}><FaStar size={20} style={{ minWidth: '20px', marginRight: '10px' }} /><p>DESCONTO ESPECIAL {couponInfo.hash}</p></nav> : <></>}

            <h2>{translate('Selecione sua inscrição')}</h2>



            <aside style={{ display: 'flex', padding: '10px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }} >
              {finished ? <><h2 style={{ background: '#ddd', padding: '15px', marginTop: '15px', borderRadius: '5px' }}>Inscrições encerradas!</h2></> : productList.map(productInfo => {

                const couponItem = couponInfo ? couponInfo?.products?.find(product => product?.product?.toString() === productInfo?._id?.toString()) : {};


                return <>
                  <div className='productBlock' style={{ display: 'flex', minHeight: '70px', margin: '5px', padding: '10px', alignItems: 'center', justifyContent: 'space-between', width: '700px', maxWidth: '100%' }}>
                    <aside style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', background: 'none', alignItems: 'center' }}>
                      <aside onClick={() => productInfo?.benefits_title && productInfo?.benefits_description ? addModal({ title: 'Benefícios', theme: 'whiteModalMedium', content: <p dangerouslySetInnerHTML={{ __html: productInfo?.benefits_description }} /> }) : false} style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <h2 className='productTitle' style={{ padding: '0px 10px' }}  >{productInfo?.[`title_${language}`] ? productInfo?.[`title_${language}`] : productInfo.title}

                        </h2>

                        {productInfo?.description ? <p style={{ fontSize: '12px', lineHeight: '16px', padding: '0px 10px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: productInfo?.[`description_${language}`] ? productInfo[`description_${language}`] : productInfo.description }} />



                          : <></>}
                        {productInfo?.benefits_title && productInfo?.benefits_description ? <p className='scale' style={{ width: '100%', fontWeight: 'bold', color: '#777', fontSize: '10px', padding: '0px 10px', cursor: 'pointer' }} >{productInfo?.benefits_title}</p> : <></>}
                      </aside>
                      <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>


                        <aside style={{ display: 'flex', minWidth: '115px', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                          {(productInfo.hash_link === 'apoio' || productInfo.hash_link === 'apoio-pos') ? <></> : productInfo &&
                            productInfo.limited_quantities === 'Sim' && (
                              productInfo.available_quantities <= 0 || (productInfo?.group && (productGroup?.[productInfo?.group] >= productInfo.total_quantities))) ? (
                            <button
                              className="greenButton2 redButton" style={{ marginBottom: '0px' }}
                              type="button"
                            >
                              {translate('Vagas encerradas')}
                            </button>
                          ) : (<>
                            {productInfo?.any_price === 'yes' ? <></> : <>

                              {productInfo?.before_price ? <p style={{ position: 'relative', color: '#aaa', fontSize: '16px' }}><div style={{ position: 'absolute', top: '45%', width: '100%', height: '2px', background: 'rgba(150,0,0,0.7)' }} />de {format(productInfo.before_price)}</p> : <></>}

                              <p onClick={() => productInfo &&
                                productInfo.limited_quantities === 'Sim' &&
                                (productInfo.available_quantities <= 0 || (productInfo?.group && (productGroup?.[productInfo?.group] >= productInfo.total_quantities))) ? false : handleProduct(productInfo)}
                                style={{ marginBottom: '0px', color: '#333', fontWeight: 'bold', fontSize: '18px' }}


                              >
                                {`R$ `} {format(couponItem?.price ? couponItem?.price : productInfo.price)}


                              </p>

                              {couponInfo?.hash ? <p style={{ color: 'rgb(0,150,0)', padding: '3px', fontSize: '10px', marginTop: '5px', borderRadius: '3px', border: '1px solid rgb(0,150,0)' }}>Desconto aplicado.</p> : <></>}

                            </>} </>)}


                          {language !== 'ptBr' ? <p style={{ fontSize: '12px', color: '#333', marginTop: '5px' }}>{translate("Aprox.")}: $ {parseInt(((productInfo.price / 100) / 5.2).toString(), 10)}</p> : <></>}
                        </aside>

                        {productInfo &&
                          productInfo.limited_quantities === 'Sim' && (
                            productInfo.available_quantities <= 0 || (productInfo?.group && (productGroup?.[productInfo?.group] >= productInfo.total_quantities))) ? <></> : <aside style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: '10px', width: '100%' }}>

                          <button onClick={() => productInfo &&
                            productInfo.limited_quantities === 'Sim' &&
                            (productInfo.available_quantities <= 0 || (productInfo?.group && (productGroup?.[productInfo?.group] >= productInfo.total_quantities))) ? false : handleProduct(productInfo)} className='greenButton2 buttonColored'>
                            {productInfo?.any_price === 'yes' ? 'Doar' : 'Comprar'}
                          </button>

                        </aside>}

                      </aside>

                    </aside>

                  </div>
                  {productInfo?.group_products && productInfo?.group_products?.length > 0 && !productId ?
                    productInfo?.group_products.map(item => {
                      return <div key={item._id} className='productBlockAdd' onClick={() => productInfo &&
                        productInfo.limited_quantities === 'Sim' &&
                        productInfo.available_quantities <= 0 ? false : handleProduct(item)} style={{ display: 'flex', cursor: 'pointer', minHeight: '70px', margin: '5px', padding: '10px', alignItems: 'center', justifyContent: 'space-between', width: '100%', maxWidth: '700px' }}>
                        <aside style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', background: 'none', alignItems: 'center' }}>
                          <aside style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}><h2 style={{ width: '100%', justifyContent: item.hash_link === 'apoio' || item.hash_link === 'apoio-pos' ? 'center' : 'flex-start', display: 'flex', padding: '10px', textAlign: 'left', marginRight: '10px' }}>{translate(item.title)}

                          </h2>

                            {item.description && <p style={{ fontSize: '10px', padding: '10px', color: '#555', textAlign: 'left', width: '100%' }}>{item.description}</p>}
                            {item?.benefits_title && item?.benefits_description ? <p onClick={() => addModal({ title: 'Benefícios', theme: 'whiteModalMini', content: <p dangerouslySetInnerHTML={{ __html: item?.benefits_description }} /> })}>{item?.benefits_title}</p> : <></>}
                          </aside>
                          <aside style={{ display: 'flex', width: '100px', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            {(item.hash_link === 'apoio' || item.hash_link === 'apoio-pos') ? <></> : item &&
                              item.limited_quantities === 'Sim' &&
                              item.available_quantities <= 0 ? (
                              <button
                                className="greenButton2 redButton" style={{ marginBottom: '0px' }}
                                type="button"
                              >
                                {translate('Esgotado')}
                              </button>
                            ) : (<>
                              {item?.any_price === 'yes' ? <h2
                                style={{ marginBottom: '0px' }}


                              >
                                {`R$ `} {format(item.price)}


                              </h2> : <></>}
                              {language !== 'ptBr' && item?.any_price !== 'yes' ? <p><strong>{translate("Aprox.")}: $ {parseInt(((item.price / 100) / 5.2).toString(), 10)}</strong></p> : <></>}
                            </>)}
                          </aside>
                        </aside>

                      </div>


                    }) : <></>}

                </>
              })}




            </aside>



          </ProductsContent>

          {configuration?.current_event_id_response?.product_footer && !productId && !groupId && <ProductsContent className='shadow product_footer' style={{ width: '100%', maxWidth: '700px' }} >
            <p style={{ maxWidth: '700px', fontSize: '12px', padding: '15px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.[`product_footer_${language}`] ? configuration?.current_event_id_response?.[`product_footer_${language}`] : configuration?.current_event_id_response?.product_footer || '' }} />

          </ProductsContent>}
        </AnimatedDiv>
      )}
    </>
  );
};

export default ProductsContainer;
