import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';



import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const LeadCapturePage: React.FC = () => {
  const endpoint = '/lead-capture-page';
  const { socket } = useSocket();
  const title = 'Páginas';


  const formSchema = {

    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    description: {
      model: 'input',
      name: 'description',
      label: 'Descritivo',
      defaultValue: '',
    },
    video_id: {
      model: 'input',
      name: 'video_id',
      label: 'ID do Vídeo',
      defaultValue: '',
    },
    video_type_id: {
      model: 'selectSimple',
      name: 'video_type_id',
      label: 'Player',
      defaultValue: 'vimeo',
      options: [
        { label: 'Vimeo', value: 'vimeo' },
        { label: 'Youtube', value: 'youtube' },
        { label: 'On Demand', value: 'ondemand' },

      ]

    },
    lesson_id: {
      model: 'input',
      name: 'lesson_id',
      label: 'ID de palestra',
      defaultValue: '',
    },
    sale_url: {
      model: 'input',
      name: 'sale_url',
      label: 'Link de Venda',
      defaultValue: '',
    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      defaultValue: '1',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

  };

  const formSchemaUpdate = {
    ...formSchema,

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },

  };




  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },

    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },

    title: { ref: 'title', column: 'title', label: 'Título' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };




  const config: ISearchComponent = {
    idKey: `${endpoint}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: `${endpoint}`,
    title: 'Páginas de captura',
    endpoint: '/lead-capture-page',
    schema: schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [



      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),


      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default LeadCapturePage;
