import styled from 'styled-components';

export const Container = styled.div`
margin: 0px auto;
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-start;
 gap: 10px;
`;

export const ProductItemStyle = styled.div`
 display: flex;
 align-items: center;
 justify-content: flex-start;
width: 100%;

 transition: 0.5s all;
 width: 100%;
 cursor: pointer;

 .addProductAction{
   
     min-width: 95px;
     display: flex;
     gap: 5px;

     div{
         border:1px solid #ddd;
         border-radius: 5px;
         width: 30px;
     min-width: 30px;
     display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px;
 transition: 0.5s all;
     }

     svg{
        transition: 0.5s all;
     }

     .activeProduct{
         background: rgb(0,150,50);
         svg{ 
             color: #fff;
         }
     }

     .activeProduct.changeble{
          .showIcon { display: flex; }
          .notShowIcon { display: none; }

          &:hover{
            background: #333;
         
            .showIcon { display: none;  }
          .notShowIcon { display: flex; }
          }
     }

     .inactiveProduct{
         
        &:hover{
            background: rgb(0,150,50);
         svg{ 
             color: #fff;
         }
        }
      
     }
 }

 .addProductInfo{
     width: 100%;
     display: flex;
 align-items: flex-end;
 justify-content: center ;
 flex-direction: column;
 padding: 0px 15px;
 
 gap: 3px;
     p{
         font-size: 12px;
         border-radius: 0px;
         border: 0px;
         width: 100%;
         text-align: right;
     }
      
 }

 .addProductCount{
     width:30px;
     min-width:30px;
      

     div{
         border:1px solid #ddd;
         border-radius: 5px;
         width: 30px;
     min-width: 30px;
     display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px;
     }
     
 }

      
 }

 




    

`;
