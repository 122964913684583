import React from 'react';
import Slider from 'react-slick';
import { urlWebsite } from '../../../../config';
import CategoriesButton from "../../../UIComponents/CategoriesButton";
import { ContainerLineUI } from "../../../UIComponents/ContainerLine/styles";
import ContentTypeButton from "../../../UIComponents/ContentTypeButton";
import MemberButton from "../../../UIComponents/MemberButton";
import Paragraph from "../../../UIComponents/Paragraph";
import ParagraphDangerous from '../../../UIComponents/ParagraphDangerous';

import Subtitle from "../../../UIComponents/Subtitle";
import Title from "../../../UIComponents/Title";

interface IProps {
    className?: string;
    theme?: 'dark' | 'light';
    style?: Record<string, any>;
    filtered?: Array<Record<string, any>>;
    categories?: { label: string, value: string }[];
    unique?: boolean;
    column?: string;
    applySlide?: boolean;
    show?: number;
    setCallback?: Function;

}

interface IRenderContent {
    filteredItems?: Array<Record<string, any>>;
    setCallback?: Function;
}

const renderContentDefault = ({ filteredItems, setCallback = () => { } }: IRenderContent) => {
    const items: Array<JSX.Element> = [];

    filteredItems?.map((item, index) => {

        items.push(<ContainerLineUI onClick={() => setCallback(item)} className="pad-sm max-300 min-300 full-width-mobile fadeIn stretch column column-start gap-xs pointer">
            {/* <ContainerLineUI className="row row-end gap-sm pad-xs border-radius-sm" style={{ marginBottom: '-25px', padding: '3px 16px', zIndex: 2, marginRight: '10px', background: '#0066b3', width: 'auto', alignSelf: 'flex-end' }}>
                <Subtitle theme="dark" style={{ lineHeight: '22px' }} className="text-center">Gratuito</Subtitle>
                 <ContentTypeButton category="news" /> 
                {index === 2 ? <MemberButton member="gold" /> : <></>}
            </ContainerLineUI>*/}
            <div className=" thumb widescreen border-radius-sm scaleImage grow shadow" style={{ backgroundSize: 'cover', background: `url(${urlWebsite}/${item?.image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top center' }} />
            <ContainerLineUI className='column column-start pad-sm gap-xs full-width'>

                <Paragraph className="text-left width-full">{item?.title}</Paragraph>
            </ContainerLineUI>
            {/*   {item?.description ? <ParagraphDangerous theme='light' text={item?.description} className="text-left width-full" /> : <></>}

            <Subtitle style={{ color: '#333' }}>{item?.date}</Subtitle>
            <CategoriesButton className="row-start gap-xs flex-wrap width-full" categories={item?.categories} />
    */}
        </ContainerLineUI>)

    })

    return items;

}

const DefaultCategoryGallery: React.FC<IProps> = ({ setCallback = () => { }, show = 4, applySlide = false, className, theme, style, filtered = [], categories = [{ value: "all", label: '' }], column }) => {



    return <> {categories?.map(category => {

        const width = window.innerWidth > 1200 ? 1200 : window.innerWidth;
        const maxItems = Math.floor(width / 270) > 1 ? Math.floor(width / 270) : 1;

        const filteredItems = category?.value === 'all' || !column ? filtered : filtered?.filter(item => item?.[column]?.findIndex((text: string) => text === category?.value) >= 0);
        const items = renderContentDefault({ filteredItems: filteredItems, setCallback });

        const showItemsValue = items?.length > maxItems ? maxItems : items?.length;
        const showItems = showItemsValue >= 0 ? showItemsValue : 1;

        return filteredItems && filteredItems?.length > 0 ?
            <ContainerLineUI className={`row row-start ${!applySlide ? 'flex-wrap' : ''} width-full  gap-sm ${show === 3 ? 'max-900' : 'max-1200'}`} themeValue={theme} style={style}>

                {applySlide ? <Slider responsive={[
                    {
                        breakpoint: 5200,
                        settings: {

                            slidesToShow: show <= items?.length ? show : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 1200,
                        settings: {

                            slidesToShow: show <= items?.length ? show : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: (show - 1) <= items?.length ? (show - 1) : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow: (show - 2) <= items?.length ? (show - 2) : items?.length,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]} slidesToShow={show <= items?.length ? show : items?.length}> {items}</Slider> : items}

            </ContainerLineUI>

            : <></>
    })} </>

}

export default DefaultCategoryGallery;