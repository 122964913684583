import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaBarcode, FaReceipt } from 'react-icons/fa';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addLoading, removeLoading } = useLoading();
  const { addModal, removeModal } = useModal();
  const { reloadSearchAll } = useSearch();

  const sendNewBoleto = async (data) => {
    addLoading({ title: 'Carregando', key: 'loading' })

    try {

      const response = await api.post(`/v4/create-new-boleto/${lineData?.sale_hash}`, { days: data?.days || 1 });
      addToast({ title: 'Novo boleto adicionado', type: 'success' });

      await api.post(`/v3/send-sale-mail/${response?.data?.new_sale_hash}`);
      addToast({ title: 'E-mail com o boleto reenviado', type: 'success' });

      removeModal('newBoleto')
      removeLoading('loading');
      reloadSearchAll();

    }
    catch (err) {
      addToast({ title: err?.response?.data?.error || err?.response?.data?.message || 'Erro ao substituir o boleto', type: 'error' });
      removeLoading('loading');
    }

  }

  const handleNewBoleto = () => {

    addModal({
      key: 'newBoleto', title: '', content: <div className='outerBlock' style={{ width: '100%' }}> <h2>Novo Boleto</h2>
        <Form style={{ marginTop: '10px' }} initialData={{ days: '1' }} onSubmit={sendNewBoleto}>

          <SelectSimple name='days' label='Dias para o novo vencimento' options={[
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
            { label: '13', value: '13' },
            { label: '14', value: '14' },
            { label: '15', value: '15' },
            { label: '16', value: '16' },
            { label: '17', value: '17' },
            { label: '18', value: '18' },
            { label: '19', value: '19' },
            { label: '20', value: '20' },
            { label: '10', value: '10' },
            { label: '21', value: '21' },
            { label: '22', value: '22' },
            { label: '23', value: '23' },
            { label: '24', value: '24' },
            { label: '25', value: '25' },
            { label: '26', value: '26' },
            { label: '27', value: '27' },
            { label: '28', value: '28' },
            { label: '29', value: '29' },
            { label: '30', value: '30' },
            { label: '31', value: '31' },
          ]} />

          <button style={{ margin: '10px auto' }} className='greenButton2 buttonColored'>Confirmar</button>

        </Form></div>, theme: 'whiteModalMini'
    })


  }

  const moduleUpdate = (line: Record<string, any>): JSX.Element => (
    line.payment_status_id === 'waiting' && line.payment_method_id === 'boleto' ? <button onClick={() => handleNewBoleto()} className="lineIcon" title="Substituir boleto" type="button"  >
      <FaBarcode />
    </button> : <></>
  );

  return moduleUpdate(lineData);
}
