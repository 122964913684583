import React, { useEffect, useState } from 'react';

import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';



import { saveAs } from 'file-saver';

import { useToast } from '../../../../../hooks/Toast';

import Button from '../Forms/Button';

import { usePrinter } from '../../../../../hooks/PrinterHook';
import { useAuth } from '../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../hooks/Configuration';

interface ICreateModule {
  sale: Record<string, any>;
}

const QRCodeTicketComponent: React.FC<ICreateModule> = ({
  sale
}) => {

  const { user } = useAuth();
  const { addPrint, printIt } = usePrinter();
  const key = uuid();
  const [saveButton, setSaveButton] = useState(false);
  const { configuration } = useConfiguration();
  const print = line => {
    printIt();
  };

  const save = async value => {

    saveAs(value, 'qrcode.png');
  };

  const handleQR = async (): Promise<void> => {






    addPrint([
      <div
        style={{
          padding: '20px 5px',
          background: '#fff',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <p style={{ fontSize: '14px', textAlign: 'center' }}>Imprima seu ingresso:</p>

        <QRCode renderAs="svg"
          size={300}
          includeMargin={true}
          imageSettings={{ src: "", width: 300, height: 300 }}
          value={
            sale?.ticket_hash
          }
        />
        <table className='tableRef'>
          <tbody>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left' }}>Ingresso:</p></td></tr>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left', fontWeight: 'bold' }}>{sale?.product_id_response?.title}</p>
            </td></tr>
          </tbody></table>
        <br />
        <table className='tableRef'>
          <tbody>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left' }}>Evento:</p></td></tr>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left', fontWeight: 'bold' }}>{configuration?.current_event_id_response?.title}</p>
            </td></tr>
            <tr><td> <p style={{ fontSize: '14px', textAlign: 'left' }}>{configuration?.current_event_id_response?.date_text}</p>
            </td></tr>
            {configuration?.description ? <tr><td> <p style={{ fontSize: '14px', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: configuration?.description }} />

            </td></tr> : <></>}
          </tbody>
        </table>




      </div>]
    );



  };


  const QRCodeItem = (line): JSX.Element => {

    handleQR();

    return <>
      <div className="qrHolder" style={{ padding: '20px', background: '#fff' }}>
        <QRCode renderAs="svg"
          size={300}
          includeMargin={true}
          imageSettings={{ src: "", width: 300, height: 300 }}
          value={
            sale?.ticket_hash
          }
        />
        <table className='tableRef'>
          <tbody>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left' }}>Ingresso:</p></td></tr>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left', fontWeight: 'bold' }}>{sale?.product_id_response?.title}</p>
            </td></tr>
          </tbody></table>
        <br />
        <table className='tableRef'>
          <tbody>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left' }}>Evento:</p></td></tr>
            <tr><td><p style={{ fontSize: '14px', textAlign: 'left', fontWeight: 'bold' }}>{configuration?.current_event_id_response?.title}</p>
            </td></tr>
            <tr><td> <p style={{ fontSize: '14px', textAlign: 'left' }}>{configuration?.current_event_id_response?.date_text}</p>
            </td></tr>
            {configuration?.description ? <tr><td> <p style={{ fontSize: '14px', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: configuration?.description }} />

            </td></tr> : <></>}
          </tbody>
        </table> </div>
      <div style={{ display: 'flex' }}>
        <Button style={{ maxWidth: '150px' }} type="button" onClick={() => { print(sale); }}>
          Imprimir
        </Button>
        {saveButton && <Button style={{ maxWidth: '150px' }} type="button" onClick={() => { save(`/qrcode/teste/${sale?.ticket_hash}.png`); }}>
          Salvar
        </Button>}</div>

    </>
  }


  return <QRCodeItem />

}

export default QRCodeTicketComponent;