
import SelectSimple from '../../../../../../../components/Forms/SelectSimple';
import TextareaInput from '../../../../../../../components/Forms/TextareaInput';
import Input from '../../../../../../../components/Forms/Input';

import { hash, recaptchaKey, urlWebsite, websitePath } from '../../../../../../../config';
import { useConfiguration } from '../../../../../../../hooks/Configuration';
import { useLoading } from '../../../../../../../hooks/LoadingHook';
import { useToast } from '../../../../../../../hooks/Toast';
import api from '../../../../../../../services/api';

import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import { loadFromApiJson } from '../utils/loadFromApiJson'
interface IProps {
    data: Record<string, any>;

}

interface ICaptcha {
    getValue(): string;
}

const Contact: React.FC<IProps> = ({ data }) => {

    const { hash, project_id } = data;

    const [formInfo, setFormInfo] = useState<Record<string, any>>({});
    const { configuration } = useConfiguration();
    const { addLoading, removeLoading } = useLoading();
    const [finished, setFinished] = useState(false);
    const recaptchaRef = useRef(null);
    const options = formInfo?.email ? formInfo?.email?.reduce((prev: Array<Record<string, any>>, item: Record<string, any>) => {
        prev.push({ label: item.title, value: item.title });
        return prev;
    }, [])?.sort((a: Record<string, any>, b: Record<string, any>) => a?.label > b?.label ? 1 : a?.label < b?.label ? -1 : 0) : [];

    const { addToast } = useToast();

    const getCaptchaValue = (elem: ICaptcha): string => {
        return elem.getValue();
    };


    const sendForm = async (data: Record<string, any>) => {
        addLoading({ title: 'Enviando...', key: 'loading' });

        const captchaValue =
            getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
            false;

        if (!captchaValue) {
            addToast({ title: 'Clique em Não sou um robô', type: 'error' })

            removeLoading('loading');
            return;
        }

        if (!data?.name) {
            addToast({ title: 'Preencha o campo Nome', type: 'error' })
            removeLoading('loading');
            return;
        }
        if (!data?.phone) {
            addToast({ title: 'Preencha o campo Telefone', type: 'error' })
            removeLoading('loading');
            return;
        }


        if (!data?.email) {
            addToast({ title: 'Preencha o campo e-mail', type: 'error' })
            removeLoading('loading');
            return;
        }

        try {
            await api.post(`/send-mail-contact/${configuration?.url}/${formInfo?.url?.toString()}`, { ...data, website: websitePath, imagePath: 'https://grupoccgl.encontrodigital.com.br' })

            addToast({ title: 'Enviado com sucesso' });
            setFinished(true);
            removeLoading('loading');
        }
        catch (err) {

            addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao submeter', type: 'error' })
            removeLoading('loading')
        }
    }


    const loadForm = async () => {
        if (configuration) {

            const response: Array<Record<string, any>> = await loadFromApiJson({ hash: hash, project_id: project_id || configuration?.url || '', table: 'MailContactOption' });

            if (response?.[0]) {

                const content = response?.find(item => item?.url === hash);

                if (content) {
                    setFormInfo(content);
                }


            }
        }
    }


    useEffect(() => {
        loadForm();
    }, [])

    if (finished) {
        return <div className='full-width row-to-column  gap' style={{ minHeight: '80vh' }}>

            <div className='column gap text-center'>
                <FaCheckCircle style={{ fontSize: '120px', color: 'rgb(0,255,0)' }} />
                <h2 className='hugeTitle2 colorWhite max-800' style={{ fontSize: '32px', lineHeight: '36px' }}>
                    Mensagem enviada<br /> com sucesso
                </h2>
                <button className='whiteButton' style={{ marginTop: '15px' }} onClick={() => setFinished(false)} >Enviar outra mensagem</button>
            </div>


        </div>
    }

    return formInfo ? <div className='full-width row-to-column  gap'>


        <div className='row gap-sm max-500' style={{ marginTop: '10px', maxWidth: '500px' }}>

            <Form className='column ' onSubmit={sendForm} initialData={{ delivery: options?.[0]?.label }}>
                <h2 style={{ fontSize: '24px', color: '#fff' }}>{formInfo?.title}</h2>
                <Input name='name' placeholder='Nome' />
                <Input name='email' placeholder='E-mail' />
                <Input name='phone' placeholder='Telefone' />
                <SelectSimple index={0} options={options} name={'delivery'} label="Área" />
                <TextareaInput name='message' placeholder='Mensagem' />
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
                <button className='whiteButton' style={{ marginTop: '15px' }} >Enviar</button>
            </Form>
        </div>
    </div> : <></>


}

export default Contact;