import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite, Paragraph } from './styles';
import { FaWhatsapp } from 'react-icons/fa';

import { urlWebsite } from '../../../../../../config';
import { useLanguage } from 'src/hooks/Language';



const IconesTemplate: React.FC<Record<string, any>> = ({ theme, pageContent, clean = 'no' }) => {
  const { hash } = pageContent;
  const { language } = useLanguage();

  const list = pageContent?.[`data_list_${language}`] ? pageContent?.[`data_list_${language}`] : pageContent?.data_list || [];

  return (
    <>

      <div style={{ position: 'relative', zIndex: 1, background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : 'none' }} className={`column full-width ${clean === 'yes' ? '' : 'container-pad'}`}>
        {pageContent?.image ? <div className={`backgroundImage ${pageContent?.theme_data?.fixed_image === 'yes' ? 'backgroundImageFixed' : ''}`} style={{ background: `url("${urlWebsite}/${pageContent?.image}")`, backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: pageContent?.theme_data?.background_opacity ? pageContent?.theme_data?.background_opacity : 1 }}></div> : <></>}

        <Container className='full-width max-1200' style={{ position: 'relative', flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', }} >




          <div className={`max-800  fadeIn pad-xl `} style={{ width: '100%' }}>
            {pageContent?.title ? <h2 className='titleH2 fadeIn' style={{ color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333', background: pageContent?.theme_data?.title_background ? pageContent?.theme_data?.title_background : 'none', maxWidth: pageContent?.theme_data?.text_max_width ? pageContent?.theme_data?.text_max_width : '600px', marginBottom: '24px' }} dangerouslySetInnerHTML={{ __html: pageContent?.[`title_${language}`] ? pageContent?.[`title_${language}`] : pageContent?.[`title`] }} /> : <></>}
            {pageContent?.description ? <Paragraph className=' fadeIn' style={{ background: pageContent?.theme_data?.text_background ? pageContent?.theme_data?.text_background : 'none', maxWidth: pageContent?.theme_data?.text_max_width ? pageContent?.theme_data?.text_max_width : '600px' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} /> : <></>}

            <div className='row gap'>

              {list?.map(i => {
                return <div className='max-200 column scaleOnHover cursor'>
                  <img className="full-width" src={`${urlWebsite}/${i?.image}`} />
                  <h2 style={{ fontSize: '12px' }}>{i?.title}</h2>
                  <p style={{ fontSize: '10px' }} dangerouslySetInnerHTML={{ __html: i?.text || '' }} />
                </div>
              })}


            </div>


            {pageContent?.buttons?.length > 0 ? <div style={{ margin: '20px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', maxWidth: pageContent?.theme_data?.text_max_width ? pageContent?.theme_data?.text_max_width : '600px' }}>
              {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
                return <a href={`${itemButton.link}`} style={{ width: 'auto', display: 'inline-block', margin: '0px' }} ><button className={itemButton.class_name || 'greenButton2 shadow'} >{itemButton.class_name?.indexOf('whatsapp') >= 0 ? <><FaWhatsapp style={{ fontSize: '18px', minWidth: '20px', marginRight: '5px' }} /> </> : <></>}<h2 style={{ fontSize: '18px', lineHeight: '22px', margin: '0px' }}>{itemButton.title}</h2></button></a>
              })}
            </div> : <></>}
          </div>


        </Container>

      </div >
    </>
  );
};
export default IconesTemplate;
