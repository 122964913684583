import styled, { keyframes } from 'styled-components';


const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateY(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`; export const Container = styled.div`
width : 100%;
display: flex ;
flex-wrap: wrap;
justify-content: center;
align-items: stretch;
gap: 10px;

.project-line{
  display: flex ;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 10px 15px;
  margin: 10px 0px;
  max-width: 1200px;
  border-radius: 10px;
  box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
  min-height: 100px;
  flex-direction: column;
  max-width: 320px;

  animation: ${fadeFromRight} 0.3s forwards ;
  
  @media (max-width: 800px) {
flex-direction: column;
}

  h2{
    font-size: 12px;
    color: rgb(0,14,64);
  }

  p{
    font-size: 12px;
    color: #777;
  }

  img{
    width: 100%;
    border-radius: 10px;
  }

  .project-line-group{
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    flex-wrap:wrap;

    >div{
      cursor: pointer ;
    }
  }

  .lineIcon{
    border : 0px !important;
    padding: 0px;
    margin: 0px ;
    height: auto ;
    width: auto ;
    background: none ;
  }

  .project-line-content{
    padding: 10px 15px ;
    display: flex ;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 350px;
    

  }

  .project-line-icons{
    display: flex ;
    max-width: 350px;

    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0px;
    svg {
      min-width: 30px;
      color: rgb(0,14,64);
      margin: 2px 0px;
      font-size: 18px;
 
    
    }

    >div, >a, >aside, >button{
      background: #eee ;
      padding: 3px ;
      border-radius: 10px;
      transition: 0.4s all;
      cursor: pointer;
      margin: 3px !important;
      width: 36px;
    height: 32px ;

    display: flex ;
    align-items: center;
    justify-content: center;
    opacity: 0.7 ;

&:hover{
transform: scale(1.1) ;
opacity: 1 ;
}
    }


    svg+svg {
      min-width: 30px;
      color: rgb(0,14,64);
      margin: 2px;
      font-size: 18px;
    }
  }
}

`;