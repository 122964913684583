

import React from 'react';
import { Container, Body, Content, Footer } from './styles';
import { useModal } from '../../../index';
import Button from '../../../../../components/Forms/Button';


interface IModalProps {
  title: string;
  key: string;
  content: JSX.Element;
  className: string;
  closeButton: boolean;
  theme?: string;
  background?: string;
}

const ModalContainer: React.FC<IModalProps> = ({
  key,
  content,
  title = '',
  className = '',
  closeButton = true,
  theme = 'whiteModalMedium',
  background
}) => {
  const { removeModal } = useModal();

  return (
    <Container className='container' >
      <Body className={`column gap pad ${theme || 'whiteModalMedium'}`} style={{ background: background }}>
        {title ? <h2 className='text-subtitle'>{title}</h2> : <></>}
        <Content className='content-modal container column column-start'>{content}</Content>
        {closeButton ? <Footer className='container column'>
          <Button className='max-200' type="button" onClick={() => removeModal(key)}>
            Fechar
          </Button>
        </Footer> : <></>}
      </Body>
    </Container>
  );
};

export default ModalContainer;
