import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Container } from './styles';
import { Error } from '../styles';
import { useLanguage } from 'src/hooks/Language';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const { translate } = useLanguage();
  const selectRef = useRef(null);

  const [currentValue, setCurrentValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {

        return currentValue;
      },
      setValue: (ref: any, value) => {
        ref.props.data = currentValue;
        setCurrentValue(value)
      },
    });
  }, [fieldName, registerField, currentValue]);



  return (
    <Container
      {...rest}
      isErrored={!!error}

    >
      <span>{translate(rest.placeholder)}</span>
      {Icon && <Icon />}
      <CKEditor
        ref={selectRef}
        editor={ClassicEditor}
        data={currentValue}
        onInit={editor => {

        }}

        onChange={(event, editor) => {
          const data = editor.getData();
          setCurrentValue(data);
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};

export default Input;
