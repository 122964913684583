import styled, { css } from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  background: none;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  min-height: auto;
  z-index: -1;
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const Container = styled.div`
  min-height: auto;

  display: flex;
  flex-direction : column;
  background: #fff;
  color: #fff;
  padding: 10px;
  width: 100%;
 

 // border: 2px solid rgb(0,15,64);
  margin: 0px auto !important;
  margin-top: 50px;
  //background: rgba(255,255,255,1);
  padding: 30px 20px;
z-index: 10 !important;
  @media (max-width: 750px) {
    padding: 30px 0px;
  }

  @media (max-width: 750px) {

    justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  >div{
    max-width: 500px;
  }


      }

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  >div, >h2, > p{
    max-width: 1600px;

    text-align: center;
  }

  >h2{
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

export const SponsorGrid = styled.div<IBackground>`

width: auto;
max-width: 1600px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin: auto;


>div{
  width:auto;
max-width: 1600px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin: auto; 
}

@media (max-width: 1600px) {
  >div{
    max-width: 500px;
  }


      }

`;

interface IBackground {
  background?: string;
}


export const Apoio = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: contain;

`;

export const Sponsor = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: cover;

width: 100px;
height: 66px;
margin:15px;

@media (max-width: 1200px) {
  width: 60px;
height: 40px;
      }
`;

interface ISize {
  size?: string;
}

export const ApoioGrid = styled.div<ISize>`

width: auto;
max-width: 1600px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
gap: 30px; 


`;

interface ISizeImg {
  computer?: string;
  mobile?: string;
}


export const ApoioDiv = styled.div<ISizeImg>`

${props =>
    props.computer &&
    css`
      width: ${props.computer} !important;  
      max-height: 100% !important;
      max-width: 100% !important;
    `}



@media (max-width: 750px) {
  ${props =>
    props.mobile &&
    css`
      width: ${props.mobile} !important;  
      max-height: 100% !important;
      max-width: 100% !important;
    `}
    }

    >img{
      width: 100% !important;
      height: auto !important ;
    }

`;

export const ApoioLink = styled.a<ISizeImg>`

${props =>
    props.computer &&
    css`
      width: ${props.computer} !important;  
      max-height: 100% !important;
      max-width: 100% !important;
    `}



@media (max-width: 750px) {
  ${props =>
    props.mobile &&
    css`
      width: ${props.mobile} !important;  
      max-height: 100% !important;
      max-width: 100% !important;
    `}
    }

    >img{
      width: 100% !important;
      height: auto !important ;
    }
`;


export const ApoioImg = styled.img<ISizeImg>`

${props =>
    props.computer &&
    css`
      width: ${props.computer} !important;  
      max-height: 100% !important;
      max-width: 100% !important;
    `}



@media (max-width: 750px) {
  ${props =>
    props.mobile &&
    css`
      width: ${props.mobile} !important;  
      max-height: 100% !important;
      max-width: 100% !important;
    `}
    }

`;

interface IBackground {
  background?: string;
}

