import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useConfiguration } from '../../../hooks/Configuration';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otherCountry, setOtherCountry] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash, productId } = useParams<IParams>();

  const [emailRecord, setEmailRecord] = useState('');

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate } = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('');

  useEffect(() => {
    if (configuration?.have_password === 'no') {
      setWithPassword(false);
    }

  }, [configuration])

  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLoginUpdate = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';
      const shape = {

      };

      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;


          x++;

        })


      }


      const schema = Yup.object().shape(shape);



      await schema.validate(data, { abortEarly: false });

      const content: Record<string, any> = {
        ...data,
        email: emailRecord,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        product: product._id.toString(),
        fields: { ...fieldsValues },
        fields_text: fieldsText,
      };



      const newUser = await api
        .post('/signup-update', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';

    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }

    try {
      if (data?.birth_date) {


        const getDate = data?.birth_date?.split('/');

        const date = new Date(`${getDate?.[2]}-${getDate?.[1]}-${getDate?.[0]}`);

        if (!isValid(date)) {
          throw ({ 'message': 'Data de nascimento incompleta' });
        }

        if (date < addYears(new Date(), -100)) {
          throw ({ 'message': 'Data de nascimento inválida' });
        }
      }
    } catch (err) {
      console.log(err)
      addToast({ type: 'error', title: 'Preencha uma data de nascimento válida' })
      setLoading(false);
      return;
    }


    const eventFields = configuration?.current_event_id_response?.sign_up_fields || [];

    try {
      formRef.current?.setErrors({});


      const shape: Record<string, any> = {
        country: Yup.string().required('País é obrigatório'),
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),
        category_id: Yup.string().required('Categoria é obrigatório'),
        document_number: Yup.string().required('Documento é obrigatório'),
        phone: Yup.string().required('Telefone é obrigatório'),
      };

      if (configuration?.have_password === 'yes') {
        shape.password = Yup.string().required('Senha obrigatória')
      }

      eventFields?.map(field => {

        if (field?.mandatory === 'yes') {
          shape[field?.fields] = Yup.string().required(field?.title);
        }

      })


      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = Yup.object().shape(shape);
      if (data.phone) {
        data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');
      }










      data.document_number = data?.document_number ? data?.document_number?.toString().replace(/[^0-9]/g, '') : '';
      data.phone = data?.phone ? data?.phone?.toString().replace(/[^0-9]/g, '') : '';

      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : projectHash,
        captcha: captchaValue,
        term: data.term,
        fields: { ...fieldsValues },
        fields_text: fieldsText
      };

      if (configuration?.current_event_id_response?.product_id) {
        content.product = configuration?.current_event_id_response?.product_id;
      }


      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : projectHash, product: productId ? productId : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';

    try {
      const checkUser = await api.post(`/check-subscriber`, { email: data.email, project_id: projectHash, event_id: eventHash || projectHash, product: productId ? productId : '' });

      if (checkUser) {
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');
        if (checkUser?.data?.product?._id) {

          setProduct(checkUser?.data?.product);
        }
        setEmailRecord(data.email)
        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      return
    }


  }


  const readFields = () => {

    const elements: Array<JSX.Element> = [];

    configuration?.current_event_id_response?.sign_up_fields?.map((field: Record<string, any>) => {

      if (field?.fields === 'birth_date') {
        elements.push(<MaskedInput
          mask="99/99/9999"
          name="birth_date"
          placeholder="Data de nascimento (DD/MM/AAAA)"
        />)
      }
      else if (field?.fields === 'state') {

        elements.push(countryISO === 'BR' ? <SelectSimple setCallback={(value) => {
          setStateValue(value);
        }} options={states} name="state" label="Estado" /> :

          <Input name="state" placeholder={'Província / Região da Fundação'} />)

      }
      else if (field?.fields === 'phone') {

        elements.push(countryISO === 'BR' ? <MaskedInput
          mask="(99) 999999999"
          name="phone"
          placeholder="Telefone com DDD"
        /> : <Input name="phone" placeholder={'Telefone com DDD'} />)

      }
      else if (field?.type === 'select') {

        const options: Array<IOption> = [];
        field?.options?.map((item) => {
          options.push({ value: item, label: item })
        }, [])

        elements.push(<SelectSimple options={options} name={field?.fields} label={field?.title} />)

      }
      else {
        elements.push(<Input name={field?.fields} placeholder={field?.title} />)

      }






    })
    return elements;
  }

  if (!ready) {

    return (
      <Form style={{ width: '100%', maxWidth: '350px' }} ref={formRefConfirmation} onSubmit={handleConfirmation} initialData={{ country: 'BR', email: currentEmail }}>
        <h1 style={{ color: 'rgb(0,15,64)', fontSize: '18px', marginBottom: '10px' }}>{translate('Nova inscrição')}</h1>
        <p style={{ color: 'rgb(0,15,64)', fontSize: '14px', marginBottom: '20px' }}>{translate(`Para iniciar, informe seu ${configuration?.sign_in_type === 'cpf' ? 'CPF' : 'email'}`)}</p>
        {configuration?.sign_in_type === 'cpf' ? otherCountry ? <Input name="email" placeholder="Documento de identificação" /> : <MaskedInput mask={'999.999.999-99'} name="email" placeholder="CPF" /> : <Input name="email" placeholder="E-mail" />}

        {!otherCountry ? <p onClick={() => setOtherCountry(true)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>{translate('Sou de outra nacionalidade')}</p> : <p onClick={() => setOtherCountry(false)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou brasileiro</p>}
        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className="defaultButton" type="submit">
          {translate('Continuar')}
        </button></aside>
      </Form>)
  }


  if (statusConfirmation === 'finished') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Inscrições encerradas</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Agradecemos seu interesse, mas as vagas estão encerradas</p>

        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          Voltar
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Olá participante!</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.</p>

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          Acessar
        </button></Link>
      </>
    )
  }



  if (statusConfirmation === 'update') {
    return <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Cadastro realizado com sucesso!</h2>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="defaultButton" type="button">
                      Acessar
                    </button>
                  </Link>
                </div>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text={`Olá! Acabei de me inscrever no ${configuration?.current_event_id_response?.title}. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: ${urlWebsite}/app/${configuration?.url}`}
                  number=""
                  message="Compartilhe!"
                />
              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} style={{ padding: '15px', minWidth: '320px' }} onSubmit={handleNewLoginUpdate} initialData={{}}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Completar inscrição</h1>

            {product && product?.fields && product?.fields.length > 0 ? <CompleteSaleFields fields={product.fields} /> : <></>}

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Finalizar inscrição


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  }



  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Cadastro realizado com sucesso!</h2>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="defaultButton" type="button">
                      Acessar
                    </button>
                  </Link>
                </div>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text={`Olá! Acabei de me inscrever no ${configuration?.current_event_id_response?.title}. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: ${urlWebsite}`}
                  number=""
                  message="Compartilhe!"
                />
              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} onSubmit={handleNewLogin} initialData={configuration?.sign_in_type === 'cpf' ? { country: 'BR', document_number: emailRecord } : { country: 'BR', email: emailRecord }}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Novo cadastro</h1>


            <SelectSimple index={0} setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

            {countryISO === 'BR' ?
              <SelectSimple index={1} options={states} name="state" label={'Estado'} />
              : <Input name="state" placeholder="Estado" />}
            <Input name="city" placeholder="Município" />

            <Input name="name" placeholder="Nome completo" />
            <Input name="email" placeholder="E-mail" />

            {countryISO === 'BR' ?
              <MaskedInput name="document_number" mask='999.999.999-99' placeholder={'CPF'} />
              : <Input name="document_number" placeholder="Número de documento" />}

            {countryISO === 'BR' ?
              <MaskedInput name="phone" mask='(99) 99999.9999' placeholder={'Telefone com DDD'} />
              : <Input name="phone" placeholder="Telefone" />}

            <SelectSimple index={3} options={[
              { label: 'Acadêmico(a) de medicina', value: 'Acadêmico de medicina' },
              { label: 'Acadêmico(a) de outras áreas da saúde', value: 'Acadêmico de outras áreas da saúde' },
              { label: 'Aluno(a) FAMETRO', value: 'Aluno FAMETRO' },
              { label: 'Demais profissoinais de Saúde', value: 'Demais profissoinais de Saúde' },
              { label: 'Médico', value: 'Médico' },
              { label: 'Médico(a) Residente', value: 'Médico Residente' },

            ]} name="category_id" label={'Categoria'} />



            <SelectSimple index={5}

              options={[
                { label: 'WhatsApp', value: 'WhatsApp' },
                { label: 'Indicação', value: 'Indicação' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Linkedin', value: 'Linkedin' },
                { label: 'E-mail marketing', value: 'E-mail marketing' },
                { label: 'SMS', value: 'SMS' },
                { label: 'Workplace', value: 'Workplace' },
                { label: 'Google', value: 'Google' },
                { label: 'Outros', value: 'Outros' },



              ]}
              name="know_about"
              formRef={formRef}
              label="Como ficou sabendo do 15º Encontro de Previdência Complementar - Região Sul?"
            />


            {readFields()}


            {withPassword && <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder={translate('Cadastre sua senha de acesso')}
            />}



            {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}


            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
              <CheckboxInput
                style={{ display: 'flex' }}
                name="term"
                unique={true}
                preText={(
                  <>
                    {' '}
                    Estou ciente de que estou fornecendo à {authTitle} e seus parceiros meus
                    contatos gerais, os quais são dados pessoais protegidos pela
                    Lei Geral de Proteção de Dados brasileira, para gestão de
                    relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
                    {' '}

                  </>
                )}
                options={[
                  {
                    id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                    label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                    value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  },
                  {
                    id: `Não autorizo o compartilhamento dos meus dados`,
                    label: 'Não autorizo o compartilhamento dos meus dados',
                    value: `Não autorizo o compartilhamento dos meus dados`,
                  },
                ]}
              />

              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Finalizar inscrição


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
