import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, defaultProjectHash } from '../../../../../../config';
import { FaFileExcel, FaList, FaMoneyBill, FaTable, FaTicketAlt, FaUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit, { convertToXLSX } from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';

interface ICreateModule {
    lineData: Record<string, any>;
}

export const CheckCourtesies: React.FC = () => {

    const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
    const { addToast } = useToast();
    const { addModal } = useModal();

    const gerarRelatorioCortesias = async () => {



        addToast({ title: 'Gerando relatório', type: 'info' });

        const cortesies = await api.get(`/v3/sales-cortesy`, { params: { limitItems: 10000, where: { project_id: projectHash } } })

        const salesProducts = await api.get(`/sales-products`, { params: { limitItems: 10000, where: { project_id: projectHash } } })

        const items: Array<string[]> = [];

        cortesies.data.rows.map(cortesy => {

            const used = salesProducts?.data?.rows.filter(s => s.sale_hash === cortesy?.sale_hash && s?.client_id)
            const notUsed = salesProducts?.data?.rows.filter(s => s.sale_hash === cortesy?.sale_hash && !s?.client_id)
            used.map(u => {
                items.push([cortesy.sale_hash, cortesy.title, u?.client_id_response])
            })
            notUsed.map(u => {
                items.push([cortesy.sale_hash, cortesy.title, 'Não utilizado'])
            })

        })

        const exportData = (data) => {

            convertToXLSX([['Código', 'Título', 'Usuários'], ...data])

        }

        const content = <>
            <table className='table'>
                <tbody><tr><td><FaFileExcel style={{ fontSize: '28px' }} onClick={() => exportData(items)} /></td><td></td><td></td></tr>
                    <tr><td>Código</td><td>Título</td><td>Usuário</td></tr>
                    {items?.map(i => <tr><td>{i?.[0]}</td><td>{i?.[1]}</td><td>{i?.[2]}</td></tr>)}
                </tbody>
            </table>

        </>

        addModal({ title: 'Listagem de ingressos de cortesia', content: content, theme: 'whiteModal' });



    }

    const gerarRelatorioFinanceiro = async () => {

        addToast({ title: 'Gerando Relatório Simplificado', type: 'info' });

        const cortesies = await api.get(`/v3/sales-cortesy`, { params: { limitItems: 10000, where: { project_id: projectHash } } })

        const salesProducts = await api.get(`/sales-products`, { params: { limitItems: 10000, where: { project_id: projectHash } } })

        const items: Array<string[]> = [];

        cortesies.data.rows.map(cortesy => {

            const countUsed = salesProducts?.data?.rows.filter(s => s.sale_hash === cortesy?.sale_hash && s?.client_id)?.length
            const countNotUsed = salesProducts?.data?.rows.filter(s => s.sale_hash === cortesy?.sale_hash && !s?.client_id)?.length

            items.push([cortesy.sale_hash, cortesy.title, countUsed + countNotUsed, countUsed, countNotUsed])
        })


        const exportData = (data) => {

            convertToXLSX([['Código', 'Título', 'QTD', 'Utilizados', 'Disponíveis'], ...data])

        }

        const content = <>
            <table className='table'>
                <tbody>
                    <tr><td><FaFileExcel style={{ fontSize: '28px' }} onClick={() => exportData(items)} /></td><td></td><td></td></tr>
                    <tr><td>Código</td><td>Título</td><td>QTD</td><td>Utilizados</td><td>Diponíveis</td></tr>
                    {items?.map(i => <tr><td>{i[0]}</td><td>{i[1]}</td><td>{i[2]}</td><td>{i[3]}</td><td>{i[4]}</td></tr>)}
                </tbody>
            </table>

        </>

        addModal({ title: 'Cortesias', content: content, theme: 'whiteModal' });
    }

    const moduleUpdate = (): JSX.Element => (
        projectHash ? <> <button title='Número de cortesias' className="searchButton" type="button" onClick={gerarRelatorioFinanceiro}>
            <FaList />
        </button>

            <button title='Lista de cortesias' className="searchButton" type="button" onClick={() => gerarRelatorioCortesias()}>
                <FaTable />
            </button> </>
            : <></>
    );

    return moduleUpdate();
}
