import styled from 'styled-components';


export const ButtonStats = styled.div`

  padding: 10px;
  background: #fff;
  border: 2px solid #333;
  color: #333;
  border-radius: 15px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

 
`;