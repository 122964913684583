import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBarcode, FaBell, FaCertificate, FaCopy, FaFile, FaIdBadge, FaImages, FaPaperPlane, FaQrcode, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser, FaWhatsapp } from 'react-icons/fa';

import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import api from '../../../../services/api';

import QRCode from 'qrcode.react';
import { Container, ContainerWhite } from './styles';
import { useModal } from '../../../../hooks/Modal';
import QRCodeTicketComponent from '../../../DashboardPages/core/components/QRCodeTicketComponent';
import { useToast } from '../../../../hooks/Toast';
import { useLoading } from '../../../../hooks/LoadingHook';
import { formatReal } from '../../../../utils/format';
import { FiShoppingCart } from 'react-icons/fi';
import { date } from '../../../../utils/date';

interface IProps {
  changeStatus: Function;

}

const MinhasIndicacoes: React.FC<IProps> = ({ changeStatus }) => {

  const { user } = useAuth();
  const { addModal, removeModal } = useModal();
  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])

  const { translate } = useLanguage();

  const { addLoading, removeLoading } = useLoading();

  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado",
    expired: "Expirado"
  }



  const load = async () => {



    const response = await api.get(`/v3/check-my-sale-recommendations`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }

  useEffect(() => {



    load();


  }, [])



  const modalItem = async (saleHash) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    const products = await api.get(`/v3/check-my-sale-product-recommendation/${saleHash}`);
    const items: Array<JSX.Element> = [];

    products?.data?.rows?.map(item => {

      items.push(<tr><td>{item?.product_id_response?.title}</td><td>{item?.payer_id_response?.name}</td></tr>)

    })

    addModal({
      title: 'Produtos', theme: 'whiteModal', content: <> <table className='table'>
        <tbody>
          <tr>

            <td><strong>Produto</strong></td>

            <td><strong>Comprador</strong></td>
          </tr>
          {items}
        </tbody>
      </table></>
    })
    removeLoading('loading');
  }

  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>{translate('Minhas indicações')}</p>
            <table><tbody>
              <tr>
                <th></th>
                <th>{translate('Protocolo')}</th>
                <th>{translate('Adquirido por')}</th>
                <th>{translate('status')}</th>
                <th>{translate('Total')}</th>
                <th>{translate('Criado em:')}</th>
                <th>{translate('Pago em:')}</th>
              </tr>
              {tickets.map(sale => {

                return <tr key={sale._id}>

                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <button className='buttonIcon' onClick={() => modalItem(sale?.sale_hash)} title="Visualizar ingressos" type='button'><FiShoppingCart /></button>
                  </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    {sale?.sale_hash}
                  </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    {sale?.payer_id_response?.name}
                  </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    {getPaymentStatus?.[sale?.payment_status_id]}
                  </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    R$ {formatReal(sale?.total)}
                  </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    {date(sale?.createdAt)}
                  </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    {date(sale?.approved_at)}
                  </td>
                </tr>
              })}


              {tickets?.length < 1 &&
                <tr  ><td>{translate('Nenhum pagamento identificado')}</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default MinhasIndicacoes;
