import styled, { css } from 'styled-components';

import theme from '../../../../../../styles/ThemeComponents';
import { defaultTheme } from '../../../../../../config';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: ${theme.input.background};
  color: ${theme.input.color};
  border-radius: 10px;
  border: ${theme.input.border};
  padding: 0px 16px;
  position: relative;
  height: 46px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  color: #333;
  margin-top: 5px;
`;



interface IModal {
  top?: boolean;
  left?: boolean;
  bottom?: boolean;
  right?: boolean;
  fullHeight?: boolean;
}

export const Modal = styled.div<IModal>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: 0.5s all;

  @media (max-width: 1200px) {
    position: fixed;
  }

  ${props =>
    props.top &&
    css`
      justify-content: flex-start !important;
    `}

    ${props =>
    props.bottom &&
    css`
        justify-content: flex-end !important;
      `}

    ${props =>
    props.right &&
    css`
        align-items: flex-end !important;
      `}

    ${props =>
    props.left &&
    css`
        align-items: flex-start !important;
      `}



  > div {
    overflow-y: auto;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    color: #333;
    background: #fff;
    margin: 0;


    @media (max-width: 750px) {
      max-width: 100%;
      width: 100%;
  }

    ${props =>
    props.fullHeight &&
    css`
        min-height: 100vh !important;
        justify-content: flex-start;
      `}

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
    }

    > p {
      color: #333;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }

    > div {
      color: #333;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;

      > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
      text-align: left;
width: 100%;
    }

    > p {
      color: #333;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }
    }
  }
`;
