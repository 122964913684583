import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import downloadit from '../../../../../../utils/download';
import format from '../../../../../../utils/format';
import { ButtonStats } from './styles';

interface ICreateModule {
  lineData: Record<string, any>;
}

const SaleStats: React.FC = () => {
  const { addToast } = useToast();
  const [total, setTotal] = useState('R$ 0,00');
  const [paid, setPaid] = useState('R$ 0,00');
  const [waiting, setWaiting] = useState('R$ 0,00');

  const [waitingFunds, setWaitingFunds] = useState('R$ 0,00');
  const [available, setAvailable] = useState('R$ 0,00');

  const { projectHash } = useParams<Record<string, any>>();

  const load = async () => {

    const response = await api.get(`/report/sales_price/${projectHash}`);


    setWaiting(`R$ ${format(response?.data?.waiting)}`);
    setPaid(`R$ ${format(response?.data?.paid)}`);
    setTotal(`R$ ${format(response?.data?.all)}`);



  }




  const load2 = async () => {
    if (projectHash) {
      const response = await api.get(`/check-balance/${projectHash}`);

      setWaitingFunds(response?.data?.waiting_funds ? `R$ ${format(response?.data?.waiting_funds)}` : `R$ 0,00`);
      setAvailable(response?.data?.available ? `R$ ${format(response?.data?.available)}` : `R$ 0,00`);

    }
  }

  useEffect(() => {
    if (projectHash) {
      load();
      load2();
    }
  }, [projectHash])

  if (!projectHash) {
    return <></>;
  }

  return <><div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
    <ButtonStats>Total:{` `} {total} (Líquido)</ButtonStats>
    <ButtonStats>Pagos:{` `} {paid}</ButtonStats>
    <ButtonStats>Aguardando:{` `} {waiting}</ButtonStats>
  </div>
    {projectHash && <>
      <h2>Vendidos</h2>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <ButtonStats>Disponível para saque:{` `} {available} </ButtonStats>
        {/* <ButtonStats>A receber:{` `} {waitingFunds}</ButtonStats> */}
      </div>


    </>}
  </>

}

export default SaleStats;
