import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';

interface ICreateModule {
  lineData: Record<string, any>;
}

export const FinanceReportModule : React.FC = () => {

  const {projectHash} = useParams<Record<string,any>>();
  const {addToast} = useToast();

    
  const gerarRelatorioFinanceiro = async() => {

    addToast({title:'Gerando relatório', type:'info'});
  
    await downloadit({ url : `/check-saldo/${projectHash}`});
   
  
  }

  const moduleUpdate = (): JSX.Element => (
    projectHash ? <button className="searchButton" type="button" onClick={gerarRelatorioFinanceiro}>
    <FaMoneyBill/>
  </button> : <></>
  );

  return moduleUpdate();
}
