import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiCopy, FiSend } from 'react-icons/fi';

interface ICreateModule {
  lineData: Record<string, any>;
  reload: Function;
}

export default function DuplicateModule({
  reload,
  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();


  const handleSend = async (id: string): Promise<void> => {
    try {
      const response = await api.post(`/sales-coupon-duplicate/${id}`);

      reload();
      addToast({ title: 'Duplicado!', type: 'success' });
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Duplicar" type="button" onClick={() => handleSend(id)}>
      <FiCopy />
    </button> : <></>
  );

  return moduleUpdate(lineData._id.toString());
}
