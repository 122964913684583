export function validaCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++)
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
}


export function validaCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;

    const validarDigitos = (digitos, posicoes = 10, somaDigitos = 0) => {
        digitos = digitos.substring(0, posicoes - 1).split('');
        digitos.forEach((e, i) => {
            somaDigitos += e * ((posicoes - 7) + (i % 8));
        });
        digitos = somaDigitos % 11;
        return digitos < 2 ? 0 : 11 - digitos;
    };

    const digito1 = validarDigitos(cnpj, 13);
    const digito2 = validarDigitos(cnpj, 14, digito1 * 2);
    if (cnpj.substring(12, 14) === `${digito1}${digito2}`) return true;

    return false;
}