import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../../../services/api';
import { day, monthNumber as monthT, year as yearT, monthFull as monthFunction } from '../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import themeDefault from '../../../../styles/ThemeComponents';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash } from '../../../../config';
import { useHistory } from 'react-router-dom';
interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const CaixaDePesquisa: React.FC<IParam> = () => {
    const [search, setSearch] = useState('');
    const history = useHistory();

    const sendToPesquisa = (text) => {

        history.push(`/conteudos?search=${encodeURIComponent(text)}`);
        window.scrollTo(0, 0);
        return;
    }

    return <div className='gap-xs shadow max-600'>
        <form className='full-width row' onSubmit={() => { sendToPesquisa(search) }}>
            <input placeholder='O que você gostaria de cozinhar hoje?' value={search} onChange={(e) => setSearch(e?.target?.value)}></input>
            <button className='greenButton2'>PESQUISAR</button>
        </form>
    </div>


}

export default CaixaDePesquisa;