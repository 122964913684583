import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}


export const searchable: Record<string, any> = {
  _id: { ref: '_id', column: '_id', label: 'ID', show: false },
  project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false, },
  event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false, },
  user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
  name: { ref: 'name', column: 'name', label: 'Nome' },
  last_name: { ref: 'last_name', column: 'last_name', label: 'Sobrenome' },
  email: { ref: 'email', column: 'email', label: 'Email' },
  document_number: {
    ref: 'document_number',
    column: 'document_number',
    label: 'CPF',
  },
  category_id: {
    ref: 'category_id',
    column: 'category_id',
    label: 'Categoria',
  },
  company: {
    ref: 'company',
    column: 'company',
    label: 'Empresa',
  },
  position: {
    ref: 'position',
    column: 'position',
    label: 'Cargo',
  },
  reference: {
    ref: 'reference',
    column: 'reference',
    label: 'Credenciado?',
  },

  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};

const CredentialPage: React.FC = () => {
  const endpoint = '/students-credential';
  const title = 'Visitantes únicos';
  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },

    email: { ref: 'email', column: 'email', label: 'Email' },
    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF',
    },
    category_id: {
      ref: 'category_id',
      column: 'category_id',
      label: 'Categoria',
    },
    company: {
      ref: 'company',
      column: 'company',
      label: 'Empresa',
    },
    position: {
      ref: 'position',
      column: 'position',
      label: 'Cargo',
    },



    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    last_name: { model: 'input', type: 'text', name: 'last_name', label: 'Sobrenome' },

    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
    category_id: { model: 'input', type: 'text', name: 'category_id', label: 'Categoria' },
    company: { model: 'input', type: 'text', name: 'company', label: 'Empresa' },
    position: { model: 'input', type: 'text', name: 'position', label: 'Cargo' },

    reference: { model: 'input', type: 'text', name: 'reference', label: 'Referência' },


  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.cracha({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.qrCode({
          lineData,
          title,

        }),

      (lineData: Record<string, any>) =>
        lineModules.credenciar({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),

    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CredentialPage;
