import usePaymentRequest from './paymentRequest';

export default function usePaymentWithPix() {

  const { paymentRequest } = usePaymentRequest()

  const payWithPix = async ({ paymentContext, moveToCart }) => {

    await paymentRequest({ paymentContext, moveToCart });

    return false;

  }

  return { payWithPix };

}