import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container, HeaderButton, HeaderButton2 } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import { FaBox, FaBoxes, FaChartBar, FaCheck, FaComment, FaFileArchive, FaIdBadge, FaQrcode, FaUser, FaUserPlus } from 'react-icons/fa';

import RecordCheckin from '../../../AreaExpositor/Reporter';
import CredentialPage from '../CredentialPage';
import api from '../../../../services/api';
import { setTimeout } from 'timers';
import { useParams } from 'react-router-dom';

import FileGallerys from '../../../FileGallery';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const CheckinsWithCredentials: React.FC = () => {
  const endpoint = '/checkin-content';
  const title = 'Checkins';

  const { projectHash } = useParams<Record<string, any>>();
  const formSchema = {

  };


  const formSchemaUpdate = {

  };
  const formValidation: Record<string, ValidationSchema> = {

  };


  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    room_id: { ref: 'room_id', column: 'room_id', label: 'Referência' },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
    company: { ref: 'company', column: 'company', label: 'Empresa' },
    position: { ref: 'position', column: 'position', label: 'Telefone' },
    category_id: { ref: 'category_id', column: 'category_id', label: 'Categoria' },
    enter: { ref: 'enter', column: 'enter', label: 'Ação' },
    posted_by_name: {
      ref: 'posted_by_name',
      column: 'posted_by_name',

      label: 'Nome do Leitor',
    },
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };



  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [

    ],
  };

  const configVisitors: ISearchComponent = {
    idKey: '/checkin-content-group',
    title,
    endpoint: '/checkin-content-group',
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [

    ],
  };

  const [currentPage, setCurrentPage] = useState('subscribers');


  const [status, setStatus] = useState({ subscribers: '0', credentials: '0' });


  const load = async () => {

    const response = await api.get(`/students-credential-count/${projectHash}`);

    const item = { subscribers: '0', credentials: '0' };

    if (response?.data?.subscribers) {
      item.subscribers = response.data.subscribers;
    }
    if (response?.data?.credentials) {
      item.credentials = response.data.credentials;
    }

    setStatus({ ...item });

    setTimeout(() => { load() }, 5000)

  }

  useEffect(() => {
    load();
  }, [])


  return (
    <Container>

      <HeaderButton>

        <button className={currentPage === 'subscribers' ? 'active' : ''} onClick={() => setCurrentPage('subscribers')}><FaUserPlus /> Checkin Geral</button>
        <button className={currentPage === 'visitors' ? 'active' : ''} onClick={() => setCurrentPage('visitors')}><FaUser />Presentes agora</button>
        <button className={currentPage === 'qrcode' ? 'active' : ''} onClick={() => setCurrentPage('qrcode')}><FaQrcode /> Leituras</button>
        <button className={currentPage === 'chart' ? 'active' : ''} onClick={() => setCurrentPage('chart')}><FaChartBar /> Gráficos</button>
      </HeaderButton>
      <HeaderButton2>


        <button><FaUserPlus /> {`Checkins: ${status?.subscribers}`}</button>
        <button><FaCheck /> {`Presentes Agora ${status?.credentials}`}</button>
      </HeaderButton2>
      {currentPage === 'subscribers' ? <CredentialPage /> : <></>}
      {currentPage === 'qrcode' ? <SearchComponent {...config} /> : <></>}
      {currentPage === 'visitors' ? <SearchComponent {...configVisitors} /> : <></>}
      {currentPage === 'chart' ? <RecordCheckin /> : <></>}

    </Container>
  );
};

export default CheckinsWithCredentials;
