import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../../hooks/Language';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import calculateDiscount from '../../Functions/calculateDiscount';
import { ProductItemStyle } from '../AddProductsToCart/styles';

export interface ICoupon {
    products: Array<Record<string, any>>;
    hash: string;
}

interface IProps {
    coupon?: Record<string, any>;
    setCoupon: Function;
    cart: Array<Record<string, any>>;

}


export function useCheckCuponCode() {
    const { addLoading, removeLoading } = useLoading();


    const checkCuponCode = async (couponValue, projectHash) => {

        const couponV = couponValue?.toUpperCase();

        addLoading({ title: 'Carregando', key: 'loading' })

        try {
            const response = await api.post(`/check-general-coupon/${projectHash}`, {
                hash: couponV,

            });

            const data = response.data;

            if (!data?._id) {
                removeLoading('loading');
                return {};
            }
            removeLoading('loading');

            return response?.data;
        }
        catch (err) {
            removeLoading('loading');
            return {}
        }

    }

    return { checkCuponCode };



}



const DiscountCouponRules: React.FC<IProps> = ({
    coupon,
    setCoupon,
    cart

}) => {
    const { projectHash } = useConfiguration();
    const { translate } = useLanguage();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<string>(coupon?.hash || '');
    const [valid, setValid] = useState('');
    const { addToast } = useToast();
    const [rules, setRules] = useState<Array<Record<string, any>>>([]);


    const load = async (project) => {

        const rulesInfo = await api.get(`list-coupon-rules/${project}`);

        if (rulesInfo?.data?.rows) {
            setRules([...rulesInfo?.data?.rows])
        }


    }

    useEffect(() => {
        if (projectHash) {
            load(projectHash)
        }

    }, [projectHash])


    const checkCupon = async (couponValue) => {



        const couponV = couponValue?.toUpperCase();

        setValid('');
        setLoading(true);

        try {
            const response = await api.post(`/check-general-coupon/${projectHash}`, {
                hash: couponV,

            });

            const data = response.data;

            if (!data?._id) {
                setValid(translate('Coupon inválido'));
                setLoading(false);
                return;
            }


            setCoupon(response?.data);

            setLoading(false);
        }
        catch (err) {

            setValid(err?.response?.data?.message || err?.response?.data?.error || 'Coupon inválido');
            setLoading(false);
        }





    }

    const listValidCoupons = ({ cartInfo, userInfo, rulesInfo }) => {



        const items: Array<JSX.Element> = [];

        rulesInfo.map(rule => {

            let applyRule = true;

            rule.rules?.map(ruleTopic => {

                if (ruleTopic?.type === 'user') {

                    if (user?.[ruleTopic?.field] !== ruleTopic?.value) {
                        applyRule = false;
                    }

                }
                else if (ruleTopic?.type === 'cart') {

                    const filter = cartInfo?.filter(product => product?.[ruleTopic?.field] === ruleTopic?.value);

                    const quantity = ruleTopic?.quantity ? parseInt(ruleTopic?.quantity) : 0;

                    if (quantity < 1) {
                        /* this happens when the rule is misconfigurated */
                        applyRule = false;
                    }

                    if (filter?.length < quantity) {
                        applyRule = false;
                    }




                }
                else { applyRule = false }

            })

            if (applyRule) {





                items.push(<ProductItemStyle>
                    <div className='addProductAction'>
                        <div className={coupon?.hash === rule?.coupon_hash ? 'activeProduct changeble' : 'inactiveProduct changeble'} onClick={() => { checkCupon(rule?.coupon_hash) }}>
                            {coupon?.hash === rule?.coupon_hash ? <FaCheck /> : <> <FaCheck />  </>}
                        </div>
                    </div>
                    <div className='addProductInfo'><p style={{ fontWeight: 'bold' }}>{rule?.title} </p>
                        {rule?.description ? <p dangerouslySetInnerHTML={{ __html: rule?.description }} /> : <></>}
                    </div>
                </ProductItemStyle>)
            }
            else {

                if (coupon?.hash === rule?.coupon_hash) {
                    setCoupon({});
                    addToast({ title: 'Cupon de desconto removido' })
                }

            }


        })

        if (items?.length > 0) {
            return <>
                <h2 style={{ margin: '10px 0px', fontSize: '14px' }}>{translate('Cupons disponíveis')}</h2>
                {items}
            </>
        }

        return <></>;

    }




    return <aside style={{ margin: '10px auto', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>


        {loading ? <p style={{ margin: '5px 0px', color: '#333', textAlign: 'right', width: '100%' }}><FaSpinner className='spin' size={20} style={{ minWidth: '20px', width: '20px', color: '#333', marginRight: '10px' }} />  {translate(`Carregando`)}</p> : <></>}

        {listValidCoupons({ cartInfo: cart, userInfo: user, rulesInfo: rules })}
        {valid !== '' ? <p style={{ margin: '5px 0px', color: 'rgb(150,0,0)', textAlign: 'right', width: '100%' }}>  {translate(valid)}</p> : <></>}
    </aside>

}

export default DiscountCouponRules;
