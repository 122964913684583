import calculateCartPrice from "./calculateCartPrice";


export default function calculateDiscount(coupon, cart) {



    const currentValue = calculateCartPrice(cart);


    const discountedValue = cart.reduce((prev, item) => {




        const couponItem = coupon?.products?.find(product => product?.product === item?._id?.toString());

        const nextValue = couponItem ? (parseInt(couponItem.price, 10) * item?.quantity) : (parseInt(item?.price, 10) * item?.quantity);

        prev = prev + nextValue;
        return prev;

    }, 0);



    return { currentValue, discountedValue, discount: (currentValue - discountedValue) };



}