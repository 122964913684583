import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import speakerLink from './modules/speakerLink';
import exportList from './modules/ExportList';
import moderatorLink from './modules/ModeratorLink';
import socketLink from './modules/UpdateLesson';

import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const Lesson: React.FC = () => {
  const endpoint = '/lesson-slides';
  const { socket } = useSocket();
  const title = 'Slides de Chat';


  const formSchema = {


    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    reference: {
      model: 'input',
      name: 'reference',
      label: 'Referência',
      defaultValue: '',
    },

    posters: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'posters',
      label: 'Slides de sala',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Link', name: 'link_url' },
      ],
    },



  };

  const formSchemaUpdate = {
    ...formSchema,

  };



  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },

  };


  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    reference: { ref: 'reference', column: 'reference', label: 'SALA de Referência' },
    title: { ref: 'title', column: 'title', label: 'Título' },

  };



  const config2: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title: 'Slides',
    endpoint: '/lesson-slides',
    schema: schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [


      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default Lesson;
