import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite, Paragraph } from './styles';


import { urlWebsite } from '../../../../../../config';
import IframePlayer from '../../../../../../components/UIComponents/IframePlayer';
import VideoBackground from '../../../../../../components/VideoBackground';


const SinglePageTemplate: React.FC<Record<string, any>> = ({ theme, pageContent }) => {
  const { hash } = pageContent;
  return (
    <>

      <Container style={{ flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }} >
        <div className='content'>
          <h2 className='titleH2' style={{ color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }}>{pageContent?.title}</h2>
          <Paragraph style={{ background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} />

          {pageContent?.files && pageContent?.files.map((item: Record<string, any>) => {
            return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
          })}

          {pageContent?.buttons?.length > 0 ? <div style={{ margin: '20px auto' }}>
            {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
              return <a href={`${itemButton.link}`} ><button className={itemButton.class_name || 'greenButton2 shadow'}>{itemButton.title}</button></a>
            })}
          </div> : <></>}
        </div>

        {pageContent?.images && pageContent?.images?.length > 0 && pageContent?.images?.map((image: Record<string, any>) => {

          return <img src={`${urlWebsite}/${image?.url}`} />

        })}

        {pageContent?.video ? <div className='imageHolder'><VideoBackground path={pageContent?.video} /> </div> : <></>}


      </Container>
    </>
  );
};
export default SinglePageTemplate;
