import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { logo } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

interface SignInData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signInAdmin } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signInAdmin({ email, password });
        window.location.href = '/manager';
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signInAdmin, addToast, history],
  );

  return (
    <Container>


      <Content>

        <AnimatedDiv>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input icon={FiMail} name="email" placeholder="E-mail" />
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="senha"
            />
            <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button style={{ margin: '10px auto' }} type="submit">Entrar</Button>
            </aside>
          </Form>
        </AnimatedDiv>
      </Content>
    </Container>
  );
};
export default SignIn;
