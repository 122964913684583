import React from 'react';
import { ContainerLineUI } from '../../../UIComponents/ContainerLine/styles';
import Paragraph from '../../../UIComponents/Paragraph';
import ParagraphDangerous from '../../../UIComponents/ParagraphDangerous';
import Title from '../../../UIComponents/Title';
import DefaultCategoryGallery from '../DefaultCategoryGallery';

interface IProps {
    bigAreas: Record<string, any>[];
    posters: Record<string, any>[];
    showPoster: Function;
    eixo: string;
}

const DisplayCientificLibraryCategory: React.FC<IProps> = ({ eixo, bigAreas, posters, showPoster }) => {


    return <ContainerLineUI className='full-width column'  >  <ContainerLineUI className='full-width column'  >
        <ContainerLineUI className='max-1200 column column-start pad' style={{ padding: '15px 30px' }}>
            <Title>{eixo ? eixo?.toUpperCase() : "Receitas CCGL"}</Title>
            <Paragraph>Conheça o conteúdo disponível</Paragraph>
        </ContainerLineUI>
        <DefaultCategoryGallery setCallback={showPoster} filtered={eixo ? posters?.filter(i => i?.category?.indexOf(eixo) > 0) : posters} />
    </ContainerLineUI>

    </ContainerLineUI>
}

export default DisplayCientificLibraryCategory;