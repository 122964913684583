import usePaymentWithBoleto from "./boletoPayment";
import usePaymentWithCourtesy from "./courtesyPayment";
import usePaymentWithCreditCard from "./creditCardPayment";
import usePaymentWithPix from "./pixPayment";

export default function useSendPaymentRequest() {

    const { payWithCreditCard } = usePaymentWithCreditCard();
    const { payWithBoleto } = usePaymentWithBoleto();
    const { payWithCourtesy } = usePaymentWithCourtesy();
    const { payWithPix } = usePaymentWithPix();


    const finishPaymentRequest = async (paymentContext, moveToCart) => {

        const { paymentMethod } = paymentContext;


        const validPaymentMethods = {
            credit_card: () => payWithCreditCard({ paymentContext, moveToCart }),
            boleto: () => payWithBoleto({ paymentContext, moveToCart }),
            courtesy: () => payWithCourtesy({ paymentContext, moveToCart }),
            cortesy: () => payWithCourtesy({ paymentContext, moveToCart }),
            pix: () => payWithPix({ paymentContext, moveToCart })
        }


        if (!validPaymentMethods[paymentMethod]) {
            return false;
        }

        await validPaymentMethods[paymentMethod]();



    }

    return { finishPaymentRequest };

}