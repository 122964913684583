import styled from 'styled-components';

export const ProductsContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  width: 100%;

  background: #fff;
  border-radius: 15px;
  max-width: 600px;
  padding: 20px 20px;
  color: #333;
  z-index: 1;

  min-width: 340px;
    margin: 10px;

  @media (max-width: 750px) {
      padding: 10px;
    }

  table {
    border-collapse: collapse;
    margin: 15px 0px;
    tr{
    
      >td{
        border:1px solid #aaa;
        padding: 10px;
      }
    }
  }

  > h1 {
    color: #fff;
  }

  .promotion {
    color: #555;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    > span {
      position: absolute;
      left: 10%;
      top: 45%;
      height: 2px;
      background: rgba(200, 0, 0, 0.7);
      width: 80%;
    }
  }

  .productTitle {
    width: 100%; 
    justify-content: flex-start; 
    display: flex; 
    padding: 10px; 
    text-align: left; 
    margin-right: 10px;

    @media (max-width: 750px) {
      margin: 0px;
      text-align: center;
      justify-content: center; 
    }
  }

  .productBlock{
    transition: 0.3s;
    width: 100% ;
    background: #fff ;
    &:hover{
      background: #f5f5f5 ;
    }

    svg{
      
      cursor: pointer;
    }

    @media (max-width: 750px) {
      >aside{
      flex-direction: column !important;
     >p{
        font-size: 10px !important;
        line-height: 12px !important;
      }
      }
    }
  }

  .productBlockAdd{
    transition: 0.3s;
    max-width: 330px !important;
    align-self: flex-end;
    padding: 5px 10px !important;
    min-height: 50px !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    h2{
      font-size: 12px !important;
    }
    
    background: rgba(0,255,0,0.1);
    &:hover{
      background: rgba(0,255,0,0.2);
    }
  }

  table{
    width: 100% !important;
  }

  .title {
  }

  .buyButton {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
    margin-left: 5px;

    margin: 0;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(60%);
    padding: 10px 20px !important;
    border-radius: 8px;

    width: 100% !important;
    max-width: 50%;
  }

  .priceDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;

    > .eventoDigital {
      color: #333;
    }
    > .eventoFisico {
      color: #333;
    }

    > h3 {
      font-size: 16px;
    }
  }

  > aside {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: stretch;
    width: 100%;

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    > div, >a {
      position: relative;
      padding: 30px 20px;
      width: 100%;
      max-width: 300px;
      margin: 15px;
      background: white;
      color: #333;
      border: 2px solid #333;
      border-radius: 8px;

      @media (max-width: 750px) {
        max-width: 100%;
        margin: 15px 0;
      }

      > h2 {
        font-size: 16px;
        margin-bottom: 0px;
        text-align: center;
        font-weight: bold;
        justify-content: center;
        min-height: 60px;

        @media (max-width: 750px) {
          justify-content: flex-start;
          text-align: left;
          min-height: auto;
          max-width: 100%;
        }

        display: flex;
        align-items: center;

        color: #333;
      }

      > h3 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 300;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
      }

      > button {
        background: rgb(0, 100, 150);
        color: #fff;
        border: 0;
        transition: background 0.2s;
        padding: 15px;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        &:hover {
          background: rgb(0, 90, 130);
        }
      }

      > ul {
        margin-top: 10px;
        > li {
          list-style: none;
          display: flex;

          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 450px) {

    width: 100%;
  }
`;
