import React, { useEffect, useRef, InputHTMLAttributes, useState } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import theme from '../../../styles/ThemeComponents';
import { uuid } from 'uuidv4';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  background_line?: string;
  setCallBack?: Function;
  options: {
    id: string;
    value: string;
    label: string;
    sub_title?: string;
  }[];
}
const CheckboxInput: React.FC<Props> = ({
  name,
  options,
  preText = '',
  posText = '',
  unique = false,
  style,
  background_line = '',
  setCallBack = (value) => { },
  ...rest
}) => {
  const [key, setKey] = useState(uuid());
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  const [value, setValue] = useState(defaultValue || []);

  useEffect(() => {
    setCallBack(value);
    setKey(uuid())
  }, [value])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return value;
      },
      clearValue: (refs: HTMLInputElement[]) => {

      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        setValue(values)
      },
    });
  }, [defaultValue, fieldName, registerField, value]);
  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{preText}</label>}


      <label style={{ cursor: 'pointer', color: theme.color, width: '100%', background: background_line ? background_line : 'none', padding: "5px" }} htmlFor={`${name}_todos`} key={`${name}_todos_${key}`}>
        <input style={{ color: theme.color, border: '1px solid#333' }}
          onClick={(e) => {
            console.log(value?.length)
            if (value?.length > 0) {
              console.log('Zerou o value')
              setValue([])
            }
            else {
              const valuesItems = options?.reduce((prev, item) => {
                prev.push(item?.value);
                return prev;
              }, [] as Array<string>)
              setValue(valuesItems)
            }
          }}
          checked={value.length === options?.length}


          value={''}
          type="checkbox"
          id={`${name}_todos`}

        />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10px' }}>
          <strong style={{ color: theme.color, fontSize: '14px' }}>Todos</strong>
        </div>
      </label>


      {options.map((option, index) => (
        <label style={{ cursor: 'pointer', color: theme.color, width: '100%', background: background_line ? background_line : 'none', padding: "5px" }} htmlFor={`${name}_${option.id}`} key={`${name}_${option.id}_${key}`}>
          <input style={{ color: theme.color, border: '1px solid#333' }}
            onClick={(e) => {
              const index = value.findIndex((dv: string) => dv === option.id);
              if (index >= 0) {
                const newValue = value.reduce((prev, item, indexV) => {

                  if (index !== indexV) {
                    prev.push(item);
                  }

                  return prev;


                }, []);

                setValue(newValue);
              }
              else {
                setValue([...value, option?.id]);
              }
            }}
            checked={value.find((dv: string) => dv === option.id)}

            value={option.value}
            type="checkbox"
            id={`${name}_${option.id}`}
            {...rest}
          />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10px' }}>
            <strong style={{ color: theme.color, fontSize: '14px' }}>{option.label}</strong>
            {option.sub_title && <p style={{ color: theme.color, fontSize: '12px' }}>{option.sub_title}</p>}
          </div>
        </label>
      ))}
      {posText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{posText}</label>}
    </Container>
  );
};
export default CheckboxInput;
