import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import RichText from '../RichText';
import { useModal } from 'src/hooks/Modal';
import { Form } from '@unform/web';
import { FaChevronDown, FaChevronUp, FaEdit, FaRobot, FaTrash } from 'react-icons/fa';
import TableWidget from 'src/pages/DashboardPages/features/PageCreator/modules/widgets/Table';
import api from 'src/services/api';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}


const TableInput: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef(null);
  const { addModal, removeModal } = useModal();
  const [tableData, setTableData] = useState<Array<Array<Record<string, any>>>>([[]]);


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return tableData
      },

      setValue: (ref, value) => {

        setTableData(value);

      },
    });
  }, [fieldName, registerField, tableData]);

  const addRow = () => {
    setTableData([...tableData, Array(tableData[0].length).fill([{ text: '' }])]);
  };

  const addColumn = () => {

    let data: Array<Array<Record<string, any>>> = [];

    tableData.map(row => {
      data.push([...row, { text: '' }]);
    })

    setTableData(data);
  };

  const removeRow = (index) => {
    const newTableData = tableData.filter((_, i) => i !== index);
    setTableData(newTableData);
  };

  const removeColumn = (index) => {
    const newTableData = tableData.map(row => row.filter((_, i) => i !== index));
    setTableData(newTableData);
  };

  const moveRowUp = (index) => {
    if (index === 0) return;
    const newTableData = [...tableData];
    [newTableData[index], newTableData[index - 1]] = [newTableData[index - 1], newTableData[index]];
    setTableData(newTableData);
  };

  const moveRowDown = (index) => {
    if (index === tableData.length - 1) return;
    const newTableData = [...tableData];
    [newTableData[index], newTableData[index + 1]] = [newTableData[index + 1], newTableData[index]];
    setTableData(newTableData);
  };

  const handleCellClick = (rowIndex, colIndex) => {
    const updateCell = (data) => {
      const newTableData = [...tableData];
      newTableData[rowIndex][colIndex] = { text: data.text };
      setTableData(newTableData);
      removeModal('editCellModal');
    };

    addModal({
      title: 'Alterar Célula',
      key: 'editCellModal',
      content: <Form onSubmit={updateCell} initialData={{ text: tableData[rowIndex][colIndex]?.text }}>
        <RichText name="text" placeholder="Editar conteúdo" />
        <button type="submit">Atualizar</button>
      </Form>

    });
  };

  const handleTableOrientation = () => {
    const transform = async (data) => {

      const transformData = await api.post('transform-data-with-ai', {

        prompt: `Analise o html a seguir, e estruture a tabela presente no html no formato JSON, com um array externo representando a tabela, um interno representando as linhas, e as linhas são um array de { text : string}. Exemplo: O resultado experado é [[{text: 'Célula 1 da linha 1', colspan="2"},{text: 'Célula 2 da linha 1'}],[  {text: 'Célula 2 da linha 2'}], [  {text: 'Célula 1 da linha 3', rowspan="2"}]]. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`,

        value: data?.text
      })

      setTableData(JSON.parse(transformData?.data?.translated));
      removeModal('editCellModal');
    };

    addModal({
      title: 'Transformar dados',
      key: 'editCellModal',
      content: <Form onSubmit={transform}  >
        <RichText name="text" placeholder="Editar conteúdo" />
        <button type="submit">Atualizar</button>
      </Form>

    });
  };


  return (
    <div>
      <h2>Table Editor</h2>
      <button type='button' onClick={handleTableOrientation}><FaRobot style={{ fontSize: '26px', color: '#333' }} /></button>
      <button type='button' onClick={addRow}>Add Row</button>
      <button type='button' onClick={addColumn}>Add Column</button>
      <table>
        <thead>
          <tr>
            {tableData[0].map((_, colIndex) => (
              <th key={colIndex}>
                Column {colIndex + 1}
                <FaTrash onClick={() => removeColumn(colIndex)} style={{ cursor: 'pointer' }} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rows, rowIndex) => {

            return <tr key={rowIndex}> {
              rows.map((cell, colIndex) => <td key={colIndex} onClick={() => handleCellClick(rowIndex, colIndex)}>
                <p dangerouslySetInnerHTML={{ __html: cell?.text }} />
                <FaEdit style={{ fontSize: '24px', color: '#333', cursor: 'pointer' }} />
              </td>
              )}
              <td>
                <FaChevronUp onClick={() => moveRowUp(rowIndex)} style={{ cursor: 'pointer' }} />
                <FaChevronDown onClick={() => moveRowDown(rowIndex)} style={{ cursor: 'pointer' }} />
                <FaTrash onClick={() => removeRow(rowIndex)} style={{ cursor: 'pointer' }} />
              </td>
            </tr>
          })}
        </tbody>
      </table>
      <TableWidget computer={{}} mobile={{}} data={tableData} />
    </div>
  );
};

export default TableInput;
