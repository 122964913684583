import React, { useState } from 'react';
import { useLanguage } from '../../../../../../../hooks/Language';
import Input from '../../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../../components/Forms/SelectSimple';
import calculateFinalPrice from '../../../Functions/calculateFinalPrice';
import generateInstallments from '../../../Functions/generateInstallments';

interface IProps {

    cart: Array<Record<string, any>>;
    couponInfo?: Record<string, any>;
    changeInstallment: Function;
    changeCardInfo: Function;
}

const CreditCardForm: React.FC<IProps> = ({ cart = [], couponInfo = {}, changeCardInfo }) => {

    const { translate } = useLanguage();
    const [type, setType] = useState('cpf');

    return <>
        <h2 style={{ margin: ' 0px', fontSize: '14px', marginBottom: '10px', width: '100%', color: '#333', borderBottom: '3px solid #162743', padding: '5px 0px', textAlign: 'left' }}>{translate('Dados do cartão de crédito')}</h2>


        <Input setCallback={(value) => changeCardInfo('card_number', value)} name="card_number" placeholder={translate('Número do Cartão')} />
        <Input setCallback={(value) => changeCardInfo('card_holder_name', value)}
            name="card_holder_name"
            placeholder={translate('Nome no Cartão')}
        />
        <MaskedInput setCallback={(value) => changeCardInfo('card_expiration_date', value)}
            mask="99/99"
            name="card_expiration_date"
            placeholder={translate('Validade')}
        />
        <Input setCallback={(value) => changeCardInfo('card_cvv', value)} name="card_cvv" placeholder="CVV" />


    </>;
};
export default CreditCardForm;
