/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useCallback, useEffect } from 'react';

import ReactSelect from 'react-select';
import { useField, FormHandles } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles';
import { Error } from '../styles';
import { useLanguage } from '../../../hooks/Language';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface Props {
  name: string;
  label: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  options: Array<IOptions>;
  isMulti?: boolean;
  style?: Record<string, any>;
  index?: number;
  mandatory?: boolean;
}


const SelectSimple: React.FC<Props> = ({
  name,
  label,
  options,
  formRef,
  isMulti = false,
  style = {},
  index = 100,
  mandatory = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCallback = (value) => { },
  ...rest
}) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState(defaultValue || null);
  const { translate } = useLanguage();
  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  useEffect(() => {
    setValue(
      defaultValue &&
      options?.find(
        option => option.value.toString() === defaultValue.toString(),
      ),
    );
  }, [defaultValue, options]);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
    setIsFilled(!!value);
  }, [value]);

  //* * Usefull function to call external functions */
  useEffect(() => {
    if (value && value.value) {
      setCallback(value.value, formRef);
    }
  }, [value, setCallback]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        if (!ref.state.value) {
          return '';
        }

        if (!ref.state.value.value) {
          return ref.state.value;
        }
        return ref.state.value.value;
      },

      setValue: (ref, valueUF) => {
        const validValue = options?.find(option => {
          return option.value === valueUF;
        });

        if (validValue) {
          ref.state.value = validValue;
          setValue(validValue);
        }
      },
    });
  }, [fieldName, registerField, isMulti]);

  const activeCallback = () => {
    if (value && value.value) {
      setCallback(value.value);
    }

  }

  return (
    <Container className="inputDiv" style={{ ...style, zIndex: 20000 - index }} isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <span style={{ color: '#333', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{translate(label)}{mandatory ? <p style={{ display: 'inline-block', color: 'rgb(150,0,0)', fontSize: '11px', marginLeft: '15px', textAlign: 'right' }}>*{translate('Obrigatório')}</p> : <></>}</span>
      <ReactSelect

        options={options}
        onChange={selectedValue => {
          setValue(selectedValue);

          activeCallback();
        }}

        value={value}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default SelectSimple;
