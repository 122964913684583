import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiLayout, FiSend } from 'react-icons/fi';
import { useModal } from 'src/hooks/Modal';
import PageCreator from 'src/pages/DashboardPages/features/PageCreator';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();

  const { addModal } = useModal();


  const OpenWidget = async (id: string): Promise<void> => {

    addModal({ title: '', theme: 'white-full-modal', key: "page-creator", closeButton: false, content: <PageCreator lineData={lineData} /> })
  };

  const moduleUpdate = (id: string): JSX.Element => (
    <button className="lineIcon" title="Editar Página" type="button" onClick={() => OpenWidget(id)}>
      <FiLayout />
    </button>
  );

  return moduleUpdate(lineData._id);
}
