/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useCallback, useEffect } from 'react';

import ReactSelect from 'react-select';
import { useField, FormHandles } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles';
import { Error } from '../styles';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface Props {
  name: string;
  label: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  from: number,
  to: number,
  isMulti?: boolean,
  index?: number;
}


const SelectSimpleSequence: React.FC<Props> = ({
  name,
  label,
  from, to,
  formRef,
  isMulti = false,
  index = 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCallback = (value) => { },
  ...rest
}) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [options, setOptions] = useState<Array<IOptions>>([]);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState(defaultValue || null);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  useEffect(() => {
    setValue(
      defaultValue &&
      options?.find(
        option => option.value.toString() === defaultValue.toString(),
      ),
    );
  }, [defaultValue, options]);


  useEffect(() => {

    const optionsValues: Array<IOptions> = [];
    let x = parseInt(from.toString(), 10);
    let y = parseInt(to.toString(), 10);
    if (x > 0 && y > 0) {

      while (x <= y) {
        optionsValues.push({ value: x, text: x?.toString(), label: x?.toString() });
        x = x + 1;
      }
    }

    setOptions(optionsValues)

  }, [from, to])


  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
    setIsFilled(!!value);
  }, [value]);

  //* * Usefull function to call external functions */
  useEffect(() => {
    if (value && value.value) {
      setCallback(value.value, formRef);
    }
  }, [value, setCallback]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        if (!ref.state.value) {
          return '';
        }

        if (!ref.state.value.value) {
          return ref.state.value;
        }
        return ref.state.value.value;
      },

      setValue: (ref, valueUF) => {
        const validValue = options?.find(option => {
          return option.value === valueUF;
        });

        if (validValue) {
          ref.state.value = validValue;
          setValue(validValue);
        }
      },
    });
  }, [fieldName, registerField, isMulti]);

  const activeCallback = () => {
    if (value && value.value) {
      setCallback(value.value);
    }

  }

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocus} style={{ zIndex: 21000 - index }} >
      <span>{label}</span>
      <ReactSelect
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        options={options}
        onChange={selectedValue => {
          setValue(selectedValue);

          activeCallback();
        }}
        value={value}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default SelectSimpleSequence;
