import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";
import styled from "styled-components";

export const BallListContainer = styled(ContainerLineUI)`

 div{
    border-radius: 50%;
    background: #eee;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
 }

img{
    
   

    padding: 15px;
    max-width: 130px;
}

p{
color: #606060;
font-size: 16px;
}

h2{
    color: #606060;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
}

`;