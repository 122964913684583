import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import { FaPlus } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import IframePlayer from '../../../../../../components/IframePlayer';

import { ContainerLineUI } from '../../../../../../components/UIComponents/ContainerLine/styles';
import ParagraphDangerous from '../../../../../../components/UIComponents/ParagraphDangerous';
import { TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';

import { urlWebsite } from '../../../../../../config';

interface IList {

    title?: string;
    text?: string;
    video: string;

}

interface IProps {
    list: Array<IList>,
    title?: string;
    background?: string;
    color: string;
    inactiveColor: string;
}
const RenderIcons: React.FC<IProps> = ({ title, list }) => {

    return <>
        <div className='full-width column' style={{ padding: '50px 15px' }}>

        </div>

    </>

}
const RenderVideoList: React.FC<IProps> = ({ list, title, background, color, inactiveColor }) => {

    const [activeIndex, setActiveIndex] = useState(0);




    return <ContainerLineUI className='full-width column' style={{ gap: '5px', width: '100%' }}>

        <IframePlayer id={list[activeIndex]?.video} />


        <div className='pad row full-width ' style={{ justifyContent: 'center', transition: '0.5s all', gap: '5px', borderRadius: '5px', alignItems: 'flex-start' }}>


            {list.map((item, index) => {
                return <div onClick={() => { setActiveIndex(index) }} style={{ background: activeIndex === index ? background : 'none', color: activeIndex === index ? color : inactiveColor, minWidth: '30px', minHeight: '30px', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px' }}>
                    {index + 1}
                </div>


            })}
        </div>

    </ContainerLineUI>


}

export default RenderVideoList;