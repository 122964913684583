import React from 'react';
import Row from '../Row';
import { ISection } from '../../hooks/PageCreatorContext';
import { FaPlus, FaArrowUp, FaArrowDown, FaPaintBrush } from 'react-icons/fa';
import { BgImageStyle, SectionStyle } from '../../styles';
import { urlWebsite } from 'src/config';

interface ISectionProps {
    section: ISection;
    containerIndex: number;
    sectionIndex: number;
    context: Record<string, any>;
    editable: boolean;
    language: string;
}

const Section: React.FC<ISectionProps> = ({
    section,
    containerIndex,
    sectionIndex,
    editable = true,
    context = {},
    language,
}) => {
    const { addRow,
        addContent,
        moveSectionUp,
        moveSectionDown,
        moveRowUp,
        moveRowDown,
        setCurrentView,
        setSelectedIndices } = context;
    const style = section.style || {};



    return (
        <SectionStyle className='section' computer={style?.computer || {}} mobile={style?.mobile || {}}>
            {style?.computer?.['background-image'] ? <BgImageStyle className='computerOnly' background={`${urlWebsite}/${style?.computer?.['background-image']}`} backgroundPosition={style?.computer?.['background-position']} backgroundOpacity={style?.mobile?.['background-opacity'] ? style?.mobile?.['background-opacity'] : style?.computer?.['background-opacity']} /> : <></>}
            {style?.mobile?.['background-image'] || style?.computer?.['background-image'] ? <BgImageStyle className='mobileOnly' background={`${urlWebsite}/${style?.mobile?.['background-image'] ? `${style?.mobile?.['background-image']}` : `${style?.computer?.['background-image']}`}`} backgroundPosition={style?.computer?.['background-position']} backgroundOpacity={style?.mobile?.['background-opacity'] ? style?.mobile?.['background-opacity'] : style?.computer?.['background-opacity']} /> : <></>}

            {section.rows.map((row, rowIndex) => (
                <Row
                    key={rowIndex}
                    row={row}
                    containerIndex={containerIndex}
                    sectionIndex={sectionIndex}
                    rowIndex={rowIndex}
                    editable={editable}
                    context={context}
                    language={language}
                />
            ))}
        </SectionStyle>

    );
};

export default Section;