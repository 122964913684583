import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaCheck, FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({
  lineData
}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>()
  const key = uuid();

  const applyToCredentiated = async (
  ): Promise<void> => {
    try {

      const response = await api.post(`/apply-certificate-to-credentiated/${lineData._id}`);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      addToast({ type: 'success', title: 'Salvo com sucesso' });
      await reloadSearchAll();

    } catch (err) {


      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleCreate = (): void => {
    const content = (<> <button onClick={() => { applyToCredentiated() }} className='defaultButton'>Confirmar</button></>
    );
    if (content) {
      const keyModal = addModal({ title: 'Adicionar certificados aos credenciados', content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    lineData._id ? <button className="lineIcon" type="button" onClick={handleCreate}>
      <FaCheck />
    </button> : <></>
  );

  return moduleCreate();
}
