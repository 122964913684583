import styled from "styled-components";

export const ContentSelectionContainer = styled.div`

 width: 100% ;
 max-width: 1200px;
 display: flex ;
 align-items: flex-start;
 justify-content: center;
 gap: 30px;
 flex-direction: column;
 
margin: 50px auto;

 

.classroomcounter{
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0,0,0,0.3) ;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    p{
        font-size: 12px;
        color: #fff;
    }

    svg{
        font-size: 10px;
        color: #fff;
    }
}


@media (max-width: 750px) {
    gap: 15px;
   
  }
>div{
    background: none !important;
    max-width: 200px;
    
   

    @media (max-width: 750px) {
        max-width: 150px;
   
  }
}



`;
