import styled, { css } from 'styled-components';
import theme from '../../styles/ThemeComponents';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
}

export const ContainerRef = styled.div<ContainerProps>`
  display: flex;
  justify-content: stretch;
  align-items: center;

  background: ${theme.input.background};
  color: ${theme.input.color};
  border-radius: 10px;
  border: ${theme.input.border};
  padding: 0px 16px;
  position: relative;
  min-height: 60px;

  div.react-datepicker-popper{
    z-index: 1000;
  }

  > span {

    transition: 0.2s all;
    position: absolute;
    left: 16px; top: 15px;
    font-size: 0px;
  }


  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;

      > span {
        display: inline-block;
        position: absolute;
      top: 3px;
        left: 16px;
        color: #333;
        font-size: 10px;
      }
    `}

    > span {
        display: inline-block;
        position: absolute;
      top: 3px;
        left: 16px;
        color: #333;
        font-size: 10px;
      }

  ${props =>
    props.isFocused &&
    css`
      color: #162743;
      border-color: #162743;

      > span {
        display: inline-block;
        position: absolute;
      top: 3px;
        left: 16px;
        color: #333;
        font-size: 10px;
      }
    `}

    ${props =>
    props.isFilled &&
    css`
        color: #162743;
        > span {
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 16px;
          color: #fff;
          font-size: 10px;
        }
      `}



  & + div {
    margin-top: 8px;
  }

  svg {
    font-size: 16px;
    margin-right: 16px;
  }



  input {
    width: 100%;
    border: 0;
    color: ${theme.input.color};
    padding: 16px;
    background: transparent;

    &::placeholder {
      color: #777;
    }
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;
  height: 20px;

  span {
    background: #c53030;
    color: #fff !important;

    &::before {
      border-color: #c53030 transparent;
   
    }
  }
  svg {
    margin-right: 0;
    color: #c53030;
    font-size: 20px;
  }
`;
