import React from 'react';
import Input from "../../../../components/Forms/Input";
import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useLanguage } from '../../../../hooks/Language';
import ArrayInputIncluderJoin from '../../../DashboardPages/core/components/Forms/ArrayInputIncluderJoin';
import SelectSimpleSequence from '../../../DashboardPages/core/components/Forms/SelectSimpleSequence';
import TextareaInput from '../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadCurriculumContent from '../../../DashboardPages/core/components/Forms/UploadCurriculumContent';

interface IFields {
    field: string;
    limit: number;
    label: string;
    title: string;
    description: string;
    type: string;
    options?: Array<string>;
    mask?: string;
    from: number;
    to: number;
    apply_coupon?: string;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string;
}

interface IProps {
    fields: Array<IFields>;
    setCallBack?: Function;
    applyCoupon?: Function;

}

const CompleteSaleFields: React.FC<IProps> = ({ fields, setCallBack = () => { }, applyCoupon = (value) => { } }) => {
    const { translate } = useLanguage();


    const readOptions = (texts) => {
        const options: Array<IOptions> = [];

        if (texts && texts.length > 0) {

            texts.map(option => {
                options.push({ label: option, value: option });
            })



        }

        return options;

    }

    const readFields = (field: IFields, index: number) => {

        const valid = {
            select: <><p style={{ margin: '10px auto' }}>{translate(field.description)}</p><SelectSimple setCallback={(value: string) => field?.apply_coupon ? applyCoupon(value === 'Sim' ? field.apply_coupon : 'remove') : false} index={index} name={field.field} options={readOptions(field.options)} label={field.title} /></>,
            input: <><p style={{ margin: '10px auto' }}>{translate(field.description)}</p><Input name={field.field} placeholder={field.title} /></>,
            textarea: <TextareaInput name={field.field} placeholder={field.title} />,
            arrayInputIncluder: <><p>{field.title}</p><p>{field.description}</p><ArrayInputIncluderJoin mask={field?.mask || ''} label={field.label} limit={field.limit} name={field.field} placeholder={field.title} /></>,
            image: <><p>{field.title}</p><p>{field.description}</p><UploadCurriculumContent label={field.label} name={field.field} /></>,
            selectSequence: <SelectSimpleSequence from={field?.from} to={field.to} index={index} name={field.field} label={field.title} />,

        };

        return valid[field?.type] ? valid[field?.type] : <></>;




    }

    return <>{fields.length > 0 ? <aside style={{ width: '100%', zIndex: 1000, position: 'relative', margin: '10px auto', background: '#ddd', height: '2px' }} /> : <></>}
        {
            fields.map((f, index) => {
                return readFields(f, index);
            })
        }
        {fields.length > 0 ? <aside style={{ width: '100%', margin: '10px auto', background: '#ddd', height: '2px' }} /> : <></>}
    </>;

}


export default CompleteSaleFields;