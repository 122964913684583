/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { CourseContent } from './styles';
import Button from '../../../../components/Button';
import { BuyContext } from '../../index';
import api from '../../../../services/api';
import Loader from '../../../../components/Loader';

interface ProductProps {
  id: string;
  title: string;
  price: number;
  formattedPrice?: string;
  max_selectable_courses: number;
  type: 'free' | 'paid';
  benefits: string[];
  quantity: number;
}

interface CoursesProps {
  id: string;
  title: string;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

const CoursesContainer: React.FC<ComponentProps> = ({
  step,
  setStep,
  buyContext,
  setBuyContext,
}) => {
  const max = buyContext.maxCourses;
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<CoursesProps[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);

  useEffect(() => {
    const loadInfo = async (): Promise<void> => {
      const responseCourses = await api.get('/courses');

      if (!responseCourses) {
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
      }

      setCourses(responseCourses.data.rows);
      setLoading(false);
    };

    loadInfo();
  }, [addToast]);

  const handleChooseCourse = useCallback(
    id => {
      const index = selectedCourses.findIndex(productID => productID === id);

      if (index >= 0) {
        setSelectedCourses(state => state.filter(courseID => courseID !== id));

        return;
      }

      const { length } = selectedCourses;

      if (length >= max) {
        addToast({
          title: 'Limite Máximo',
          description: 'Você atingiu o limite de cursos para este plano',
          type: 'info',
        });
        return;
      }
      addToast({
        title: 'Selecionado',

        type: 'success',
      });
      setSelectedCourses([...selectedCourses, id]);
    },
    [addToast, buyContext, selectedCourses],
  );

  const handleConfirmCourses = useCallback((): void => {
    const { length } = selectedCourses;

    if (max !== length) {
      addToast({
        title: 'Escolha os seus cursos.',
        description: `Ainda falta escolher ${max - length}`,
        type: 'info',
      });
      return;
    }
    setBuyContext({ ...buyContext, courses: selectedCourses });
    setStep([2, 3]);
  }, [addToast, selectedCourses, buyContext, setStep, setBuyContext, max]);

  return (
    <>
      {loading ? (
        <Loader message="Carregando" />
      ) : (
        <AnimatedDiv visible={step[1] === 2}>
          <CourseContent>
            <h1>Escolher Cursos</h1>
            <aside>
              {max > 0 &&
                courses?.map((course: CoursesProps) => {
                  const selected = selectedCourses.find(
                    courseID => courseID === course.id,
                  );

                  return (
                    <button
                      className="checkboxButton"
                      type="button"
                      onClick={() => handleChooseCourse(course.id)}
                    >
                      <input
                        value={course.id}
                        type="checkbox"
                        checked={!!selected}
                      />

                      <span>{course.title}</span>
                    </button>
                  );
                })}
            </aside>

            <Button type="button" onClick={handleConfirmCourses}>
              Continuar
            </Button>

            <Button type="button" onClick={() => setStep([1, 1])}>
              Voltar
            </Button>
          </CourseContent>
        </AnimatedDiv>
      )}
    </>
  );
};

export default CoursesContainer;
