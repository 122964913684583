import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, logo, urlWebsite } from '../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';
import Logo from '../../components/Logo';
import { useConfiguration } from '../../hooks/Configuration';
import MaskedInput from '../DashboardPages/core/components/Forms/MaskedInput';
import { useLoading } from '../../hooks/LoadingHook';



interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
}



const SignIn: React.FC<IParams> = ({ redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(true);
  const { signIn, signInDocumentNumber } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { addLoading, removeLoading } = useLoading();
  const { translate } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({});
  const [manutencao, setManutencao] = useState(false);
  const [otherCountry, setOtherCountry] = useState(false);


  const { configuration, setConfiguration } = useConfiguration();
  const { projectHash, eventHash } = useParams<Record<string, any>>();

  useEffect(() => {
    if (configuration?.have_password === 'no') {
      setWithPassword(false);
    }

  }, [configuration])

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      addLoading({ title: 'Carregando', key: 'loading' })

      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
            email: Yup.string()
              .required(`${translate(configuration?.sign_in_type === 'cpf' ? 'CPF' : 'E-mail')} ${translate('obrigatório')}`),
            password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
          }
          : {
            email: Yup.string()
              .required(`${translate(configuration?.sign_in_type === 'cpf' ? 'CPF' : 'E-mail')} ${translate('obrigatório')}`)

          };

        const schema = Yup.object().shape(shape);
        const { email: email_trim } = data;
        data.email = email_trim.toLowerCase().trim();

        if (configuration?.sign_in_type === 'cpf') {
          data.email = data.email.toString().replace(/[^0-9/]/g, '');
        }

        await schema.validate(data, { abortEarly: false });

        let { email, name, password } = data;

        email = email?.trim().toLowerCase();
        if (configuration?.sign_in_type === 'cpf') {
          await signInDocumentNumber({ email, password, name, product_id: configuration?.current_event_id_response?.product_id, project_id: projectHash, event_id: eventHash ? eventHash : '' });
        }
        else {
          await signIn({ email, password, name, product_id: configuration?.current_event_id_response?.product_id, project_id: projectHash, event_id: eventHash ? eventHash : '' });

        }

        if (configuration?.current_event_id_response?.presence_type === 'Presencial') {
          window.location.href = `/app/${projectHash}/tickets-dashboard/`;
        }
        else {
          window.location.href = `/app/${projectHash}/dashboard/`;
        }

        //  window.location.href = redirect !== '/' ? `/dashboard/${redirect}` : (projectHash ? `/app/${projectHash}/dashboard/` : '/projects');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          removeLoading('loading');

          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        removeLoading('loading');

        const { status } = err?.response;

        if (status >= 500) {

          setErrorMessage({ title: 'Um momento...', description: 'Estamos com alta demanda de pedidos de acesso. <br/><br/>Por favor refaça seu login em 1 minuto para acessar.' })
          setTimeout(() => {
            setErrorMessage({});
          }, 60000)

        }
        else {

          setErrorMessage({ title: 'Usuário não identificado', description: 'Verifique se há alguma divergência em seus dados.' })
          setTimeout(() => {
            setErrorMessage({});
          }, 5000)

        }




      }
    },
    [signIn, addToast, history, withPassword, configuration],
  );

  return (<>

    <Container>
      <Content>
        <AnimatedDiv2 className='shadowBox'>

          {manutencao ? <div className="onlineMessage ">

            <h2 className="defaultMarked"><div />{translate('Estamos em manutenção')}</h2>
            <p style={{ color: '#333', textAlign: 'center', marginTop: '20px' }}>
              {translate(
                'Voltamos em breve!',
              )}
            </p><br />
          </div> : <>

            <Link style={{ color: '#555', fontWeight: 'bold', cursor: 'pointer', width: '100%', marginBottom: '20px' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/inscricao` : `/app/${projectHash}/inscricao`}>      <button className='greenButton2 buttonColored' style={{ width: '100%', maxWidth: '340px', minHeight: '50px', padding: '10px' }}


            >
              {translate('Novo Cadastro')}
            </button></Link>

            <p style={{ width: '100%', background: '#333', height: '2px', position: 'relative', margin: '20px 0px' }}>
              <p style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%) translateY(-20px)', fontSize: '12px', background: '#333', color: '#fff', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {translate('OU')}
              </p>
            </p>


            <Form className="form" ref={formRef} onSubmit={handleSubmit}>

              {/*  <aside style={{ width: '100%', maxWidth: '350px', margin: '20px auto' }}>
              {projectHash && configuration.logo ? <img src={`${urlWebsite}/${configuration.logo}`} style={{ width: '250px', margin: '10px auto' }} /> : <Logo />}
              </aside> */}
              <h2 style={{ marginBottom: '20px' }}>{translate('Faça seu login')}</h2>

              {configuration?.sign_in_type === 'cpf' ? otherCountry ? <Input name="email" placeholder={translate('Documento de identificação')} /> : <MaskedInput mask="999.999.999-99" name="email" placeholder={translate('CPF')} /> : <Input name="email" placeholder={translate('E-mail')} />}

              {configuration?.sign_in_type === 'cpf' ? !otherCountry ? <p onClick={() => setOtherCountry(true)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou de outra nacionalidade</p> : <p onClick={() => setOtherCountry(false)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou brasileiro</p> : <></>}


              {withPassword && <Input

                name="password"
                type="password"
                placeholder={translate('Senha')}
              />}

              {errorMessage && errorMessage.title && <ErrorContainer>
                <h2>{errorMessage.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: errorMessage.description }} />
              </ErrorContainer>}
              <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <button className='defaultButton' style={{ margin: '0px auto', marginTop: '40px' }} type="submit">{translate('Entrar')}</button>
              </aside>
              {withPassword && <>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}> <p style={{ margin: '10px 0px', color: '#333' }}>{translate('Caso tenha dificuldade em logar, solicite uma nova senha.')}</p>
                <Link style={{ color: '#333' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/recuperar_senha` : projectHash ? `/app/${projectHash}/recuperar_senha` : "/recuperar_senha"}  ><Button>{translate('Esqueci minha senha')}</Button>

                </Link></aside></>}

              <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><Whatsapp
                number={supportNumber}
                text={`${translate(`Olá, estou no ${authTitle}`)}`}
                message={translate('Suporte Técnico')}
              />

              </aside>
            </Form></>}
        </AnimatedDiv2>
      </Content>
    </Container></>
  );
};
export default SignIn;
