import styled, { keyframes } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;


export const HeaderButton = styled.div`
display: flex;

  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  padding: 10px 10px;
  width: 100%;
  flex-wrap: wrap;

  >button{
    padding: 15px;
    font-size:12px;
    border:0px solid #fff;
    background: #fff;
    color: #27213D;
    margin: 5px 10px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    border-radius: 15px;
    transition: 0.5s all;
    font-weight: bold;

    &:hover{
      color:#fff;
      background:  #27213D;
    }
  }

  >button.active {
    color:#fff;
      background:  #27213D;
  }

`;



export const HeaderButton2 = styled.div`
display: flex;

  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  padding: 10px 10px;
  width: 100%;
  flex-wrap: wrap;

  >button{
    padding: 15px;
    font-size:14px;
    border:0px solid #fff;
    background: #fff;
    border: 2px solid rgb(0,100,0);
    color: rgb(0,100,0);
    margin: 5px 10px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    border-radius: 15px;
    transition: 0.5s all;
    font-weight: bold;
    cursor: default;

    svg{
      width: 20px ;
    }

    &:hover{
      
    }
  }

  >button.active {
    color:#fff;
      background:  #27213D;
  }

`;


export const Container = styled.div`
  min-height: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 0px;
  background: #ddd;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  background: none;
  color: #333;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    max-width: 400px;
    form {
      display: flex;

      > input {
        border: 0;
        padding: 20px;
        background: #eee;
        color: #333;
      }
      > button {
        border: 0;
        padding: 20px;
        background: rgb(0, 100, 150);
        color: #fff;
      }
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 10vh;
  display: flex;

  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: auto;
    background: white;
    margin-top: 10px;

    tr {
      td,
      th {
        border: 1px solid #ddd;
        text-align: left;
        color: #333;
        padding: 5px;
      }
    }

    tr + tr {
      border-top: 1px solid #999;
    }
  }
`;
