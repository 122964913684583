import React from 'react';
import lineModules from '../../../../core/components/lineModules';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { FaPaintBrush } from 'react-icons/fa';

const UpdateEventTheme: React.FC = () => {

    const { configuration } = useConfiguration();

    const formSchemaUpdate = {
        theme: {
            model: 'jsonList',
            type: 'text',
            name: 'theme',
            label: 'Personalização',
            list: [
                { label: 'Fundo da Programação', name: 'program_background' },
                { label: 'Cor do Título da Programação', name: 'program_title_color' },
                { label: 'Fundo dos palestrantes', name: 'speaker_background' },
                { label: 'Cor do Título dos Palestrantes', name: 'speaker_title_color' },
                { label: 'Cor do Texto dos Palestrantes', name: 'speaker_text_color' },
                { label: 'Fundo dos botões', name: 'button_background' },
                { label: 'Cor dos botões', name: 'button_color' },
                { label: 'Cor dos Títulos', name: 'title_color' },
                { label: 'Cor dos Textos', name: 'text_color' },
                { label: 'Menu: Background', name: 'menu_background' },
                { label: 'Menu: Cor dos Textos', name: 'menu_color' },
            ],
        },
        landing_page: {
            model: 'jsonInputList',
            type: 'text',
            name: 'landing_page',
            label: 'Configuração da Landing Page',
            show: 'title',
            list: [
                { label: 'Referência', name: 'reference', type: 'input' },
                { label: 'Hash', name: 'hash', type: 'input' },


            ]
        },

        product_header_image: {
            model: 'uploadImage',
            type: 'upload',
            name: 'product_header_image',
            label: 'Imagem no cabeçalho de Vendas',
        },


        product_header: {
            model: 'richText',
            type: 'text',
            name: 'product_header',
            label: 'Cabeçalho de Vendas',

        },
        product_footer: {
            model: 'richText',
            type: 'text',
            name: 'product_footer',
            label: 'Rodapé de Vendas',
            show: 'title',

        }
    };

    const endpoint = '/events';
    return lineModules.updateUnique({
        lineData: { _id: configuration?.current_event_id_response?._id?.toString() },
        title: 'Personalizar Landing Page',
        formSchema: formSchemaUpdate,
        validationSchema: {},
        endpoint,
        Icon: FaPaintBrush
    })

}

export default UpdateEventTheme;