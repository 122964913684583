import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { hash } from "src/config";
import { useAuth } from "src/hooks/Auth";
import { useConfiguration } from "src/hooks/Configuration";
import { useLanguage } from "src/hooks/Language";
import api from "src/services/api";
import { loadFromJson } from "src/utils/loadFromJson";

/* This hook handle course state */
export default function useCourse() {

    const [speakers, setSpeakers] = useState<Record<string, any>>([]);
    const { videoIdItem } = useParams<Record<string, any>>();
    const [search, setSearch] = useState<string>('');
    const { configuration } = useConfiguration();
    const { language } = useLanguage();
    const [ready, setReady] = useState(false);
    const [notPaid, setNotPaid] = useState(false);
    const [currentView, setCurrentView] = useState<'list' | 'course' | 'content'>('list');
    const [courseList, setCourseList] = useState<Record<string, any>>([]);
    const [contentList, setContentList] = useState<Record<string, any>>([]);
    const [currentCourse, setCurrentCourse] = useState('');
    const [currentContent, setCurrentContent] = useState('');

    const [contentFilter, setContentFilter] = useState<Array<Record<string, any>>>([]);
    const history = useHistory();
    const filtered = search ? contentFilter?.filter(i => i?.search.indexOf(search?.toLowerCase()?.trim()) >= 0) : contentFilter;
    const { user } = useAuth();
    const addSearch = (text: string) => {

        setSearch(text);
    }

    useEffect(() => {

        if (courseList) {

            const items: Array<Record<string, any>> = [];

            courseList?.map(course => {
                course?.lessons?.map(lesson => {

                    let speakersInfo = ``;

                    if (lesson?.speakers) {
                        lesson?.speakers?.map(speakerID => {
                            const speakerData = speakers?.find(i => i?._id === speakerID);
                            if (speakerData) {
                                speakersInfo = `${speakersInfo}-${speakerData?.name}`
                            }
                        })


                    }

                    const lessonData = {
                        ...lesson,
                        course: {
                            _id: course?._id,
                            title: course?.title,
                            title_en: course?.title_en,
                            title_es: course?.title_es,
                        },

                        search: `${lesson?.title} - ${lesson?.title_en} ${lesson?.title_es} ${course?.title} - ${course?.title_en} ${course?.title_es} ${speakersInfo}`?.toLowerCase()

                    }



                    items?.push(lessonData);


                })
            })

            setContentFilter(items);
        }



    }, [
        speakers, courseList
    ])



    const course = courseList?.find(item => item?._id === currentCourse) || {};

    const content = course?.lessons?.find(item => item?._id === currentContent) || course?.lessons?.find(i => i?.visible === '2') || {};

    const sidebarContent = course?.lessons?.reduce((prev, item) => {

        if (item?.visible === '2') {
            prev?.[0].content.push(item)
        }

        return prev;
    }, [{ title: course?.[`title_${language}`] ? course?.[`title_${language}`] : course?.title, content: [] }]) || [];


    const loadCourses = async (project_id) => {

        let data: Array<Record<string, any>> = [];
        const coursesList = await loadFromJson({ hash: hash, project_id: project_id, table: 'Course' });
        if (coursesList) {
            data = coursesList;
        }
        else {
            const response = await api.get(`/my-courses/${project_id}`)
            data = response?.data;
        }

        const dataList: Record<string, any>[] = [];

        data?.map(i => {
            if (i?.paid === 'paid') {

                if (user?.profile !== 2) {
                    dataList?.push(i);
                }

            }
            else {
                dataList?.push(i);
            }
        })

        setCourseList(dataList);
        setReady(true);
    }

    const loadSpeakers = async (project_id) => {

        let data: Array<Record<string, any>> = [];
        const coursesList = await loadFromJson({ hash: hash, project_id: project_id, table: 'Speaker' });
        if (coursesList) {
            data = coursesList;
        }
        else {
            const response = await api.get(`/list-speakers/${project_id}`)
            data = response?.data;
        }

        setSpeakers(data);

    }

    const checkPayment = async () => {

        if (user?.profile === 2) {
            const response = await api.get(`/my-qrcodes`);

            const find = response?.data?.rows?.find(i => i?.payment_status_id === 'paid' || i?.price < 1)

            if (!find) {
                setNotPaid(true);
                return;
            }
        }

        loadCourses(configuration?.url);
        loadSpeakers(configuration?.url)

    }

    useEffect(() => {

        if (configuration?.url) {
            checkPayment()
        }




    }, [configuration?.url])

    useEffect(() => {

        let findCourseID = '';
        let findLessonID = '';



        if (videoIdItem && courseList?.length > 0) {


            courseList?.map(item => {

                item?.lessons?.map(lesson => {

                    if (lesson?._id?.toString() === videoIdItem) {

                        findCourseID = item?._id?.toString();
                        findLessonID = lesson?._id?.toString();

                    }

                })

            })

            if (findCourseID && findLessonID) {
                setCurrentCourse(findCourseID);
                selectContent(findLessonID);
            }


        }



    }, [videoIdItem, courseList])




    const searchContent = () => { }

    const loadSidebar = () => { }
    const loadContent = () => { }

    const backToStart = () => {


        history.push(`/app/${configuration?.url}/dashboard`);
        setCurrentContent('');
        setCurrentCourse('');
        setCurrentView('list');


    }

    const selectCourse = (id) => {

        const courseInfo = courseList?.find(i => i?._id === id);

        if (courseInfo?.lessons?.[0]) {
            history.push(`/app/${configuration?.url}/dashboard/${courseInfo?.lessons?.[0]?._id}`);
        }

        setCurrentCourse(id);
        setCurrentView('course');

    }
    const selectContent = (id) => {
        history.push(`/app/${configuration?.url}/dashboard/${id}`);
        setCurrentContent(id);
        setCurrentView('course');
    }


    return { addSearch, search, filtered, speakers, ready, notPaid, sidebarContent, currentView, course, content, searchContent, loadContent, loadSidebar, loadCourses, backToStart, selectCourse, selectContent, courseList, contentList }


}