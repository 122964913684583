import React from 'react';
import { IContainer, usePageCreatorContext } from '../../hooks/PageCreatorContext';
import { ContainerListStyle } from '../../styles';
import Container from '../Container';

interface IContainerListProps {
    page: Record<string, any>;
    containers: Array<IContainer>;
    editable: boolean;
    context: Record<string, any>;
    language: string;
}

const ContainerList: React.FC<IContainerListProps> = ({
    page,
    containers,
    editable = true,
    context,
    language = 'ptBr'
}) => {




    return (
        <ContainerListStyle computer={page?.style?.computer || {}} mobile={page?.style?.mobile || {}} >
            {containers.map((container, index) => (

                <Container
                    containerIndex={index}
                    container={container}
                    context={context}
                    editable={editable}
                    language={language}
                />

            ))}
        </ContainerListStyle >
    );
};

export default ContainerList;