/* eslint-disable @typescript-eslint/camelcase */
import React, { Dispatch, useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../../../components/Forms/Input';
import { AnimatedDiv } from '../../styles';
import { Content } from './styles';

import getValidationErrors from '../../../../utils/getValidationErrors';

import SelectSimple from '../../../../components/Forms/SelectSimple';
import MaskedInput from '../../../../components/Forms/MaskedInput';

import Button from '../../../../components/Button';
import { BuyContext } from '../../index';
import getCep from '../../../../services/cep';

interface IComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface IAddressProps {
  zipcode: string;
  address: string;
  address_number?: string;
  neighborhood: string;
  city: string;
  state: string;
}

interface IUserProps {
  document_type: string;
  document_number: string;
  birth_date: string;

}

const AddressContainer: React.FC<IComponentProps> = ({
  buyContext,
  setBuyContext,
  setStep,
  step,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [documentType, setDocumentType] = useState('cpf');

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };

  const handleUserData = useCallback(
    async (data: IUserProps & IAddressProps) => {
      const validateData = { ...data };
   

      validateData.zipcode = data.zipcode.toString().replace(/[^0-9]/g, '');
      

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
     
          zipcode: Yup.string()
            .min(8, 'Preecha a CEP')
            .max(8, 'Preecha a CEP')
            .required('CEP obrigatório'),
          address: Yup.string().required('Endereço obrigatório'),
          address_number: Yup.string().required('Endereço obrigatório'),
          neighborhood: Yup.string().required('Bairro obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          state: Yup.string()
            .max(2, 'No máximo 2 caracteres')
            .required('Estado obrigatório'),
        });

        await schema.validate(validateData, { abortEarly: false });

        const {
   
          zipcode,
          address,
          address_number,
          neighborhood,
          city,
          state,
        } = data;

        const adjustBuyContext = {
          ...buyContext,

          address: {
            zipcode,
            address,
            address_number,
            neighborhood,
            city,
            state,
          },
        };

        setBuyContext(adjustBuyContext);
        setStep([4, 5]);
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [buyContext, setBuyContext, setStep],
  );

  return (
    <AnimatedDiv visible={step[1] === 4}>
      <Content>
        <aside>
          <Form
            ref={formRef}
            initialData={{
              document_type: documentType || 'cpf',
            }}
            onSubmit={handleUserData}
          >
            <h1>Endereço</h1>
            
            <MaskedInput
              setCallback={getCepFromApi}
              mask="99999999"
              name="zipcode"
              placeholder="CEP"
            />
            <Input name="address" placeholder="Endereço" />
            <Input name="address_number" placeholder="Número / Apartamento" />
            <Input name="neighborhood" placeholder="Bairro" />
            <Input name="city" placeholder="Cidade" />
            <MaskedInput mask="aa" name="state" placeholder="Estado" />
            <Button type="submit">
              Continuar
              <FiArrowRight />
            </Button>
            <Button
              type="button"
              onClick={() => {
                setStep([1, 3]);
              }}
            >
              Voltar
              <FiArrowLeft />
            </Button>
          </Form>
        </aside>
      </Content>
    </AnimatedDiv>
  );
};

export default AddressContainer;
