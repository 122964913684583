import React, { useEffect, useState } from 'react';
import { useConfiguration } from '../../hooks/Configuration';

import { scrollToID } from '../../utils/scrollTo';

import { FaChevronDown } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import MenuItem from './modules/MenuItem';

export function simpleScrollTo(

    targetID2: string,

): void {

    var targetElement = document.getElementById(targetID2);

    // Verificar se o elemento alvo existe
    if (targetElement) {
        // Rolar até o elemento
        if (window.innerWidth <= 768) {
            targetElement.scrollIntoView();
        }
        else {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

}
const MenuMain = () => {
    const [active, setActive] = useState(false);
    const { configuration } = useConfiguration();
    const goTo = (ID: string) => {
        scrollToID(ID);
        setActive(false)
        document.getElementById(ID)?.focus();


    }
    const [categories, setCategories] = useState<Array<Record<string, any>>>([]);

    const [currentPath, setCurrentPath] = useState<Array<number>>([]);

    const changePath = (option: string, index: number) => {

        if (option === 'close') {

            setCurrentPath(state => {
                const value = [...state];
                value?.pop();

                return value;
            });
            return;
        }
        setCurrentPath(state => [...state, index]);

    }

    const [activeProducts, setActiveProducts] = useState(false);

    const closeMenu = () => {
        window?.scrollTo(0, 0);
        setCurrentPath([]);
        setActive(false);

    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Verifica se a tecla pressionada é Esc
            if (event.key === 'Escape') {
                setActive(false)

            }
        };

        // Adiciona o ouvinte de eventos ao document
        document.addEventListener('keydown', handleKeyDown);



        // Função de limpeza que remove o ouvinte de eventos quando o componente é desmontado
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);



    return <>

        {!active ? <div onKeyDown={(event) => {
            if (event.key === 'Enter') {
                setActive(true);
            }
        }} tabIndex={0} onClick={() => setActive(true)} title="Menu" className='cursor fadeIn scaleOnHover' style={
            {
                background: "#fff",
                border: '2px solid #fff',
                borderRadius: '5px',
                cursor: 'pointer',
                color: '#3a497b',
                zIndex: 10000,
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'

            }
        }>
            <FiMenu style={{ fontSize: '35px' }} />
        </div> : <></>}



        {active ?

            <div className='fadeIn column pad gap-sm shadow'
                style={{
                    background: "#fff",
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    width: '100%',
                    maxWidth: '400px',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    zIndex: 1000000,
                    overflowY: 'auto',
                    padding: '50px 30px'

                }}

            >

                <p style={{ border: '1px solid #ddd', color: '#000', cursor: 'pointer', padding: '5px', borderRadius: '5px', margin: '10px 0px' }} tabIndex={0} onClick={() => setActive(false)} onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setActive(false);
                    }
                }}>Fechar</p>

                {configuration?.active_pages?.map((item, index) => {

                    return currentPath?.length < 1 || currentPath?.[0] === index ? <MenuItem indexNumber={index} position={0} content={item} changePath={changePath} currentPath={currentPath} closeMenu={closeMenu} /> : <></>

                })}




            </div>

            : <></>}

    </>



}

export default MenuMain;