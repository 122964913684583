const template = {
    globalBackground: 'rgb(0,14,64)',
    globalColor: '#fff',
    textComponent: {
        title: {
            color: '#fff'
        },
        paragraph: {
            color: '#fff'
        }
    },
    bodyBackgroundWithOpacity: 'rgba(0,0,0,0.3)',
    color: '#333',
    selectButton: {
        background: 'rgb(0,50,150)',
        borderColor: 'rgb(0,30,100)',
        color: '#fff',
    },
    input: {
        placeholder: '#333',
        background: '#fff',
        color: '#333',
        border: '1px solid #ddd',
        colorFilled: '#333',
        colorFocused: '#162743',
        borderColorFocused: '#162743',
    },
    button: {
        background: 'rgb(100,100,100)',
        color: '#fff',
        backgroundHover: 'rgb(50,50,50)',
        colorHover: '#fff',
    },
    alternativeButton: {
        background: '#162743',
        color: '#fff',
    }
}

export default template;