import React, { useEffect, useRef, useState } from 'react';

const FadeInOnScroll = ({ style = {}, children }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if (domRef?.current) {
      const observer = new IntersectionObserver(entries => {

        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisible(true);
            // Opcional: se você quiser que o efeito aconteça apenas uma vez, você pode desvincular o observer após o elemento ficar visível
            if (domRef.current) {
              observer.unobserve(domRef.current);
            }
          }
        });
      });

      observer.observe(domRef.current);


      return () => observer.disconnect();
    }
  }, []);

  return (
    <div
      ref={domRef}
      style={{
        width: '100%', padding: '0px', margin: '0px',
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'none' : 'translateY(20vh)',
        transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default FadeInOnScroll;