import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import Input from '../../../../components/Forms/Input';
import MaskedInput from '../../../../components/Forms/MaskedInput';

import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useLanguage } from "../../../../hooks/Language"
import { countries } from "./countries";

interface IProps {
    changeCountry: Function;
    countryISO: string;
}

const DefaultSignUpForm: React.FC<IProps> = ({ changeCountry, countryISO }) => {


    const { translate } = useLanguage();

    return <>
        <SelectSimple mandatory={true} setCallback={(value) => changeCountry(value)} options={countries} name="country" label={translate('Pais')} />

        <Input mandatory={true} name="name" placeholder={translate('Nome completo')} />

        {countryISO === 'BR' ? <MaskedInput
            mandatory={true}
            mask="(99) 99999.9999"
            name="phone"
            placeholder={translate('Telefone / Whatsapp')}
        /> : <Input name="phone" mandatory={true}
            placeholder={translate('Telefone')} />}

        <MaskedInput
            mask="99/99/9999"
            name="birth_date" mandatory={true}
            placeholder={translate('Data de Nascimento (DD/MM/AAAA)')}
        />

        {countryISO === 'BR' ? <Input mandatory={true} style={{ display: 'none' }} name="document_type" value="cpf"
            placeholder={translate('Tipo de documento')} /> : <SelectSimple mandatory={true} name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{ label: translate('Passaporte'), value: 'passport' }, { label: translate('Outro'), value: 'other' }]} />}



        {countryISO === 'BR' ? <MaskedInput mandatory={true}
            mask="999.999.999-99"
            name="document_number"
            placeholder={translate('CPF')}
        /> : <Input mandatory={true} name="document_number"
            placeholder={translate('Nº do documento')} />}

        <Input

            name="company"

            placeholder={translate('Empresa')}
        />

        {/*
        <Input
            icon={FaFacebook}
            name="facebook"

            placeholder={translate('Facebook')}
        />

        <Input
            icon={FaInstagram}
            name="extra_4"

            placeholder={translate('Instagram')}
        />

        <Input
            icon={FaLinkedin}
            name="extra_5"

            placeholder={translate('Linkedin')}
        />
*/}
        <p style={{ fontSize: '14px', textAlign: 'center', fontWeight: 'bold', marginTop: '30px', marginBottom: '15px' }}> {translate('Crie seu acesso')}</p>

        <Input mandatory={true} name="email" placeholder={translate('E-mail')} />


        <Input mandatory={true}
            icon={FiLock}
            name="password"
            type="password"
            placeholder={translate('Cadastre sua senha de acesso')}
        />

    </>
}

export default DefaultSignUpForm;