import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

import { FaFileExcel, FaFilter } from "react-icons/fa";

import { convertToXLSX, downloaditPost } from "../../utils/download";
import { apiUrl } from "../../config";
import { formatReal } from "../../utils/format";

interface IData {
    value: number;
    name: string;
    total: number;
    simpleName?: string;
    percentage?: string;
}

interface IChart {
    title?: string;
    subtitle?: string;
    data: Array<Record<string, any>>;
    category?: string;
    group?: string;
    chartType?: string;
    sortBy?: string;
    showTotal?: boolean;
    widthSize?: string;
    heightSize?: string;
    showTable?: boolean;
}

interface IConverter {
    data: IData[];
    type: string;
    title: string;
}

const Chart: React.FC<IChart> = ({ showTable = true, widthSize, heightSize, showTotal = false, category = '', group = '', sortBy = "value", data = [], title = '', subtitle = '', chartType = "pie" }) => {

    const [renderData, setRenderData] = useState<IData[]>([])
    const [groupColumn, setGroupColumn] = useState(group);
    const [type, setType] = useState(chartType);

    const convertToArrayAndExport = ({ data, type = 'xlsx', title }: IConverter) => {

        const content = [[title, 'Quantidade', 'Porcentagem']];

        data.map(item => {
            content.push([item.simpleName || '', item?.value?.toString() || '0', item?.percentage?.toString()?.replace('.', ',') || '0'])
        })



        return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

    }


    const changeGroup = (value: string) => {
        setGroupColumn(value);
    }

    const renderDataInfo = async (groupInfo: string, dataContent) => {


        const dataInfo = dataContent;


        const dataItems: Array<IData> = dataInfo.reduce((prev: Array<IData>, item: Record<string, any>) => {

            const index = prev.findIndex(i => i.simpleName === item?.[groupInfo]);

            if (index >= 0) {
                prev[index].value = prev[index].value + 1;
                prev[index].total = prev[index].total + item?.total;
            }
            else {
                prev.push({ simpleName: item?.[groupInfo], name: item?.[groupInfo], value: 1, total: item?.total })
            }




            return prev;



        }, [] as Array<IData>)

        if (dataItems?.length > 0) {
            dataItems.sort((a, b) => a?.[sortBy] < b?.[sortBy] ? 1 : a?.[sortBy] > b?.[sortBy] ? -1 : 0);
            dataItems.reverse();
        }
        const total = dataItems?.reduce((prev, item) => {

            prev = prev + item?.value;
            return prev;

        }, 0)


        dataItems?.map(d => {
            d.percentage = ((d.value / total) * 100).toFixed(2);
            d.name = `${d.name} - ${d.value} - ${((d.value / total) * 100).toFixed(2)}%`;
        })



        setRenderData(dataItems);


    }
    useEffect(() => {

        renderDataInfo(group, data);
    }, [data, group]);

    // Dynamically adjust options based on chartType
    const getOption = (type) => {


        switch (type) {
            case 'bar':
                return {
                    title: {
                        text: title,
                        subtext: subtitle,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'shadow' }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: renderData.map(item => item.simpleName || item.name)
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: category,
                        type: 'bar',
                        data: renderData.map(item => item.value),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                };
            case 'pie':
            default:
                return {
                    title: {
                        text: title,
                        subtext: subtitle,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },

                    xAxis: {
                        type: 'category',
                        data: []
                    },
                    series: [{
                        name: category,
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: renderData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                };
        }
    };

    const convert = (list: Array<Record<string, any>>) => {

        const items: Array<Array<string>> = [];


        list?.map(item => {

            items.push([item?.simpleName, item?.value, item?.percentage]);

        })


        convertToXLSX(items);

    }


    return (
        <div className="container column" style={{ width: '100%' }}>
            <div className="container row-to-column row-to-column-start gap-sm pad" style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="row" style={{ width: '100%', maxWidth: chartType === 'pie' ? '1000px' : '1000px', overflowX: 'auto' }}>
                    {renderData?.length > 0 ? <ReactEcharts option={getOption(chartType)} style={{
                        height: heightSize ? heightSize : '50vh',
                        width: widthSize ? widthSize : chartType === 'pie' ? '100%' : `${renderData?.length * 100}px`,


                    }} /> : <></>}
                </div>
                <div style={{ width: '100%', maxWidth: '400px', display: showTable ? 'flex' : 'none' }} className="container column column-start max-400 overflow-hidden-height">
                    <table className="tableRef" style={{ width: '100%', maxWidth: '400px' }}>
                        <thead>
                            <tr><th className="row-space-between">  Total</th><th>{renderData.reduce((prev, item) => {
                                prev = prev + item?.value;
                                return prev;

                            }, 0)} </th><th><FaFileExcel onClick={() => convert(renderData)} style={{ cursor: 'pointer', fontSize: '24px', color: 'rgb(0,150,100)' }} /></th>

                                {showTotal ? <th>R$ {formatReal(renderData.reduce((prev, item) => {
                                    prev = prev + item?.total;
                                    return prev;

                                }, 0))}</th> : <></>}
                            </tr>

                            <tr><th className="row-space-between">  </th><th>QTD </th><th>% </th>
                                {showTotal ? <th>R$</th> : <></>}
                            </tr>
                        </thead>
                        <tbody>
                            {renderData?.map(item => <tr key={`render-data-${group}-${item.name}`}><td>{item.simpleName}

                            </td><td>{item.value}</td><td>{item.percentage}%</td>
                                {showTotal ? <td>R$ {formatReal(item?.total)}</td> : <></>}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Chart;