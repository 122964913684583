import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';


import { useConfiguration } from '../../../../hooks/Configuration';

import { useAuth } from '../../../../hooks/Auth';
import duplicateModule from './Modules/duplicate';
import { useSearch } from '../../../../hooks/Search';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}



const SaleCouponRule: React.FC = () => {
  const endpoint = '/sales-coupon-rules';
  const title = 'Cupom de desconto';
  const { reloadSearchAll } = useSearch();
  const { user } = useAuth();
  const formSchema = {

    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    coupon_hash: { model: 'input', type: 'text', name: 'coupon_hash', label: 'Cupon' },
    rules: {
      model: 'jsonInputList',
      type: 'text',
      name: 'rules',
      label: 'Produtos',
      show: ['type', 'field', 'value'],
      list: [{
        label: 'Tipo', name: 'type', type: 'select', options: [
          { label: 'Usuário', value: 'user' },
          { label: 'Carrinho', value: 'cart' },

        ]
      },
      { label: 'Campo', name: 'field', type: 'input' },
      { label: 'Valor', name: 'value', type: 'input' },
      { label: 'QTD', name: 'quantity', type: 'input' },
      ],

    },

    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'Protocolo', show: false },
    project_id: {
      ref: 'project_id',
      column: 'project_id',
      label: 'Projeto',

    },
    event_id: {
      ref: 'event_id',
      column: 'event_id',
      label: 'Evento', show: false,

    },
    title: {
      ref: 'title',
      column: 'title',
      label: 'Título',
      show: true,
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: {},
          endpoint,
        }),

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema,
          validationSchema: {},
          endpoint,
        }),



      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: {},
          endpoint,
        }),



    ],
  };

  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default SaleCouponRule;
