import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponentUnique, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaEdit, FaUpload } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { Form } from '@unform/web';
import JsonList from '../../../../core/components/Forms/JsonListIncluder';
import UploadImage from '../../../../core/components/Forms/UploadImage';
import Button from '../../../../core/components/Forms/Button';

interface ICreateModule {
  lineData: Record<string, any>;

}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const key = uuid();

  const uploadContacts = async (data) => {

    const formData = new FormData();
    formData.append('file', data.file);

    await api.post(`/upload-and-sell/${lineData.project_id}/${lineData.event_id}/${lineData._id}`, formData);
    addToast({ title: 'Upload Realizado com sucesso', type: 'success' });
    removeModal('uploadContacts')

  }

  const handleUpdate = async (id: string): Promise<void> => {


    const content = (
      <Form onSubmit={uploadContacts}>

        <UploadImage name="file" placeholder='Excel com vendas' />
        <Button>Enviar</Button>
      </Form>
    );
    if (content) {
      const keyModal = addModal({ title: 'Upload de vendas', content, key: 'uploadContacts', theme: "whiteModalMini" });
    }
  };

  const moduleUpdate = (lineData): JSX.Element => (
    <button className="lineIcon" title="Editar" type="button" onClick={() => handleUpdate(lineData)}>
      <FaUpload />
    </button>
  );

  return moduleUpdate(lineData);
}
