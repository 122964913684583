import styled from 'styled-components';
import { darken } from 'polished';

interface ILink {
  fontSize: string;
  iconSize: string;
}

export const Whats = styled.a<ILink>`
  z-index: 1;
   color: #333;

  transition: 0.5s all;
  cursor: pointer;

 
  
`;
