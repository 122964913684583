import styled from "styled-components";

export const SidebarContainer = styled.div`



max-height: 80vh;
width: 100%;
max-width: 350px;
padding: 10px 30px;

>div {

    background: rgba(33,33,55,0.7);
    width: 100%;
    border-radius: 10px;
    transition: 0.3s all;
    padding-left: 15px;


    h2{
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        text-align: left;
        width: 100%;
        margin: 10px 0px;
        padding: 10px ;
    }

    >div{
     
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        width: 100%;
        padding: 15px 0px;
       

        nav{
            width: 100%;
            padding: 10px;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
            color: #fff;
            border-left: 3px solid #333;
            transition: 0.3s all;
            &:hover{
        background: #333;  
    }



        }

        nav.activeSidebarMenu{
            background: #ddd;
            color: #333;
        }
    }

}



`;
