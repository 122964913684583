import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../SponsorsPage';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import RodapeLP from '../Home/modules/RodapeLP';
import Whatsapp from '../../components/Whatsapp';
import { ContactIcons } from 'src/components/MenuOld';
import { getYear } from 'date-fns';

const FooterLayout: React.FC = ({ children }) => {
  return (<><div style={{ position: 'relative', zIndex: 0, display: 'block' }}>

    {/* <ImageSliderBackground slides={['/apoio/fundo1.jpg', '/apoio/fundo2.jpg', '/apoio/fundo3.jpg']} /> */}
    <div style={{ width: '100%', marginTop: '10px' }} />

    {window?.location?.pathname?.indexOf('/patrocinadores') < 0 ? <div tabIndex={0} className='gap-bg column ' style={{ position: 'relative', minHeight: '10vh', padding: '0px 15px', background: '#fff', zIndex: 900000 }}>

      <div className='full-width ' style={{ zIndex: 1 }}>


        <Sponsors />

      </div>



    </div> : <></>}

    <div className='row-to-column max-1200 full-width' style={{ zIndex: 2, position: 'relative', gap: '15px', justifyContent: 'space-between', margin: '15px auto', padding: '30px 10px' }}>
      {/* <div className='column-start' style={{ gap: '5px', width: '100%', maxWidth: '300px' }}>
        <strong style={{ color: '#162743' }}>INSCRIÇÕES</strong>
        <p style={{ color: '#162743' }}>(51) 98033-2025</p>
        <p style={{ color: '#162743' }}>atendimento@tribecaeventos.com.br</p>


      </div>

      <div className='column-start' style={{ gap: '5px', width: '100%', maxWidth: '300px' }}>
        <strong style={{ color: '#162743' }}>SEJA UM PATROCINADOR</strong>
        <p style={{ color: '#162743' }}>(51) 98190-0522</p>
        <p style={{ color: '#162743' }}>elivia@tribecaeventos.com.br</p>


      </div>
*/}




      <p style={{ color: '#162743', fontSize: '18px', lineHeight: '24px', maxWidth: '300px', width: '100%', textAlign: 'center', margin: '0px auto' }} className="text-center-mobile"><strong>Contato:</strong> apoio@tcheprevidencia.com.br <br />whatsapp (51) 99214-1027 </p>

      {/*  <p style={{ color: '#162743', fontSize: '14px', maxWidth: '400px', width: '100%' }} className="text-center-mobile">
        <strong>Política de Reembolso:</strong><br />
        Enviar e-mail para forumwlm2025@cemcerimonia.com.br<br />
        Até 60 dias antes da realização – 85% do valor<br />
        Entre 59 e 45 dias antes da realização – 50% do valor<br />
        Até 44 dias antes da realização – Não haverá reembolso
</p> */}
      <ContactIcons color="#162743" />
    </div>
  </div>

  </>
  );
};

export default FooterLayout;
