import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, defaultProjectHash } from '../../../../../../config';
import { FaList, FaMoneyBill, FaReceipt, FaTable, FaTicketAlt, FaUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';

interface ICreateModule {
    lineData: Record<string, any>;
}

export const AplicarCortesia: React.FC<ICreateModule> = ({ lineData }) => {

    const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
    const { addToast } = useToast();
    const { addModal, removeModal } = useModal();
    const { reloadSearchAll } = useSearch();



    const gerarRelatorioFinanceiro = async (data) => {

        try {
            await api.post(`/apply-courtesy/${projectHash}/${lineData?._id?.toString()}/${data?.hash}`);
            addToast({ title: 'Cortesia aplicada com sucesso', type: 'success' });
            reloadSearchAll();
            removeModal('modal');
        }
        catch (err) {
            addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao aplicar cortesia', type: 'error' })
        }




    }


    const modalHash = () => {


        addModal({
            theme: 'whiteModalMini', key: "modal", title: ' ', content: <Form style={{ width: '100%' }} onSubmit={gerarRelatorioFinanceiro}>

                <Input name={'hash'} placeholder="Código de cortesia" />
                <button style={{ margin: '10px auto' }} className='defaultButtonReverse'>Enviar</button>
            </Form>
        })

    }

    const moduleUpdate = (): JSX.Element => (
        projectHash ? <> <button title='Aplicar cortesia' className="lineIcon" type="button" onClick={modalHash}>
            <FaReceipt />
        </button>

        </>
            : <></>
    );

    return moduleUpdate();
}
