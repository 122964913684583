import React from 'react';
import Section from '../Section';
import { IContainer } from '../../hooks/PageCreatorContext';
import { FaPaintBrush } from 'react-icons/fa';
import { BgImageStyle, ContainerStyle } from '../../styles';
import { urlWebsite } from '../../../../../../config';

interface IContainerProps {
    containerIndex: number;
    container: IContainer;
    context: Record<string, any>;
    editable: boolean;
    language: string;

}

const Container: React.FC<IContainerProps> = ({

    container,
    containerIndex,
    context = {},
    editable = true,
    language,
}) => {

    const { addRow,
        addContent,
        moveContainerUp,
        moveContainerDown,
        moveSectionUp,
        moveSectionDown,
        moveRowUp,
        moveRowDown,
        setCurrentView,
        setSelectedIndices,
        selectedIndices } =
        context;
    const style = container.style || {};




    const computerStyle = style?.computer ? { ...style?.computer, background: '' } : {};
    const mobileStyle = style?.mobile ? { ...style?.mobile, background: '' } : {};


    return (<div style={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', background: style?.computer?.['background'] || '' }}>

        {style?.computer?.['background-image'] ? <BgImageStyle className='computerOnly' background={`${urlWebsite}/${style?.computer?.['background-image']}`} backgroundPosition={style?.computer?.['background-position']} backgroundOpacity={style?.mobile?.['background-opacity'] ? style?.mobile?.['background-opacity'] : style?.computer?.['background-opacity']} /> : <></>}
        {style?.mobile?.['background-image'] || style?.computer?.['background-image'] ? <BgImageStyle className='mobileOnly' background={`${urlWebsite}/${style?.mobile?.['background-image'] ? `${style?.mobile?.['background-image']}` : `${style?.computer?.['background-image']}`}`} backgroundPosition={style?.computer?.['background-position']} backgroundOpacity={style?.mobile?.['background-opacity'] ? style?.mobile?.['background-opacity'] : style?.computer?.['background-opacity']} /> : <></>}

        <ContainerStyle computer={computerStyle || {}} mobile={mobileStyle || {}}>
            {container.sections.map((section, sectionIndex) => (
                <Section
                    key={sectionIndex}
                    section={section}
                    containerIndex={containerIndex}
                    sectionIndex={sectionIndex}
                    context={context}
                    editable={editable}
                    language={language}
                />
            ))}
        </ContainerStyle>
    </div >
    );
};

export default Container;