import calculateDiscount from "./calculateDiscount";
import calculateFees from "./calculateFees";

export default function calculateFinalPrice({ cart, coupon, paymentMethod, installments = 1 }) {

    let price = calculateDiscount(coupon, cart)?.discountedValue;

    if (paymentMethod === 'credit_card') {
        price = calculateFees(price, installments, 0.016);

    }

    return price;

}