import React from 'react';
import { useLoading } from "../../../../../../hooks/LoadingHook";
import pagarme from 'pagarme';
import { useModal } from "../../../../../../hooks/Modal";
import { useLanguage } from "../../../../../../hooks/Language";
import { gateKey } from '../../../../../../config';
import { useToast } from '../../../../../../hooks/Toast';
import { useParams } from 'react-router-dom';
import usePaymentRequest from './paymentRequest';

interface ICardProps {
  id?: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_number: string;
  card_cvv: string;
  installments?: number;
  document_type: string;
}


export default function usePaymentWithCreditCard() {

  const { paymentRequest } = usePaymentRequest()
  const { projectHash, eventHash } = useParams<Record<string, any>>()
  const { translate, language } = useLanguage();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const { addToast } = useToast();

  const payWithCreditCard = async ({ paymentContext, moveToCart }) => {




    const cardValidations = await pagarme.validate(paymentContext);


    if (!cardValidations?.card?.card_number) {
      addModal({
        title: translate('Cartão inválido'),
        content: <>{translate('Verifique o número do seu cartão')}</>,
        theme: 'whiteModalMini'
      });
      removeLoading('loading')
      return false;
    }

    await pagarme.client
      .connect({ encryption_key: gateKey })
      .then(async (client: Record<string, any>) => {
        const card_hash = await client.security.encrypt({
          ...paymentContext?.card
        });

        return card_hash;
      })
      .then(async (card_hash: string) => {
        if (!card_hash) {
          removeLoading('loading')
          return false;
        }

        paymentContext.billing = {
          paymentMethod: 'credit_card',
          credit_card_hash: card_hash,
          installments: paymentContext?.billing?.installments,
          document_type: paymentContext?.billing?.document_type,
        };

        paymentContext.card = {};


        await paymentRequest({ paymentContext, moveToCart });

        return false;

      })

  }

  return { payWithCreditCard };

}