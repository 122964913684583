import { Form } from '@unform/web';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useConfiguration } from '../../../../hooks/Configuration';
import { usePrinter } from '../../../../hooks/PrinterHook';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import { decodeCracha } from '../../../DashboardPages/core/components/lineModules/Cracha';
import states from '../../../SignUp/FormContainer/states';
import NumberPad from '../numberPad';
import QRCode from 'qrcode.react';
import { Container, Content, Background, AnimatedDiv2 } from './styles';
import { useLoading } from '../../../../hooks/LoadingHook';


interface IProps {
  studentId: string;
  setCallback: Function;
}

/* 
Rules
Can only print if the reference is different than yes 
have to add crm and crm uf if is Médico or Psicólogo 

*/


const StudentUpdate: React.FC<IProps> = ({ studentId, setCallback }) => {

  const [value, setValue] = useState('');
  const [country, setCountry] = useState('BR');
  const [status, setStatus] = useState('ready');
  const [padPadNumberKey, setPadNumberKey] = useState(uuid());
  const [studentInfo, setStudentInfo] = useState({});
  const { addToast } = useToast();
  const { configuration } = useConfiguration();

  const [crmValue, setCRM] = useState('');
  const [crmStateValue, setCRMState] = useState('');
  const { addLoading, removeLoading } = useLoading();


  const reset = () => {
    setValue('');
    setCountry('BR');
    setStatus('ready');
    setPadNumberKey(uuid());
  }
  const printMyBadge = async ({ crm, crm_uf, id }) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    if (!crm) {
      addToast({ title: 'Informe seu CRM / CRP', type: 'info' });
      removeLoading('loading');
      return;
    }

    if (!crm_uf) {
      addToast({ title: 'Informe o estado do seu CRM / CRP', type: 'info' });
      removeLoading('loading');
      return;
    }

    try {
      const student = await api.post(`update-user-on-auto/${id}`, { crm, crm_uf });
      removeLoading('loading');


      setCallback(student?.data)




    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao solicitar StudentUpdate, dirija-se ao credenciamento.' })
    }

  }

  if (status === 'used') {
    return <Container style={{ flexDirection: 'column' }}>
      <h2>Seu crachá já foi emitido</h2>
      <p>Caso precise de ajuda, solicite apoio no credenciamento.</p>

      <button className='button' onClick={() => reset()}> Novo credenciamento </button>
    </Container>
  }

  return (
    <Container >
      <div>
        <h2 style={{ margin: '20px auto', fontSize: '24px' }}>ATUALIZE SEU CADASTRO</h2>

        <h2 style={{ margin: '20px auto', fontSize: '18px' }}>ESTADO DO CRM / CRP</h2>
        <Form style={{ width: '100%', maxWidth: '600px', margin: '10px auto' }} onSubmit={() => { }}>



          <SelectSimple name='crm_uf' setCallback={(value) => {
            setCRMState(value)
          }} label='Estado do CRM / CRP' options={states} />
        </Form>

        <h2 style={{ margin: '20px auto', fontSize: '18px' }}>INSIRA SEU CRM / CRP</h2>



        <div className='number-display-update'>
          <aside>{crmValue} </aside>



        </div>

      </div>

      <div style={{ width: '30%' }}>
        <NumberPad keyValue={padPadNumberKey} maximum={1000} setCallback={(newValue => setCRM(newValue))} />

        <button className='defaultButton' onClick={() => {
          printMyBadge({ crm: crmValue, crm_uf: crmStateValue, id: studentId })

        }}>Enviar</button>
      </div>
    </Container>
  );
};
export default StudentUpdate;
