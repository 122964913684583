
import React, { useEffect, useState } from 'react';
import { FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa,



} from '../Themes/SinglePageTheme/styles';

import { Container } from './styles';

const SpeakersListByCountry = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <aside style={{ marginBottom: '25px', background: 'rgba(255,255,255,0.9)', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>

        <Container style={{ flexDirection: 'column' }}  >
            <div className='content'>
                <h2 className='titleElement'>PALESTRANTES CONFIRMADOS</h2>
            </div>
            <div className='contentItems'>

                <div className='speakerWithCountry'>
                    <img src="/apoio/canada.png" />
                    <p>Adriana Luk <br />
                        Neil Fam <br />
                        Ashkai Bagai <br />
                        Juglans Alvarez <br />
                        Vidal Essebag <br />
                    </p>

                </div>


                <div className='speakerWithCountry'>
                    <img src="/apoio/alemanha.png" />
                    <p>Nicholaus Marx
                    </p>

                </div>
                <div className='speakerWithCountry'>
                    <img src="/apoio/usa.png" />
                    <p>Robert Gottlieb <br />
                        Mandeep Mehra <br />
                        Shelley Hall <br />
                        Jason Katz<br />
                        Maria Rosa Costanzo<br />
                        João Breda<br />
                        Ramesh Hariharan<br />
                        Anu Lala<br />
                        Adam Devore<br />
                        Renato Lopes<br />
                        Guilherme Oliveira<br />

                    </p>

                </div>


                <div className='speakerWithCountry'>
                    <img src="/apoio/espanha.png" />
                    <p>Julio Nuñez


                    </p>

                </div>
                <div className='speakerWithCountry'>
                    <img src="/apoio/brasil.png" />
                    <p>Fernando Bacal<br />
                        Luis E. Rohde<br />
                        Fabiana Marcondes<br />
                        Jefferson Vieira<br />
                        Felipe Fuchs<br />
                        Alexandre Quadros<br />
                        Sandrigo Mangini<br />
                        Mucio Tavares<br />
                        Lidia Zytinski<br />
                        Fernando Atik<br />



                    </p>

                </div>
            </div>


        </Container>
    </aside>
}

export default SpeakersListByCountry;