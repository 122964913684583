import React, { useEffect, useState } from 'react';
import { AiFillStepBackward } from 'react-icons/ai';
import { Container } from "./styles";

interface IProps {
    setCallback: Function;
    keyValue: string;
    maximum: number;
}

const NumberPad: React.FC<IProps> = ({ setCallback, keyValue, maximum }) => {

    const [value, setValue] = useState('');

    const addNumber = (currentValue: string, number: string) => {

        if (currentValue?.length < maximum) {
            setValue(`${currentValue}${number}`);
        }

    }

    const removeLastNumber = (currentValue: string) => {

        const length = currentValue?.length;

        const newValue = currentValue.substring(0, length - 1);

        setValue(newValue);

    }

    const clear = () => {
        setValue('');

    }

    useEffect(() => {
        setCallback(value);
    }, [value])

    useEffect(() => {
        setValue('');
    }, [keyValue])

    return <Container>

        <div className='number-pad-row'>
            <aside onClick={() => addNumber(value, '1')}>1</aside>
            <aside onClick={() => addNumber(value, '2')}>2</aside>
            <aside onClick={() => addNumber(value, '3')}>3</aside>
        </div>
        <div className='number-pad-row'>
            <aside onClick={() => addNumber(value, '4')}>4</aside>
            <aside onClick={() => addNumber(value, '5')}>5</aside>
            <aside onClick={() => addNumber(value, '6')}>6</aside>
        </div>
        <div className='number-pad-row'>
            <aside onClick={() => addNumber(value, '7')}>7</aside>
            <aside onClick={() => addNumber(value, '8')}>8</aside>
            <aside onClick={() => addNumber(value, '9')}>9</aside>
        </div>
        <div className='number-pad-row'>
            <aside onClick={() => addNumber(value, '0')}>0</aside>
            <aside onClick={() => removeLastNumber(value)}><AiFillStepBackward /></aside>
            <aside className='limpar' onClick={() => clear()}>Limpar</aside>
        </div>
    </Container>

}

export default NumberPad;