import React, { useCallback, useRef, useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import { useConfiguration } from '../../hooks/Configuration';
import { usePrinter } from '../../hooks/PrinterHook';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import { decodeCracha } from '../DashboardPages/core/components/lineModules/Cracha';
import NumberPad from './modules/numberPad';

import { Container, Content, Background, AnimatedDiv2 } from './styles';
import QRCode from 'qrcode.react';
import StudentUpdate from './modules/studentUpdate';
import { defaultProjectHash } from '../../config';
import { useParams } from 'react-router-dom';
import { useLoading } from '../../hooks/LoadingHook';

interface AutoatendimentoData {
  email: string;
  password: string;
  name: string;
}

/* 
Rules
Can only print if the reference is different than yes 
have to add crm and crm uf if is Médico or Psicólogo 

*/

const Autoatendimento: React.FC = () => {

  const [value, setValue] = useState('');
  const [country, setCountry] = useState('BR');
  const [status, setStatus] = useState('ready');
  const [padPadNumberKey, setPadNumberKey] = useState(uuid());
  const [studentInfo, setStudentInfo] = useState<Record<string, any>>();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const { configuration } = useConfiguration();
  const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
  const { addLoading, removeLoading } = useLoading();
  const reset = () => {
    setValue('');
    setCountry('BR');
    setStatus('ready');
    setPadNumberKey(uuid());
  }





  const print = async line => {


    printIt();
    await api.post(`/credenciar`, { hash: line.user_hash });
    setStatus('finished')

  };

  const handlePrint = async (line): Promise<void> => {



    const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;



    addPrint([
      <div className="qrHolder2" style={{ display: 'flex', width: '100%', minWidth: '8.7cm', justifyContent: 'space-between', alignItems: 'center', padding: '15px', paddingBottom: '0px', paddingTop: configuration?.theme_data?.cracha_margin_top_line ? configuration?.theme_data?.cracha_margin_top_line : '0px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth: '6cm', flexDirection: 'column' }}>
          {decodeCracha(line, configuration?.cracha_config)}
        </div>

        {configuration?.cracha_have_qrcode !== 'no' ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
          size={80}
          value={line.user_hash} renderAs="svg" /></div> : <></>}
      </div>]
    );


    print(line);


  };


  const printMyBadge = async (currentValue) => {
    addLoading({ title: 'Carregando', key: 'loading2' });
    try {
      const student = await api.get(`check-student-by-document-number/${projectHash}/${currentValue}`);

      const data = student?.data;


      if (student?.data?.reference === 'Sim') {
        setStatus('used');
        removeLoading('loading2');
        return;
      }


      if (!data?.crm && (data?.category_id?.indexOf('Médico') >= 0 || data?.category_id?.indexOf('Psicólogo') >= 0 || data?.category_id?.indexOf('Psicologo') >= 0)) {
        setStudentInfo(data);
        setStatus('update');
        removeLoading('loading2');
        return;

      }
      removeLoading('loading2');

      handlePrint(student?.data);
      setStatus('finished')




    }
    catch (err) {
      removeLoading('loading2');
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao solicitar autoatendimento, dirija-se ao credenciamento.' })
    }

  }

  if (status === 'used') {
    return <Container style={{ flexDirection: 'column', gap: '20px' }}>
      <h2 style={{ fontSize: '28px' }}>Seu crachá já foi emitido</h2>
      <p>Caso precise de ajuda, solicite apoio no credenciamento.</p>

      <button className='defaultButton' onClick={() => reset()}> Novo credenciamento </button>
    </Container>
  }

  if (status === 'finished') {
    return <Container style={{ flexDirection: 'column', gap: '20px' }}>
      <h2 style={{ fontSize: '28px' }}>Credenciamento realizado com sucesso</h2>
      <p>Caso precise de ajuda, solicite apoio no credenciamento.</p>

      <button className='defaultButton' onClick={() => reset()}> Novo credenciamento </button>
    </Container>
  }

  if (status === 'update' && studentInfo) {
    return <StudentUpdate studentId={studentInfo?._id?.toString()} setCallback={(value) => {
      handlePrint(studentInfo)
    }} />
  }

  return (
    <Container>
      <div>
        <h2 style={{ margin: '20px auto' }}>INSIRA SEU CPF</h2>


        {country === 'BR' ? <div className='number-display'>
          <aside>{value?.[0]}</aside>
          <aside>{value?.[1]}</aside>
          <aside>{value?.[2]}</aside>.
          <aside>{value?.[3]}</aside>
          <aside>{value?.[4]}</aside>
          <aside>{value?.[5]}</aside>.
          <aside>{value?.[6]}</aside>
          <aside>{value?.[7]}</aside>
          <aside>{value?.[8]}</aside>-
          <aside>{value?.[9]}</aside>
          <aside>{value?.[10]}</aside>


        </div> : <div className='number-display-estrangeiros'>
          <aside>{value}</aside>


        </div>}

      </div>

      <div style={{ width: '30%' }}>
        <NumberPad keyValue={padPadNumberKey} maximum={country === 'BR' ? 11 : 15} setCallback={(newValue => setValue(newValue))} />

        <button className='defaultButton' onClick={() => { printMyBadge(value) }}>Enviar</button>

        {country === 'BR' ? <button className='defaultButton' onClick={() => setCountry('AR')}>Sou estrangeiro</button> : <button className='defaultButton' onClick={() => setCountry('BR')}>Sou brasileiro</button>}

      </div>
    </Container>
  );
};
export default Autoatendimento;
