import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { sanitizeData } from '../../core/components/FormComponent';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
 
import { useConfiguration } from '../../../../hooks/Configuration';
 
import recreate from './Modules/Recreate';
import { FaMoneyCheck } from 'react-icons/fa';
import { useAuth } from '../../../../hooks/Auth';
 
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}
  

const SaleLog: React.FC = () => {
  const endpoint = '/sales-log';
  const title = 'vendas';
  const {user } = useAuth();
  const formSchema = { 
  };

  const formValidation: Record<string, ValidationSchema> = {
 
  };


    const {projectHash, eventHash } = useConfiguration(); 
    
    const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'Protocolo', show: false },
    project_id: {
      ref: 'project_id',
      column: 'project_id',
      label: 'Projeto',
     
    },
    event_id: {
      ref: 'event_id',
      column: 'event_id',
      label: 'Evento',
     
    },
    sale_hash: {
      ref: 'sale_hash',
      column: 'sale_hash',
      label: 'Hash',
      show: true,
    },
    name: {
      ref: 'name',
      column: 'name',
      label: 'Nome',
      show: true,
    },
    email: {
      ref: 'email',
      column: 'email',
      label: 'E-mail',
      show: true,
    },
  
    product: {
      ref: 'product_id',
      column: 'product_id_response',
      includeColumn: 'title',
      label: 'Produto',
    },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

 
  
  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
 
    ],
    bodyModules: user.email === 'guilherme@encontrodigital.com.br' ? 
      [
         
          (lineData: Record<string, any>) =>
          recreate({
            lineData
            
          }),
    
      
    ] : [
      
    ],
  };

  return (
    <Container>
        
      <SearchComponent {...config} />
    </Container>
  );
};

export default SaleLog;
