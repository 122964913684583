import React from 'react';
import ContentItem from '../ContentItem';
import { IRow, IContentItem } from '../../hooks/PageCreatorContext';
import { FaEdit, FaArrowUp, FaArrowDown, FaPaintBrush } from 'react-icons/fa';
import { RowStyle } from '../../styles';

interface IRowProps {
    row: IRow;
    containerIndex: number;
    sectionIndex: number;
    rowIndex: number;
    context: Record<string, any>;
    editable: boolean;
    language: string;
}

const Row: React.FC<IRowProps> = ({
    row,
    containerIndex,
    sectionIndex,
    rowIndex,
    context = {},
    editable = true,
    language
}) => {
    const style = row.style || {};
    const { addContent,
        moveRowUp,
        moveRowDown,
        setCurrentView,
        setSelectedIndices } = context;

    const handleOpenContentEditor = () => {
        setSelectedIndices({ containerIndex, sectionIndex, rowIndex });
        setCurrentView('contentItem');
    };



    const handleOpenContentUpdate = (contentIndex) => {
        setSelectedIndices({ containerIndex, sectionIndex, rowIndex, contentIndex });
        setCurrentView('editContent');
    };

    return (

        <RowStyle className="rowStyle" computer={style?.computer || {}} mobile={style?.mobile || {}} >

            {row.content.length === 0 ? (
                editable ? <div className="placeholder" onClick={handleOpenContentEditor}>
                    Clique para adicionar um conteúdo
                </div> : <></>
            ) : (
                row.content.map((contentItem, contentIndex) => (
                    <ContentItem language={language} context={context} editable={editable} editContent={() => { return editable ? handleOpenContentUpdate(contentIndex) : false }} key={contentIndex} contentItem={contentItem} />
                ))
            )}
        </RowStyle>

    );
};

export default Row;