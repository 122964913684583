import React, { useEffect, useState } from 'react';
import { FaChalkboardTeacher, FaEye, FaFileExcel } from 'react-icons/fa';
import CopyToClipboard from '../../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../../hooks/Auth';
import downloadit, { downloaditPost } from '../../../../../../utils/download';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import api from '../../../../../../services/api';
import { JsxEmit } from 'typescript';
import { useModal } from '../../../../../../hooks/Modal';

interface ICreateModule {
  lineData: Record<string, any>;
}

const SaleReport: React.FC<ICreateModule> = ({ lineData }) => {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [sales, setSales] = useState<Record<string, any>[]>([]);
  const [contador, setContador] = useState(5)

  useEffect(() => {

    if (contador === 5) {
      load();

    }

  }, [contador])

  const reloadLoad = () => {
    let x = 5;
    setInterval(() => {
      setContador(x);
      x = x - 1;

      if (x === -2) {
        x = 5
      }

    }, 1000)
  }


  const load = async () => {




    const productsList = await api.get('/products', {
      params: {
        limitItems: 10000,
        where: { project_id: lineData?.project_id },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          title: { ref: 'title', label: 'Título', search: true },
        }

      }
    });

    const salesList = await api.get('/sales-products', {
      params: {
        limitItems: 10000,
        where: { project_id: lineData?.project_id, payment_status_id: { "$ne": "cancelled" } },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          client_id: { ref: 'client_id', label: 'Titular', search: true },
          payer_id: { ref: 'payer_id', label: 'Comprador', search: true },
          product_id: { ref: 'product_id', label: 'producte', search: true },
          payment_status_id: { ref: 'payment_status_id', label: 'Status de pagamento', search: true },
        }

      }
    });

    setSales(salesList?.data?.rows)
    setProducts(productsList?.data?.rows)

  }

  useEffect(() => {
    load();
    reloadLoad();
  }, [])

  const modalItem = async (product, sales) => {
    const students = await api.get('/students', {
      params: {
        limitItems: 10000,
        where: { project_id: lineData?.project_id },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          name: { ref: 'name', label: 'Nome', search: true },
          company: { ref: 'company', label: 'Empresa', search: true },
          email: { ref: 'email', label: 'E-mail', search: true },
        }
      }
    });
    const items: Array<JSX.Element> = [];

    const status = {
      paid: 'Pago',
      waiting: 'Aguardando pagamento'
    }

    const paymentMethod = {
      credit_card: 'Cartão de Crédito',
      boleto: 'Boleto',
      pix: 'Pix'
    }

    sales?.map(sale => {

      const student = students?.data?.rows?.find(st => st?._id?.toString() === sale?.client_id);
      const payer = students?.data?.rows?.find(st => st?._id?.toString() === sale?.payer_id);

      items.push(<tr><td>{product?.title}</td><td>{student?.name}</td><td>{payer?.name}</td><td>{status?.[sale?.payment_status_id]}</td><td>{paymentMethod?.[sale?.payment_method_id]}</td></tr>)


    })

    addModal({
      title: 'Compradores', theme: 'whiteModal', content: <><p style={{ width: '100%', textAlign: 'right', padding: '10px' }}>Atualiza em: {contador} segundos</p><table className='table'>
        <tbody>
          <tr>
            <td></td>
            <td><strong>Produto</strong></td>
            <td><strong>Titular</strong></td>
            <td><strong>Comprador</strong></td>
            <td><strong>Status de Pagamento</strong></td>
            <td><strong>Forma de Pagamento</strong></td>
          </tr>
          {items}
        </tbody>
      </table></>
    })
  }

  return <><p style={{ width: '100%', textAlign: 'right', padding: '10px' }}>{contador > 0 ? <p>Atualiza em: {contador} segundos</p> : <p>Carregando...</p>}</p><table className='table'>
    <tbody>
      <tr>
        <td></td>
        <td><strong>Produto</strong></td>
        <td><strong>Vendas</strong></td>
        <td><strong>Pagas</strong></td>
        <td><strong>Aguardando</strong></td>
        <td><strong>% Vendidos</strong></td>
      </tr>
      {products?.map(product => {
        const salesItems = sales?.filter(i => i.product_id === product?._id?.toString() && (i?.payment_status_id === 'paid' || i?.payment_status_id === 'waiting'));
        const salesPaid = sales?.filter(i => i.product_id === product?._id?.toString() && i?.payment_status_id === 'paid')?.length;
        const salesWaiting = sales?.filter(i => i.product_id === product?._id?.toString() && i?.payment_status_id === 'waiting')?.length;
        const percent = product?.total_quantities ? `${((salesItems?.length / parseInt(product?.total_quantities, 10)) * 100)?.toFixed(2)}%` : '-';

        return <tr><td><button className='lineIcon buttonColored' type='button' onClick={() => modalItem(product, salesItems)}><FaEye /></button></td><td>{product.title}</td><td>{salesItems?.length}</td><td>{salesPaid}</td><td>{salesWaiting}</td><td>{percent}</td></tr>



      })}
    </tbody>
  </table></>




}


export default function ExportList({ lineData }: ICreateModule): JSX.Element {



  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const { user } = useAuth();
  const download = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })



    addModal({
      title: `Vendas`, content: <SaleReport lineData={lineData} />, theme: 'whiteModal'
    })

    removeLoading('loading');

  }

  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button title="Visualizar vendas" type='button' onClick={() => download()} className='lineIcon'><FaFileExcel /></button> : <></>}
    </>;
  }



  return moduleList();
}
