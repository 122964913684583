import React, { useState, useEffect } from 'react';
import { IconType } from 'react-icons';
import { FiLoader } from 'react-icons/fi';
import { useSocket } from '../../../../../hooks/Socket';
import api from '../../../../../services/api';
import { Container } from './styles';

interface ICounterCard {
  endpoint: string;
  Icon?: IconType;
  title: string;
  value?: number;
  setValue?: boolean;
  onClick?: Function;
}

const CounterCardSocket: React.FC<ICounterCard> = ({
  endpoint,
  Icon,
  title,
  value = 0,
  setValue = false,
  onClick = () => { },
}) => {
  const [counter, setCounter] = useState(value);
  const [loading, setLoading] = useState(false);

  const { socket } = useSocket();

  useEffect(() => {
    setInterval(() => {
      socket?.emit('getAdminReport');
    }, 1000)
  }, [])

  useEffect(() => {
    socket?.off('usersCount');
    socket?.on('usersCount', (data) => {

      setCounter(data);
    })
  }, [endpoint, value, setValue]);

  return (
    <Container onClick={() => onClick()}>
      {Icon && Icon({})}
      <h3>{title}</h3>
      <p>{loading ? <FiLoader /> : counter}</p>
    </Container>
  );
};
export default CounterCardSocket;
