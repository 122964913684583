import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from "../../../../../../hooks/Language"

const ProductNotAvailable: React.FC = () => {

    const { projectHash } = useParams<Record<string, any>>();
    const { translate } = useLanguage();

    return <div className="onlineMessage" style={{ display: 'flex', background: '#fff', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>

        <h2 className="defaultMarked" style={{ color: '#333' }}>{translate('Inscrição encerrada')}</h2>
        <p style={{ color: '#333', textAlign: 'center', marginTop: '20px' }}>
            {translate(
                'Esta inscrição não está mais disponível',
            )}
        </p><br /><br />
        <Link to={`/app/${projectHash}`}>
            <button className="defaultButton" type="button">
                {' '}
                {translate('Voltar')}
            </button>
        </Link>
    </div>

}

export default ProductNotAvailable;