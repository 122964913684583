import styled from 'styled-components';
import { darken } from 'polished';

interface ILink {
  fontSize: string;
  iconSize: string;
}

export const Whats = styled.a<ILink>`
  z-index: 1;
  border-radius: 50%;
  background: rgb(50, 150, 26) !important;
  min-width: 50px;
  height: 50px; ;
  width: 50px;
  max-width: 50px;
 
 
  font-size: 12px;
  font-weight: bold;
  color: #fff !important;
  cursor: pointer;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
   
  border: 2px solid #18ae00;
  text-decoration: none;

  transition: 0.2s all;
  cursor: pointer;
  box-shadow: rgb(7 13 28 / 25%) 0px 9px 90px;

  &:hover {
    transform: scale(1.1) ;
  }

  img{
    width: 25px;
  }

  .icon {
    width: ${props => (props.iconSize ? props.iconSize : '20px')};
    height: ${props => (props.iconSize ? props.iconSize : '20px')};
    margin-right: 10px;
  }

  @media (max-width: 760px) {
    width: 50px;
  }
`;
