import React, { useEffect, useState } from 'react';
import { FaCartArrowDown, FaChalkboardTeacher, FaEye, FaFileExcel } from 'react-icons/fa';
import CopyToClipboard from '../../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../../hooks/Auth';
import downloadit, { downloaditPost } from '../../../../../../utils/download';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import api from '../../../../../../services/api';
import { JsxEmit } from 'typescript';
import { useModal } from '../../../../../../hooks/Modal';
import { date } from '../../../../../../utils/date';
import Whatsapp from '../../../../core/components/Whatsapp';
import WhatsappMini from '../../../../core/components/WhatsappMini';

interface ICreateModule {
  lineData: Record<string, any>;
}

const SaleReport: React.FC<ICreateModule> = ({ lineData }) => {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [contador, setContador] = useState(5)

  useEffect(() => {

    if (contador === 5) {
      load();

    }

  }, [contador])

  const reloadLoad = () => {
    let x = 5;
    setInterval(() => {
      setContador(x);
      x = x - 1;

      if (x === -2) {
        x = 5
      }

    }, 1000)
  }


  const load = async () => {




    const studentsList = await api.get('/students', {
      params: {
        limitItems: 10000,
        where: { profile: 2 },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          name: { ref: 'name', label: 'Nome', search: true },
        }

      }
    });

    const salesList = await api.get('/sales-products', {
      params: {
        limitItems: 10000,
        where: { project_id: lineData?.project_id },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          client_id: { ref: 'client_id', label: 'Titular', search: true },
          payer_id: { ref: 'payer_id', label: 'Comprador', search: true },
          product_id: { ref: 'product_id', label: 'producte', search: true },
          payment_status_id: { ref: 'payment_status_id', label: 'Status de pagamento', search: true },
        }

      }
    });

    const items: Record<string, any>[] = [];

    studentsList?.data?.rows?.map(student => {
      if (student?.profile !== 2) {
        return;
      }
      if (student?.email?.indexOf('encontrodigital') >= 0) { return; }
      const index = salesList?.data?.rows?.findIndex(sale => (sale?.payment_status_id === 'paid' || sale?.payment_status_id === 'waiting') && (sale?.payer_id === student?._id?.toString() || sale?.client_id === student?._id?.toString()))

      if (index < 0) {
        items.push({ name: student?.name, email: student?.email, phone: student?.phone, createdAt: student?.createdAt });

      }

    })

    setData(items)


  }

  useEffect(() => {
    load();

  }, [])


  const convertToArrayAndExport = async ({ data, type = 'xlsx' }) => {

    addLoading({ title: 'Carregando...', key: 'loading' })
    const content = [['Nome', 'E-mail', 'Telefone', 'Data de criação']];

    data.map(item => {
      content.push([item.name, item.email, item?.phone, date(item?.createdAt)])
    })



    await downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });
    removeLoading('loading');
    return

  }

  return <><div style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex', padding: '10px' }}>
    <button className='lineIcon' onClick={() => { convertToArrayAndExport({ data }) }}><FaFileExcel /></button>

    <p>{data?.length} usuários</p></div><table className='table'>
      <tbody>
        <tr>
          <td>  </td>
          <td><strong>Nome</strong></td>
          <td><strong>E-mail</strong></td>
          <td><strong>Telefone</strong></td>
          <td><strong>Data de cadastro</strong></td>

        </tr>
        {data?.map(item => {

          return <tr> <td> <WhatsappMini number={`55${item?.phone}`} message={`Chamar`} text={`Olá, ${item?.name}. Nós somos do 15º Encontro de Previdência Complementar - Região Sul. Identificamos que você iniciou a sua inscrição, porém não finalizou a etapa de pagamento. Ocorreu algum problema? Como podemos lhe ajudar?`} /> </td>
            <td>{item?.name}</td><td>{item?.email}</td><td>{item?.phone}</td><td>{date(item?.createdAt)}</td></tr>



        })}
      </tbody>
    </table></>




}


export default function ExportList({ lineData }: ICreateModule): JSX.Element {



  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const { user } = useAuth();
  const download = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })



    addModal({
      title: `Abandono de Carrinho`, content: <SaleReport lineData={lineData} />, theme: 'whiteModal'
    })

    removeLoading('loading');

  }

  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button title="Visualizar Abandono de Carrinho" type='button' onClick={() => download()} className='lineIcon'><FaCartArrowDown /></button> : <></>}
    </>;
  }



  return moduleList();
}
