import { useState } from "react";
import { useToast } from "../../../../../hooks/Toast";

export default function useCart() {

    /* this function manage the cart */

    const [cart, setCart] = useState<Array<Record<string, any>>>([]);
    const { addToast } = useToast();

    const changeCart = (product, option, cartCurrentValue) => {



        const cartItems = [...cartCurrentValue];

        const index = cartItems?.findIndex(item => item?._id?.toString() === product?._id?.toString());

        if (option === 'add') {

            if (((cartItems?.[index]?.quantity || 0) + 1) > product?.available_quantities) {
                addToast({ title: 'Quantidade indisponível', type: 'info' });
                return;
            }

            if (index >= 0) {
                cartItems[index].quantity = cartItems[index].quantity + 1;
            }
            else {
                cartItems.push({ ...product, quantity: 1 });
            }


        }
        else if (option === 'remove') {

            if (index >= 0) {
                if (cartItems[index].quantity === 1) {
                    cartItems.splice(index, 1);
                }
                else {
                    cartItems[index].quantity = cartItems[index].quantity - 1;
                }

            }


        }
        setCart(cartItems)



    }

    return { cart, changeCart, setCart }

}