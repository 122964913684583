import React from 'react';
import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import { urlWebsite } from "../../../../../../config";
import RenderPageImages from "../../modules/RenderPageImages";
import RenderPageImagesSponsors from "../../modules/RenderPageImagesSponsors";

const FluxoTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;

  return (
    <>

      <ContainerLineUI id={hash} style={{ flexDirection: 'column', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }} >
        {pageContent?.theme_data?.show_title !== 'no' ? <h2 className='titleElement max-1400 gap' style={{ margin: '0px auto', color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333' }}>{pageContent?.title}</h2> : <></>}
        <RenderPageImagesSponsors images={pageContent?.images} />

      </ContainerLineUI>
    </>
  );


}

export default FluxoTemplate;