import React from 'react';

import { Container } from './styles';

import { usePrinter } from '../../../../../hooks/PrinterHook';

const PrinterContainer: React.FC = ({ children }) => {
  const { printMessage } = usePrinter();

  return (
    <Container>
      <div>{children}</div>
      <div className="printMessage" style={{ width: '100%' }}>
        {printMessage}
      </div>
    </Container>
  );
};

export default PrinterContainer;
