import React from 'react';
import { FaChalkboardTeacher } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../config';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  export default function SpeakerLinkModule({
    lineData,
  }: ICreateModule): JSX.Element {



  const moduleCreate = (): JSX.Element => { 
    return lineData.hash ? <CopyToClipboard Icon={FaChalkboardTeacher} text={`${urlWebsite}/moderator/access/${lineData.project_id}/${lineData.hash}`}/> : <></>;
  }
     
  

  return moduleCreate();
}
