import Axios from "axios"

type IProps = {
    hash: string,
    project_id: string,
    table: string
}

export const loadFromApiJson = async ({ hash, project_id, table }: IProps) => {

    try {
        const date = new Date().getTime();

        const response = await Axios(`https://content.encontrodigital.com.br/${hash}/${project_id}/${table}.json?t=${date}`);

        return response.data;


    } catch (err) {
        return false;
    }

}

export const createApiJsonEndpoint = async ({ hash, project_id, table }: IProps) => {
    return `https://content.encontrodigital.com.br/${hash}/${project_id}/${table}`;
}