import React from 'react';
import { FaChalkboardTeacher, FaFileExcel } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  
  export default function ExportList({
    lineData,
  }: ICreateModule): JSX.Element {

    const {user} = useAuth();

    const download = (data) => {

      downloadit({url: `${apiUrl}/getParticipants/${data._id}?type=xlsx`});
    }

  const moduleList  = ({data}): JSX.Element => { 

    return <> { user && user.profile === 1 ?  <FaFileExcel onClick={() => download(data)} color="#333" size={30}  title="Exportar Lista geral"/>  : <></>}
    </>;
  }
     
  

  return moduleList({data: lineData});
}
