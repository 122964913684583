import React, { useEffect, useState } from 'react';



import { urlWebsite } from '../../../../../../../config';

import ParagraphDangerous from '../../../../../../../components/UIComponents/ParagraphDangerous';

import { useModal } from '../../../../../../../hooks/Modal';

interface IProps {
    list: Record<string, any>;
}

const GridList: React.FC<IProps> = ({ list }) => {


    const [items, setItems] = useState<JSX.Element[]>([])
    const { addModal } = useModal();

    const openModal = (item: Record<string, any>) => {

        addModal({
            title: '', theme: 'whiteModalExtra', background: item?.theme?.find((i: Record<string, any>) => i?.column === 'background')?.value, content: <>

                <div className='pad-bg ' style={{ width: '100%', flex: '1', minHeight: '350px', background: item?.theme?.find((i: Record<string, any>) => i?.column === 'background')?.value || '#fff' }}>
                    <h2 className='pad' style={{ fontSize: '22px', lineHeight: '26px', textAlign: 'center', color: '#fff', bottom: '0px', left: 0, width: '100%' }}>
                        {item?.title}
                    </h2>
                    <ParagraphDangerous className=' colorWhite' style={{ fontSize: '18px' }} text={item?.description} />


                    {item?.links?.length > 0 ? <>
                        <h2 style={{ fontSize: '18px', lineHeight: '22px', textAlign: 'center', marginTop: '30px', marginBottom: '15px', color: '#fff' }}>Saiba Mais</h2>
                        <div className='row flex-wrap full-width gap'  >
                            {item?.links?.map((link: Record<string, any>) => {
                                return <a href={link?.url} target="_blank" className='scaleOnHover'><button className='highlightButtonWhite'>{link?.title}</button></a>
                            })}


                        </div></> : <></>}


                </div>
            </>
        })


    }

    useEffect(() => {

        const listItems = list.reduce((prev: JSX.Element[], item: Record<string, any>) => {

            prev.push(<div className='column scaleOnHoverImage max-300' style={{ width: '100%', borderRadius: '5px', cursor: 'pointer' }}>
                <div onClick={() => openModal(item)} onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        openModal(item)
                    }
                }} className='bgImage' style={{ position: 'relative', background: `url("${urlWebsite}/${item?.image}")`, paddingBottom: '60%', width: '100%' }}>


                    <h2 className='pad' style={{ background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)', position: 'absolute', fontSize: '18px', lineHeight: '18px', textAlign: 'center', bottom: '0px', left: 0, width: '100%' }}>
                        {item?.title}
                    </h2>


                </div>



            </div>)

            return prev;
        }, [] as JSX.Element[])

        setItems(listItems);

    }, [list])





    return (<><div className='full-width row-start gap' style={{ width: '100%', padding: '50px', alignItems: 'stretch', flexWrap: 'wrap' }}>{items}</div>

    </>);
};
export default GridList;
