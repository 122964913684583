import React from 'react';
import { Link } from "react-router-dom";

interface IProps {
    data?: Record<string, any>;
}

const LinkListWidget: React.FC<IProps> = ({ data }) => {

    return <div className="full-width row-to-column flex-wrap">

        {data?.map(linkList => {

            return <div className="column-start gap-sm  max-300  pad text-left" >
                <h2 style={{ fontSize: linkList?.titleFontSize || '16px', lineHeight: '20px', color: linkList?.titleColor || '#333' }}>{linkList?.title}</h2>

                {linkList?.links?.map(linkValue => {

                    return linkValue?.type === 'external' ? <a tabIndex={0} href={linkValue?.url} target='_BLANK' className='row row-start gap-sm ' >
                        <p style={{ color: linkList?.contentColor || '#333', fontSize: linkList?.contentFontSize || '14px' }}> {linkValue?.title}</p>
                    </a> : <Link tabIndex={0} to={linkValue?.url} className='row-start  gap-xs column-start-mobile ' >
                        <p style={{ color: linkList?.contentColor || '#333', fontSize: linkList?.contentFontSize || '14px' }}>Associadas</p>
                    </Link>


                })}




            </div>


        })}
    </div>

}



export default LinkListWidget;