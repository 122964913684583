import React, { useCallback, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';


import FormContainer from './FormContainer';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SpeakerConfirmationComponent: React.FC = () => {

  return (
    <Container>
      <Content>


        <FormContainer />

      </Content>
    </Container>
  );
};

export default SpeakerConfirmationComponent;
