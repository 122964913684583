import React from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import IframePlayer from 'src/components/IframePlayer';
import { useModal } from 'src/hooks/Modal';

// import { Container } from './styles';
interface VimeoProps {
  item: Record<string, any>;
  theme?: Record<string, any>;
  keyName?: string;
  time?: string;
}

const IframeListItem: React.FC<VimeoProps> = ({ item, keyName, theme = {}, time = '0' }) => {
  const { addModal } = useModal();

  const openItem = (content) => {


    addModal({
      title: '', content: <div className='column full-width pad gap-sm'>
        <p style={{ color: '#162743', fontSize: '22px', textAlign: 'center', width: '100%' }}>{content?.title}</p>
        <IframePlayer id={content?.url} keyName={content?.url} />
      </div>, theme: 'whiteModal'
    })


  }


  return <nav onClick={() => openItem(item)} className='row gap-sm pad-sm'>
    <FaPlayCircle style={{ color: '#162743', fontSize: '22px' }} /> <p style={{ color: theme?.color || '#333' }}>{item?.title}</p>
  </nav>
};

export default IframeListItem;
