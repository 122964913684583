import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons';
import { Feira, Expositor, Container } from './styles';
import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import { useInteraction } from '../../hooks/Interactions';
import { useAuth } from '../../hooks/Auth';
import CheckinQR from '../DashboardPages/features/CheckinQR';
import Checkins from '../DashboardPages/pages/Checkins';
import CheckinsWithCredentials from '../DashboardPages/pages/CheckinsWithCredentials';
import CredentialPage from '../DashboardPages/pages/CredentialPage';

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  link_name: string;
  social: Record<string, any>;
}

const AreaExpositor: React.FC = () => {

  const { user } = useAuth();

  useEffect(() => {

    if (!user || (user.profile !== 9 && user.profile !== 8 && user.profile !== 5)) {

      window.location.href = '/';
    }

  }, [])

  const options = (profile) => {
    /* expositor */
    if (user.profile === 11) {
      return <Checkins />;
    }
    if (user.profile === 8) {
      return <CheckinQR />;
    }
    if (user.profile === 9) {
      return <CheckinsWithCredentials />;
    }
    if (user.profile === 10) {
      return <CredentialPage />;
    }

    return <h2 style={{ margin: '50px auto', background: '#333', color: '#fff', textAlign: 'center', padding: '30px' }}>Acesso não autorizado</h2>
  }

  return (
    <Container>
      {options(user?.profile)}
    </Container>
  );
};
export default AreaExpositor;
