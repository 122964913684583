import React from 'react';
import { IconType } from 'react-icons';
import { FiCopy } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import { useLanguage } from '../../hooks/Language';
import { useToast } from '../../hooks/Toast';
import { Button } from '../Button/styles';
import Whatsapp from '../Whatsapp';

// import { Container } from './styles';
interface IProps {
  text: string,
  Icon?: IconType
  message?: string;
}

const CopyToClipboard: React.FC<IProps> = ({ text, Icon = FiCopy, message = '' }) => {

  const { user } = useAuth();
  const { translate } = useLanguage();
  const { addToast } = useToast();
  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    addToast({ type: 'success', 'title': translate('Copiado para o Clipboard') })

  }



  return (
    <div className='lineIcon' style={{ cursor: 'pointer' }} title={translate("Clique para copiar")} onClick={() => copyToClipboard(text)}>
      <Icon size={30} color="rgb(0,15,64)" /> {message}
    </div>
  );
};

export default CopyToClipboard;
