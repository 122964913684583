import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaQrcode, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { projectHash, eventHash } = useParams<IParams>()
  const key = uuid();
  const { configuration } = useConfiguration();



  const formSchema = {
    cracha_have_qrcode: {
      model: 'selectSimple',
      name: 'cracha_have_qrcode',
      label: 'Tem QRCode?',

      options: [

        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ]

    },
    cracha_config: {
      model: 'jsonInputList',
      type: 'text',
      name: 'cracha_config',
      label: 'Campos do Crachá',
      show: ['position', 'field', 'size'],
      list: [
        {
          label: 'Linha', name: 'position', type: 'select', options: [
            { text: '1', label: '1', value: 1 },
            { text: '2', label: '2', value: 2 },
            { text: '3', label: '3', value: 3 },
            { text: '4', label: '4', value: 4 },
            { text: '5', label: '5', value: 5 },
            { text: '6', label: '6', value: 6 },
            { text: '7', label: '7', value: 7 },
            { text: '8', label: '8', value: 8 },
            { text: '9', label: '9', value: 9 },
          ]
        },
        {
          label: 'Campo', name: 'field', type: 'select', options: [
            { text: 'Nome', label: 'Nome', value: 'name' },
            { text: 'Empresa', label: 'Empresa', value: 'company' },
            { text: 'Cargo', label: 'Cargo', value: 'position' },
            { text: 'Categoria', label: 'Categoria', value: 'category_id' },
            { text: 'Cidade', label: 'Cidade', value: 'city' },
            { text: 'Estado', label: 'Estado', value: 'state' },
            { text: 'País', label: 'País', value: 'country' },
            { text: 'E-mail', label: 'E-mail', value: 'email' },
            { text: 'CNPJ', label: 'CNPJ', value: 'company_document_number' },
            { text: 'Coringa', label: 'Coringa', value: 'extra_1' },
          ]
        },

        {
          label: 'Tamanho da Fonte', name: 'size', type: 'select', options: [
            { text: '8', label: '8', value: '8px' },
            { text: '9', label: '9', value: '9px' },
            { text: '10', label: '10', value: '10px' },
            { text: '11', label: '11', value: '11px' },
            { text: '12', label: '12', value: '12px' },
            { text: '13', label: '13', value: '13px' },
            { text: '14', label: '14', value: '14px' },
            { text: '15', label: '15', value: '15px' },
            { text: '16', label: '16', value: '16px' },
            { text: '17', label: '17', value: '17px' },
            { text: '18', label: '18', value: '18px' },
            { text: '19', label: '19', value: '19px' },
            { text: '20', label: '20', value: '20px' },
            { text: '21', label: '21', value: '21px' },
            { text: '22', label: '22', value: '22px' },
            { text: '23', label: '23', value: '23px' },
            { text: '24', label: '24', value: '24px' },
            { text: '25', label: '25', value: '25px' },
            { text: '26', label: '26', value: '26px' },
            { text: '27', label: '27', value: '27px' },
            { text: '28', label: '28', value: '28px' },
            { text: '29', label: '29', value: '29px' },
            { text: '30', label: '30', value: '30px' },
            { text: '31', label: '31', value: '31px' },
            { text: '32', label: '32', value: '32px' },
            { text: '33', label: '33', value: '33px' },
            { text: '34', label: '34', value: '34px' },
            { text: '35', label: '35', value: '35px' },
            { text: '36', label: '36', value: '36px' },
            { text: '37', label: '37', value: '37px' },
            { text: '38', label: '38', value: '38px' },
            { text: '39', label: '39', value: '39px' },
            { text: '40', label: '40', value: '40px' },
            { text: '41', label: '41', value: '41px' },
            { text: '42', label: '42', value: '42px' },
          ]
        },
        {
          label: 'Margem superior', name: 'margin', type: 'select', options: [
            { text: '8', label: '8', value: '8px' },
            { text: '9', label: '9', value: '9px' },
            { text: '10', label: '10', value: '10px' },
            { text: '11', label: '11', value: '11px' },
            { text: '12', label: '12', value: '12px' },
            { text: '13', label: '13', value: '13px' },
            { text: '14', label: '14', value: '14px' },
            { text: '15', label: '15', value: '15px' },
            { text: '16', label: '16', value: '16px' },
            { text: '17', label: '17', value: '17px' },
            { text: '18', label: '18', value: '18px' },
            { text: '19', label: '19', value: '19px' },
            { text: '20', label: '20', value: '20px' },
            { text: '21', label: '21', value: '21px' },
            { text: '22', label: '22', value: '22px' },
            { text: '23', label: '23', value: '23px' },
            { text: '24', label: '24', value: '24px' },
            { text: '25', label: '25', value: '25px' },
            { text: '26', label: '26', value: '26px' },
            { text: '27', label: '27', value: '27px' },
            { text: '28', label: '28', value: '28px' },
            { text: '29', label: '29', value: '29px' },
            { text: '30', label: '30', value: '30px' },
            { text: '31', label: '31', value: '31px' },
            { text: '32', label: '32', value: '32px' },
            { text: '33', label: '33', value: '33px' },
            { text: '34', label: '34', value: '34px' },
            { text: '35', label: '35', value: '35px' },
            { text: '36', label: '36', value: '36px' },
            { text: '37', label: '37', value: '37px' },
            { text: '38', label: '38', value: '38px' },
            { text: '39', label: '39', value: '39px' },
            { text: '40', label: '40', value: '40px' },
            { text: '41', label: '41', value: '41px' },
            { text: '42', label: '42', value: '42px' },
          ]
        },
        {
          label: 'Negrito?', name: 'bold', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Máscara', name: 'mask', type: 'select', options: [
            { text: 'Primeiro e último nome', label: 'Primeiro e último nome', value: 'firstAndLast' },
            { text: 'Primeiro, segundo e último nome', label: 'Primeiro, segundo e último nome', value: 'firstSecondAndLast' },
          ]
        },
        {
          label: 'Separador', name: 'separator', type: 'select', options: [
            { text: '|', label: '|', value: '|' },
            { text: '-', label: '-', value: '-' },
          ]
        },
      ],
    }
  }



  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const newData = sanitizeDataObject(data, formSchema);

      const schema = createSchemaByForm({});
      await schema.validate(newData, { abortEarly: false });
      const column = Object.keys(data)[0];

      if (formSchema?.[column]?.type && formSchema[column].type === 'upload') {
        const formData = new FormData();
        formData.append('file', data[column]);

        const response = await api.put(
          `projects/${configuration._id}/${column}`,
          formData,
        );
        if (response.status !== 200) {
          return addToast({ type: 'error', title: 'Erro ao atualizar' });
        }
      } else {
        const response = await api.put(
          `projects/${configuration._id}/${column}`,
          newData,
        );
        if (response.status !== 200) {
          return addToast({ type: 'error', title: 'Erro ao atualizar' });
        }
      }

      // removeModal(key);
      reloadSearchAll();

      addToast({ type: 'success', title: 'Atualizado com sucesso' });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      return addToast({ type: 'error', title: 'erro' });
    }
  };

  const handleUpdate = async (): Promise<void> => {
    const response = await api.get(`projects/${configuration._id}`);
    const { data } = response;

    const content = (
      <FormComponent
        dataInfo={data}
        schema={formSchema}
        setCallback={setCallback}
      />
    );
    if (content) {
      const keyModal = addModal({ title: 'Gerenciar Crachá', content, key, theme: "whiteModal" });
    }
  };


  const moduleCreate = (): JSX.Element => (
    user?.profile === 1 && projectHash ? <button className="searchButton" type="button" onClick={() => handleUpdate()}>
      <FaQrcode />
    </button> : <></>
  );

  return moduleCreate();
}
