import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const Account: React.FC = () => {
  const endpoint = '/accounts';
  const title = 'Contas';
  const formSchema = {

    name: {
      model: 'input',
      name: 'name',
      label: 'Título',
      defaultValue: '',
    },
    email: {
      model: 'input',
      name: 'email',
      label: 'E-mail',
      defaultValue: '',
    },
    company: {
      model: 'input',
      name: 'company',
      label: 'Empresa',
      defaultValue: '',
    },
    company_document_number: {
      model: 'maskedInput',
      name: 'company_document_number',
      label: 'CNPJ',
      mask: '99.999.999/9999-99',
      defaultValue: '',
    },
  };


  const formSchemaUpdate = {

    ...formSchema
  };
  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },

    name: { ref: 'name', column: 'name', label: 'Responsável' },
    email: { ref: 'email', column: 'email', label: 'E-mail do responsável' },
    company: { ref: 'company', column: 'company', label: 'Empresa' },
    company_document_number: { ref: 'company_document_number', column: 'company_document_number', label: 'CNPJ' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };



  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default Account;
