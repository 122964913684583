import React from 'react';
import FadeInOnScroll from '../../../../../../components/FadeInOnScroll';
import ImageGallery from '../../../../../../components/ImageGallery';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import { ParagraphHighlightUI, TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import ImageGalleryFlowComponent from '../../modules/imageGalleryFlowComponent';
import RenderPageList from '../../modules/RenderList';
import RenderPageImages from "../../modules/RenderPageImages";

const ImageGalleryFlowPageTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;

  return (
    <>

      <ContainerLineUI className=' full-width' id={hash} style={{ display: 'flex', padding: '100px 0px', zIndex: 1000, alignItems: 'center', flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : '#fff' }} >
        {pageContent?.theme_data?.hide_side_content !== 'yes' ? <div className='column full-width  gap' >
          <div className='column full-width  max-600' >
            {pageContent?.theme_data?.show_title !== 'no' ? <FadeInOnScroll><TitleHighlightUI color={pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333'} background={pageContent?.theme_data?.title_background ? pageContent?.theme_data?.title_background : ''} style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: pageContent?.title }} /></FadeInOnScroll> : <></>}
            <FadeInOnScroll><ParagraphHighlightUI style={{ marginTop: '15px' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} className='midTitle max-600 text-center' dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} /></FadeInOnScroll>
          </div>
          {pageContent?.images ? <ImageGalleryFlowComponent data={pageContent?.images} /> : <></>}


          {pageContent?.buttons?.length > 0 ? <div style={{ margin: '0px auto' }}>
            {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
              return <a href={`${itemButton.link}`} ><FadeInOnScroll><button className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title}</button></FadeInOnScroll></a>
            })}
          </div> : <></>}
        </div> : <></>}
      </ContainerLineUI>
    </>
  );


}

export default ImageGalleryFlowPageTemplate;