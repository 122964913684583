import React, { useState, useEffect } from 'react';

import {
  FaPlus,
  FaTrash,
  FaQuestion,
  FaList,
  FaChartPie,
  FaEye,
  FaStickyNote,
  FaBroom,
} from 'react-icons/fa';
import { FiX, FiSend, FiSidebar } from 'react-icons/fi';
import { Container, Field, QuizzHeader, Report } from './styles';
import { Modal } from '../../../../../ClassRoomContainer/styles';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';

import api from '../../../../../../services/api';
import { useAuth } from '../../../../../../hooks/Auth';

import { useSocket } from '../../../../../../hooks/Socket';
import Loader from '../../../../../Loader';
import { useLanguage } from '../../../../../../hooks/Language';

interface IID {
  roomNumber: string;
  active?: boolean;
  activeList?: boolean;
  setOption: React.Dispatch<React.SetStateAction<string>>;
}

interface IAnswers {
  name: string;
  value?: string;
  label?: string;
}

interface IQuestions {
  _id?: string;
  title: string;
  type: number;
  questions: Array<Record<string, any>>;
}

interface IVoter {
  name?: string;
  email?: string;
}

interface IVoterReport {
  voted: Array<IVoter>;
  notVoted: Array<IVoter>;
}

interface IReceiver {
  roomNumber: string;
}

export const QuizContainerReceiver: React.FC<IReceiver> = ({ roomNumber }) => {
  const { socket } = useSocket();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();
  const createID = () => `p${new Date().getUTCMilliseconds()}`;
  let selectedOptionVote: Array<string> = [];
  const [votation, setVotation] = useState<JSX.Element>();
  const [votationActive, setVotationActive] = useState(false);

  const [reportVotation, setReportVotation] = useState<JSX.Element>();
  const [reportVotationActive, setReportVotationActive] = useState(false);


  const { addToast } = useToast();


  const readReport = report => {

    const counter = report.report.reduce((prev, con) => {
      prev += con.count;

      return prev;
    }, 0);

    return (
      <>
        <p>{translate("Pergunta")}</p>
        <h2>{report.title}</h2>
        <Field>
          <div className="voteOption">
            <button
              className="iconButton gradientBg"
              style={{ marginRight: '5px', padding: '3px', width: 'auto' }}
              type="button"
            >
              {report.users}
            </button>
            {' '}
            <span>{translate("Usuários participaram")}</span>
          </div>
        </Field>
        <p>Resultados</p>
        {report.report.map(quest => {
          const percentage = (quest.count / counter) * 100 || 0;
          return (
            <Field>
              <div className="voteOption">
                <button
                  className="iconButton  gradientBg"
                  style={{ marginRight: '5px', padding: '3px', width: 'auto', minWidth: '100px' }}
                  type="button"
                >
                  {percentage.toFixed(2)} %
                </button>
                {' '}
                <span style={{ fontSize: '12px' }}>{quest.title}</span>
              </div>
            </Field>
          );
        })}
      </>
    );
  };

  const readQuizzReport = (data) => {
    if (data && data.data) {
      const Item = readReport(data.data);
      setReportVotation(Item);
      setReportVotationActive(true);
    }
  }

  const selectOption = (questionObj, value: string) => {
    if (questionObj.type === 1) {
      const vote: Array<string> = [];
      vote.push(value);
      selectedOptionVote = vote;
    } else {
      const index = selectedOptionVote.findIndex(select => select === value);

      if (index >= 0) {
        const votes = [...selectedOptionVote];
        votes.splice(index, 1);
        selectedOptionVote = votes || [];
      } else {
        selectedOptionVote = [...selectedOptionVote, value];
      }
    }

    read(questionObj);
  };

  const sendVote = async id => {
    if (selectedOptionVote.length < 1) {
      return addToast({
        type: 'error',
        title: translate('Vote em ao menos 1 das alternativas'),
      });
    }

    const data = {
      answers: selectedOptionVote,
      id,
    };
    try {
      const response = await api.post('/quizz_answer', data);
      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao votar' });
      }

      setVotationActive(false);

      addToast({ type: 'success', title: 'Enviado com sucesso' });
    } catch (err) {
      return addToast({ type: 'error', title: err.response.data.message });
    }
  };

  const checkVoteSelected = value => {
    const index = selectedOptionVote.findIndex(select => select === value);

    if (index >= 0) {
      return true;
    }
    return false;
  };



  const read = questionObj => {

    return (
      <>
        <p>Pergunta</p>
        <h2>{questionObj.title}</h2>
        <p>Opções</p>
        {questionObj.questions.map(quest => {
          const checked = checkVoteSelected(quest.value);

          return (
            <Field>
              <div className="voteOption">
                <input style={{ width: '20px', minWidth: '20px' }}
                  name="option"
                  defaultChecked={checked}
                  type={questionObj.type === '1' ? 'radio' : 'checkbox'}
                  onClick={() => selectOption(questionObj, quest.value)}
                  className="iconButton  gradientBg"
                  value={quest.value}
                />
                {' '}
                <span>{quest.value}</span>
              </div>
            </Field>
          );
        })}

        <button
          type="button"
          onClick={() => sendVote(questionObj._id)}
          className="actionAdminButton"
        >
          {translate("Enviar")}
        </button>
      </>
    );
  };


  const getQuestionToVote = async question => {
    selectedOptionVote = [];
    setVotation(<></>);
    setVotationActive(false);

    if (question) {
      setVotation(read(question));
      setVotationActive(true);
    }
  };

  useEffect(() => {

    socket?.off('startQuizz');
    socket?.on('startQuizz', data => {


      if (data && data.id) {
        getQuestionToVote(data.id);
      }
    });
    socket?.off('showQuizzReport');
    socket?.on('showQuizzReport', data => {
      if (data) {
        readQuizzReport(data);
      }
    });


  }, [roomNumber]);




  return <>
    {votationActive && (
      <Modal>
        <div>{votation}</div>
        <button
          className="closeAdminButton"
          type="button"
          onClick={() => setVotationActive(false)}
          style={{ marginTop: '25px' }}
        >
          Fechar
        </button>
      </Modal>
    )}

    {reportVotationActive && (
      <Modal>
        <div>{reportVotation}</div>
        <button
          className="closeAdminButton"
          type="button"
          onClick={() => setReportVotationActive(false)}
          style={{ marginTop: '25px' }}
        >
          Fechar
        </button>
      </Modal>
    )}


  </>
}

const QuizzComponent: React.FC<IID> = ({ roomNumber = 0,
  active = false,
  activeList = false,
  setOption

}) => {
  const { socket } = useSocket();
  const { user, handleApiErrors } = useAuth();
  const [room_id, setRoom_id] = useState(roomNumber || 0);
  const { translate } = useLanguage();
  useEffect(() => {
    socket?.off('startQuizz');
    socket?.on('startQuizz', data => {


      if (data && data.id) {
        getQuestionToVote(data.id);
      }
    });
    socket?.off('showQuizzReport');
    socket?.on('showQuizzReport', data => {
      if (data) {
        readQuizzReport(data);
      }
    });


  }, []);

  useEffect(() => {
    setRoom_id(roomNumber);
  }, [roomNumber]);

  const { addToast } = useToast();

  const createID = () => `p${new Date().getUTCMilliseconds()}`;
  let selectedOptionVote: Array<string> = [];
  const [votation, setVotation] = useState<JSX.Element>();
  const [votationActive, setVotationActive] = useState(false);

  const [reportVotation, setReportVotation] = useState<JSX.Element>();
  const [reportVotationActive, setReportVotationActive] = useState(false);

  const [deleteItem, setDeleteItem] = useState<JSX.Element>();
  const [deleteItemActive, setDeleteItemActive] = useState(false);

  const [questionList, setQuestionList] = useState<Array<IQuestions>>([]);
  const [questionListActive, setQuestionListActive] = useState(false);

  const [question, setQuestion] = useState('');
  const [room, setRoom] = useState(room_id || 0);
  const [type, setType] = useState(1);

  const [answers, setAnswers] = useState<Array<IAnswers>>([
    { name: createID(), label: '', value: '' },
  ]);

  const { addModal } = useModal();

  useEffect(() => {

    setReportVotationActive(false)
    setDeleteItemActive(false)
    setQuestionListActive(false);
    setVotationActive(false)


  }, [room_id])

  /* EXCLUI UMA ENQUETE */

  const askDeleteQuizz = id => {
    const item = (
      <>
        <p />
        <h2 style={{ textAlign: 'center' }}>Confirmar exclusão?</h2>
        <div className='actionDiv'>
          <button
            type="button"
            onClick={() => setDeleteItemActive(false)}
            className="actionAdminButton"
          >
            Não
          </button>
          <button
            type="button"
            onClick={() => deleteQuizz(id)}
            className="actionAdminButton"
          >
            Sim
          </button>
        </div>
      </>
    );

    setDeleteItemActive(true);
    setDeleteItem(item);
  };

  const deleteQuizz = async id => {
    const response = await api.delete(`/quizz/${id}`);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: translate('Erro ao remover') });
    }

    listQuestions(room_id);

    setDeleteItemActive(false);
    setDeleteItem(<></>);
    return addToast({ type: 'success', title: translate('Removido com sucesso') });
  };

  /* ENVIA PARA OS PARTICIPANTES */
  const sendQuizzToParticipants = (id, room_id) => {

    socket?.emit('sendQuizz', { id, room_id });
  };

  /* RECUPERA O RELATÓRIO */

  const readReport = report => {

    const counter = report.report.reduce((prev, con) => {
      prev += con.count;

      return prev;
    }, 0);

    return (
      <>
        <p>{translate("Pergunta")}</p>
        <h2>{report.title}</h2>
        <Field>
          <div className="voteOption">
            <button
              className="iconButton gradientBg"
              style={{ marginRight: '5px', padding: '3px', width: 'auto' }}
              type="button"
            >
              {report.users}
            </button>
            {' '}
            <span>{translate("Usuários participaram")}</span>
          </div>
        </Field>
        <p>Resultados</p>
        {report.report.map(quest => {
          const percentage = (quest.count / counter) * 100 || 0;
          return (
            <Field>
              <div className="voteOption">
                <button
                  className="iconButton  gradientBg"
                  style={{ marginRight: '5px', padding: '3px', width: 'auto', minWidth: '100px' }}
                  type="button"
                >
                  {percentage.toFixed(2)} %
                </button>
                {' '}
                <span style={{ fontSize: '12px' }}>{quest.title}</span>
              </div>
            </Field>
          );
        })}
      </>
    );
  };

  const getReport = async (id, type = 1) => {
    selectedOptionVote = [];
    setVotation(<></>);
    setVotationActive(false);
    try {
      const response = await api.get(`/quizz_answer_report/${id}`);
      if (response.status !== 200) {
        return addToast({ type: 'error', title: translate('Erro ao recuperar resultados') });
      }

      if (type === 1) {

        readQuizzReport(response);
      }
      else {

        if (response) {
          socket?.emit('sendQuizzReport', { room_id, data: response.data })
        }
      }
    } catch (err) {
      handleApiErrors(err);
    }

  };

  const readQuizzReport = (data) => {
    if (data && data.data) {
      const Item = readReport(data.data);
      setReportVotation(Item);
      setReportVotationActive(true);
    }
  }

  /* LISTAGEM DE ENQUETES */

  const listQuestions = async idRef => {

    try {
      const response = await api.get('/quizz', {
        params: { where: { room_id: idRef } },
      });


      if (response.status !== 200) {
        return addToast({ type: 'error', title: translate('Erro ao pesquisar') });
      }

      if (response) {
        setQuestionList(response.data.rows || []);
        setQuestionListActive(true);

        setOption('');
      }
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  /* GERAR SELEÇÃO */

  const checkVoteSelected = value => {
    const index = selectedOptionVote.findIndex(select => select === value);

    if (index >= 0) {
      return true;
    }
    return false;
  };

  const read = questionObj => {

    return (
      <>
        <p>Pergunta</p>
        <h2>{questionObj.title}</h2>
        <p>Opções</p>
        {questionObj.questions.map(quest => {
          const checked = checkVoteSelected(quest.value);

          return (
            <Field>
              <div className="voteOption">
                <input style={{ width: '20px', minWidth: '20px' }}
                  name="option"
                  defaultChecked={checked}
                  type={questionObj.type === '1' ? 'radio' : 'checkbox'}
                  onClick={() => selectOption(questionObj, quest.value)}
                  className="iconButton  gradientBg"
                  value={quest.value}
                />
                {' '}
                <span>{quest.value}</span>
              </div>
            </Field>
          );
        })}

        <button
          type="button"
          onClick={() => sendVote(questionObj._id)}
          className="actionAdminButton"
        >
          Enviar
        </button>
      </>
    );
  };

  const selectOption = (questionObj, value: string) => {
    if (questionObj.type === 1) {
      const vote: Array<string> = [];
      vote.push(value);
      selectedOptionVote = vote;
    } else {
      const index = selectedOptionVote.findIndex(select => select === value);

      if (index >= 0) {
        const votes = [...selectedOptionVote];
        votes.splice(index, 1);
        selectedOptionVote = votes || [];
      } else {
        selectedOptionVote = [...selectedOptionVote, value];
      }
    }

    read(questionObj);
  };

  const sendVote = async id => {
    if (selectedOptionVote.length < 1) {
      return addToast({
        type: 'error',
        title: translate('Vote em ao menos 1 das alternativas'),
      });
    }

    const data = {
      answers: selectedOptionVote,
      id,
    };
    try {
      const response = await api.post('/quizz_answer', data);
      if (response.status !== 200) {
        return addToast({ type: 'error', title: translate('Erro ao votar') });
      }

      setVotationActive(false);

      addToast({ type: 'success', title: translate('Enviado com sucesso') });
    } catch (err) {
      return addToast({ type: 'error', title: err.response.data.message });
    }
  };

  const getQuestionToVote = async question => {
    selectedOptionVote = [];
    setVotation(<></>);
    setVotationActive(false);

    if (question) {
      setVotation(read(question));
      setVotationActive(true);
    }
  };

  const addAnswer = () => {
    const newID = createID();
    setAnswers([...answers, { name: newID, label: '', value: '' }]);
  };

  const updateAnswerValue = (key, value) => {
    const index = answers.findIndex(answer => answer.name === key);

    if (index >= 0) {
      const newValue = [...answers];
      newValue[index].value = value;

      setAnswers([...newValue]);
    }
  };

  const removeAnswer = (key: string) => {
    const { length } = answers;
    if (length >= 1) {
      const index = answers.findIndex(answer => answer.name === key);

      if (index >= 0) {
        const newValue = [...answers];
        newValue.splice(index, 1);



        setAnswers([...newValue]);
      }
    }
  };

  const addQuestion = () => {
    setQuestion('');
    setAnswers([{ name: createID(), label: '', value: '' }]);
    setType(1);
    setQuestionListActive(false);
    setOption('createQuizz');
  };

  const send = async (send = false) => {
    if (!question) {
      return addToast({ type: 'error', title: translate('Campo Pergunta é obrigatório') });
    }

    const data = {
      title: question,
      room_id,
      questions: answers,
      type,
    };
    try {
      const response = await api.post('/quizz', data);
      if (response.status !== 200) {
        return addToast({ type: 'error', title: translate('Erro ao salvar') });
      }

      setOption('');

      addToast({ type: 'success', title: translate('Salvo com sucesso') });

      if (send === true) {
        sendQuizzToParticipants(response.data, room_id);
      }
    } catch (err) {
      return addToast({ type: 'error', title: err.response.data.error });
    }
  };

  const [voters, setVoters] = useState<IVoterReport>({
    voted: [],
    notVoted: [],
  })
  const [loading, setLoading] = useState(false);
  const [activeVoters, setActiveVoters] = useState(false);
  const [currentVoterRoom, setCurrentVoterRoom] = useState(0);

  const closeVoters = () => {

    setActiveVoters(false);
    setVoters({
      voted: [],
      notVoted: [],
    });
    setCurrentVoterRoom(0);

  }

  const reportVoters = () => {

    const total = voters.voted.length + voters.notVoted.length;
    const votedPercentage = ((voters.voted.length / total) * 100).toFixed(2);
    const notVotedPercentage = (100 - parseFloat(votedPercentage)).toFixed(2);
    return <div style={{ width: '100%', maxWidth: '100%' }}>
      <button
        className="closeAdminButton"
        type="button"
        onClick={() => closeVoters()}
        style={{ marginBottom: '25px' }}
      >
        Fechar
      </button>
      <div className="votersHeaderContainer">
        <p className="voted"> <p><strong>Votaram: </strong> : {voters.voted.length} </p><strong style={{ fontSize: '12px' }}>({votedPercentage}%)</strong></p>
        <p className="notVoted"> <p><strong>Não Votaram: </strong> : {voters.notVoted.length}</p> <strong style={{ fontSize: '12px' }}>({notVotedPercentage}%) </strong></p>
        <button className="voted" onClick={() => getVoters(currentVoterRoom)}> {translate('Atualizar')}</button>
      </div>
      <div className="votersContainer">
        <div className="voterContainer">
          <h2>{translate("Votaram")}</h2>
          {voters.voted.map(v => {
            return <p>{v.name}</p>
          })}

        </div>
        <div className="voterContainer">
          <h2>{translate("Não Votaram")}</h2>
          {voters.notVoted.map(v => {
            return <p>{v.name}</p>
          })}

        </div>
      </div>
    </div>
  }

  const getVoters = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/voters/${id}`);
      if (response.status !== 200) {
        setLoading(false);
        return addToast({ type: 'error', title: translate('Erro ao salvar') });

      }

      setVoters(response.data);
      setCurrentVoterRoom(id);
      setActiveVoters(true);
      setLoading(false);
    }
    catch (err) {
      handleApiErrors(err);
    }
  }

  const clearQuizz = async id => {
    const response = await api.delete(`/answers/${id}`);
    if (response.status !== 200) {
      return addToast({ type: 'error', title: translate('Erro ao remover') });
    }

    listQuestions(room_id);

    setDeleteItemActive(false);
    setDeleteItem(<></>);
    return addToast({ type: 'success', title: translate('Limpo com sucesso') });
  };

  const askClearQuizz = id => {
    const item = (
      <>
        <p />
        <h2 style={{ textAlign: 'center' }}>{translate("Confirmar Limpar respostas")}?</h2>
        <div className='actionDiv'>
          <button
            type="button"
            onClick={() => setDeleteItemActive(false)}
            className="actionAdminButton"
          >
            {translate("Não")}
          </button>
          <button
            type="button"
            onClick={() => clearQuizz(id)}
            className="actionAdminButton"
          >
            {translate("Sim")}
          </button>
        </div>
      </>
    );

    setDeleteItemActive(true);
    setDeleteItem(item);
  };

  useEffect(() => {
    if (activeList) {
      listQuestions(room_id)
    }
  }, [room_id, activeList])

  return (
    <Container>
      {loading && <Loader message={translate("Atualizando")} />}
      {activeVoters && <Modal style={{ zIndex: 2500, maxWidth: '100%' }}><Report> {reportVoters()}</Report></Modal>}

      {active && (
        <Modal style={{ justifyContent: 'flex-start', paddingTop: '30px' }}>
          <div>
            <h2>{translate("Criar Pergunta")}</h2>
            <Field>
              <label>{translate("Pergunta")}</label>
              <textarea
                onChange={e => setQuestion(e.target.value)}
                value={question}
                placeholder={translate("Pergunta")}
              />
            </Field>

            <Field className="field">
              <label onClick={() => addAnswer()}>
                {translate("Respostas")}
                {' '}
                <button className="iconButton  gradientBg" type="button">
                  <FaPlus />
                </button>
              </label>
              {answers.map(ans => (
                <div>
                  {' '}
                  <input
                    type="text"
                    name={ans.name}
                    value={ans.value}
                    onChange={e => updateAnswerValue(ans.name, e.target.value)}
                    placeholder={translate("Resposta")}
                  />
                  <button
                    className="iconButton iconTrash"
                    type="button"
                    onClick={() => removeAnswer(ans.name)}
                  >
                    <FaTrash />
                  </button>

                </div>
              ))}
            </Field>

            <Field  >
              <label>Múltipla Escolha?</label>
              <select onChange={e => setType(parseInt(e.target.value))}>
                <option selected={type === 1} value="1">
                  {translate("Não")}
                </option>
                <option selected={type === 2} value="2">
                  {translate("Sim")}
                </option>
              </select>
            </Field>

            <div className='actionDiv'>
              <button type="button" className='actionAdminButton' onClick={() => send(false)}>
                {translate("Salvar")}
              </button>
              <button type="button" className='actionAdminButton' onClick={() => send(true)}>
                {translate("Salvar e enviar")}
              </button>
            </div>
          </div>
          <button
            className="closeAdminButton" style={{ marginTop: '25px', alignSelf: 'center' }}
            type="button"
            onClick={() => setOption('')}
          >
            {translate("Fechar")}
          </button>
        </Modal>
      )}


      {questionListActive && (
        <Modal>

          <div>
            <h2>Enquetes</h2>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                {questionList.map(quest => (
                  <tr style={{ borderBottom: '2px solid #333' }}>
                    <td style={{ padding: '10px' }}>
                      <div> {quest.title} </div>
                      <div className="ListIcons" style={{ display: 'flex' }}>

                        {user.profile === 2 && <p style={{ marginRight: '5px', marginTop: '10px' }}>{translate("Clique no ícone para visualizar")}: </p>}
                        <button
                          title={translate("Visualizar Pergunta")}
                          onClick={() => getQuestionToVote(quest)}
                          type="button"
                          className="iconButton "
                        >
                          <FaEye />
                        </button>

                        {(user.profile === 1 || user.profile === 3 || user.profile === 4 || user.profile === 6 || user.profile === 9) &&
                          <>
                            <button
                              title={translate("Ver Resultado")}
                              onClick={() => getReport(quest._id, 1)}
                              type="button"
                              className="iconButton "
                            >
                              <FaChartPie />
                            </button>

                            <button
                              style={{ background: 'rgb(0,100,200)', color: '#fff' }}
                              title={translate("Enviar resultado para todos")}
                              onClick={() => getReport(quest._id, 2)}
                              type="button"
                              className="iconButton gradientBg"
                            >
                              <FaChartPie />
                            </button>
                            <button
                              title={translate("Listar Votantes")}
                              onClick={() => getVoters(quest._id)}
                              type="button"
                              className="iconButton "
                            >
                              <FiSidebar />
                            </button>



                            <button
                              title={translate("Excluir")}
                              onClick={() => askDeleteQuizz(quest._id)}
                              type="button"
                              className="iconButton  danger"
                            >
                              <FaTrash />
                            </button>

                            <button
                              title={translate("Limpar")}
                              onClick={() => askClearQuizz(quest._id)}
                              type="button"
                              className="iconButton  danger"
                            >
                              <FaBroom />
                            </button>

                            <button title={translate('Enviar')}
                              onClick={() =>
                                sendQuizzToParticipants(quest, room_id)}
                              type="button" style={{ maxWidth: 'auto' }}
                              className="iconButton gradientBg"
                            >
                              <FiSend />  </button></>}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="closeAdminButton"
            type="button"
            onClick={() => setQuestionListActive(false)}
            style={{ marginTop: '25px' }}
          >
            {translate("Fechar")}
          </button>
        </Modal>
      )}

      {votationActive && (
        <Modal>
          <div>{votation}</div>
          <button
            className="closeAdminButton"
            type="button"
            onClick={() => setVotationActive(false)}
            style={{ marginTop: '25px' }}
          >
            {translate("Fechar")}
          </button>
        </Modal>
      )}

      {reportVotationActive && (
        <Modal>
          <div>{reportVotation}</div>
          <button
            className="closeAdminButton"
            type="button"
            onClick={() => setReportVotationActive(false)}
            style={{ marginTop: '25px' }}
          >
            {translate("Fechar")}
          </button>
        </Modal>
      )}

      {deleteItemActive && (
        <Modal>
          <div>{deleteItem}</div>
          <button
            className="closeAdminButton"
            type="button"
            onClick={() => setDeleteItemActive(false)}
            style={{ marginTop: '25px' }}
          >
            {translate("Fechar")}
          </button>
        </Modal>
      )}
    </Container>
  );
};

export default QuizzComponent;
