
import React from 'react';
import { FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import EventsPages from '../Paginas';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const CustomPage = ({ hash }) => {



    return <EventsPages hash={hash} />

}

export default CustomPage;