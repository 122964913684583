import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CSSProperties } from 'react';
import api from 'src/services/api';
import { useToast } from 'src/hooks/Toast';

export interface IStyle {
  computer?: Record<string, any>;
  mobile?: Record<string, any>;
}

export interface IContentItem {
  type: string;
  value?: any;
  style?: {
    computer?: Record<string, any>;
    mobile?: Record<string, any>;
  };
}

export interface IRow {
  content: IContentItem[];
  style?: {
    computer?: Record<string, any>;
    mobile?: Record<string, any>;
  };
}

export interface ISection {
  rows: IRow[];
  style?: {
    computer?: Record<string, any>;
    mobile?: Record<string, any>;
  };
}

export interface IContainer {
  type: 'single' | 'double' | 'triple';
  sections: ISection[];
  style?: {
    computer?: Record<string, any>;
    mobile?: Record<string, any>;
  };
  name?: string;
}

export interface IPageCreatorContext {
  containers: IContainer[];

  addContainer: (type: 'single' | 'double' | 'triple') => void;
  addRow: (containerIndex: number, sectionIndex: number) => void;
  addContent: (containerIndex: number, sectionIndex: number, rowIndex: number, contentItem: IContentItem) => void;
  moveContainerUp: (index: number) => void;
  moveContainerDown: (index: number) => void;
  removeContainer: (containerIndex: number) => void;
  moveSectionUp: (containerIndex: number, sectionIndex: number) => void;
  moveSectionDown: (containerIndex: number, sectionIndex: number) => void;
  removeSection: (containerIndex: number, sectionIndex: number) => void;

  moveRowUp: (containerIndex: number, sectionIndex: number, rowIndex: number) => void;
  moveRowDown: (containerIndex: number, sectionIndex: number, rowIndex: number) => void;
  removeRow: (containerIndex: number, sectionIndex: number, rowIndex: number) => void;
  moveContentUp: (containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number) => void;
  moveContentDown: (containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number) => void;
  removeContent: (containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number) => void;

  updateContainerName: (containerIndex: number, name: string) => void;
  updateStyle: (indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }, style: IStyle, device: 'computer' | 'mobile') => void;
  updateContent: (indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }, value: any, type: string) => void;

  page: Record<string, any>;
  changePage: Function;
  saveContent: Function;
  currentLanguage: string;
  changeLanguage(language: string): void;

}

interface IProps {
  content: Record<string, any>;
}

const PageCreatorContext = createContext<IPageCreatorContext | undefined>(undefined);

export const PageCreatorProvider: React.FC<IProps> = ({ content, children }) => {
  const { addToast } = useToast();
  const [currentLanguage, setCurrentLanguage] = useState('ptBr');
  const [page, setPage] = useState<Record<string, any>>({});

  const [containers, setContainers] = useState<IContainer[]>([
  ]);

  const changeLanguage = (language) => {
    setCurrentLanguage(language)
  }

  useEffect(() => {

    if (content?.content) {
      setPage(content?.content?.page || {});
      setContainers(content?.content?.containers || []);
    }



  }, [content])

  const changePage = (column, value) => {
    setPage((currentPageState) => {

      const newPageState = { ...currentPageState };
      newPageState[column] = value;
      return newPageState;

    })
  }

  const addContainer = (type: 'single' | 'double' | 'triple') => {
    const newContainer: IContainer = {
      type,
      sections: type === 'single'
        ? [{ rows: [{ content: [] }] }]
        : type === 'double'
          ? [{ rows: [{ content: [] }] }, { rows: [{ content: [] }] }]
          : [{ rows: [{ content: [] }] }, { rows: [{ content: [] }] }, { rows: [{ content: [] }] }],
      name: '',
      style: {
        computer: {
          'padding-top': '100px',
          'padding-bottom': '100px'
        }
      }
    };
    setContainers(prev => [...prev, newContainer]);

  };

  const addRow = useCallback((containerIndex: number, sectionIndex: number) => {

    const newContainers = [...containers];
    newContainers[containerIndex].sections[sectionIndex].rows.push({ content: [] });

    newContainers[containerIndex].sections[sectionIndex].rows.push({ content: [] });

    setContainers(newContainers);


  }, [containers]);

  const addContent = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number, contentItem: IContentItem) => {
    const newContainers = [...containers];
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content.push(contentItem);

    setContainers(newContainers);

  }, [containers]);

  const moveContainer = (containersItems: IContainer[], index: number, move = -1) => {

    const newContainers = [...containersItems];
    const temp = newContainers[index + move];
    newContainers[index + move] = newContainers[index];
    newContainers[index] = temp;
    return newContainers;

  }

  const moveContainerUp = useCallback((index: number) => {
    if (index <= 0) { return }
    setContainers(() => moveContainer(containers, index, -1));

    return;
  }, [containers]);

  const moveContainerDown = useCallback((index: number) => {
    const newContainers = [...containers];
    if (index === newContainers.length - 1) return newContainers;

    setContainers(() => moveContainer(containers, index, +1));

  }, [containers]);

  const removeContainer = useCallback((containerIndex: number) => {
    const newContainers = [...containers];
    newContainers.splice(containerIndex, 1);



    setContainers(newContainers);

    return;
  }, [containers]);


  const moveSection = (containersItems: IContainer[], containerIndex: number, sectionIndex: number, move = -1) => {

    const newContainers = [...containersItems];
    const temp = newContainers[containerIndex].sections[sectionIndex + move];
    newContainers[containerIndex].sections[sectionIndex + move] = newContainers[containerIndex].sections[sectionIndex];
    newContainers[containerIndex].sections[sectionIndex] = temp;
    return newContainers;

  }

  const moveSectionUp = useCallback((containerIndex: number, sectionIndex: number) => {
    const newContainers = [...containers];
    if (sectionIndex === 0) return newContainers;


    setContainers(() => moveSection(containers, containerIndex, sectionIndex, -1));

    return;
  }, [containers]);

  const moveSectionDown = useCallback((containerIndex: number, sectionIndex: number) => {
    const newContainers = [...containers];
    if (sectionIndex === newContainers[containerIndex].sections.length - 1) return newContainers;
    setContainers(() => moveSection(containers, containerIndex, sectionIndex, +1));

    return;
  }, [containers]);

  const removeSection = useCallback((containerIndex: number, sectionIndex: number) => {
    const newContainers = [...containers];
    newContainers[containerIndex].sections.splice(sectionIndex, 1);

    setContainers(newContainers);


    return;
  }, [containers]);


  const moveRow = (containersItems: IContainer[], containerIndex: number, sectionIndex: number, rowIndex: number, move = -1) => {

    const newContainers = [...containersItems];
    const temp = newContainers[containerIndex].sections[sectionIndex].rows[rowIndex + move];
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex + move] = newContainers[containerIndex].sections[sectionIndex].rows[rowIndex];
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex] = temp;

    return newContainers;

  }

  const moveRowUp = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number) => {
    const newContainers = [...containers];
    if (rowIndex === 0) return newContainers;
    setContainers(() => moveRow(containers, containerIndex, sectionIndex, rowIndex, -1));


    return;
  }, [containers]);

  const moveRowDown = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number) => {
    const newContainers = [...containers];

    if (rowIndex === newContainers[containerIndex].sections[sectionIndex].rows.length - 1) return newContainers;

    setContainers(() => moveRow(containers, containerIndex, sectionIndex, rowIndex, +1));

    return;
  }, [containers]);

  const removeRow = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number) => {
    const newContainers = [...containers];
    newContainers[containerIndex].sections[sectionIndex].rows.splice(rowIndex, 1);

    setContainers(newContainers);


    return;
  }, [containers]);


  const moveContent = (containersItems: IContainer[], containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number, move = -1) => {

  }

  const moveContentUp = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number) => {

    if (contentIndex === 0) { return };

    const newContainers = [...containers];
    const temp = newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content?.[contentIndex - 1];
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex - 1] = { ...newContainers[containerIndex].sections[sectionIndex].rows?.[rowIndex].content?.[contentIndex] };
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex] = { ...temp };
    setContainers(newContainers);

    return
  }, [containers]);

  const moveContentDown = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number) => {
    const newContainers = [...containers];

    if (contentIndex === newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content?.length) {

      return;
    }

    const temp = newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content?.[contentIndex + 1];
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex + 1] = { ...newContainers[containerIndex].sections[sectionIndex].rows?.[rowIndex].content?.[contentIndex] };
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex] = { ...temp };
    setContainers(newContainers);
    return
  }, [containers]);

  const removeContent = useCallback((containerIndex: number, sectionIndex: number, rowIndex: number, contentIndex: number) => {

    const newContainers = [...containers];
    newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content.splice(contentIndex, 1);

    setContainers(newContainers);



  }, [containers]);

  const updateContainerName = (containerIndex: number, name: string) => {
    setContainers(prev => {
      const newContainers = [...prev];
      newContainers[containerIndex].name = name;
      return newContainers;
    });
  };

  const updateElementStyle = (containerItems: IContainer[], indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }, style: IStyle, device: 'computer' | 'mobile') => {

    const newContainers = [...containerItems];
    const { containerIndex, sectionIndex, rowIndex, contentIndex } = indices;

    if (containerIndex !== undefined) {
      if (sectionIndex !== undefined) {
        if (rowIndex !== undefined) {
          if (contentIndex !== undefined) {
            newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex].style = {
              ...newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex].style,
              [device]: style
            };
          } else {
            newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].style = {
              ...newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].style,
              [device]: style
            };
          }
        } else {
          newContainers[containerIndex].sections[sectionIndex].style = {
            ...newContainers[containerIndex].sections[sectionIndex].style,
            [device]: style
          };
        }
      } else {
        newContainers[containerIndex].style = {
          ...newContainers[containerIndex].style,
          [device]: style
        };
      }
    }

    return newContainers;


  }

  const updateStyle = useCallback((indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }, style: IStyle, device: 'computer' | 'mobile') => {
    setContainers(() => updateElementStyle(containers, indices, style, device));

  }, [containers]);

  const saveContent = async (pageId) => {

    try {
      await api.put(`events-pages/${pageId}/content`, { content: { page, containers } });

      addToast({ title: 'Salvo com sucesso', type: 'success' })
    }
    catch (err) {
      addToast({ title: 'Erro ao salvar', type: 'error' })
      console.log('err')
    }


  }

  /* here is where i translate */

  const translateContent = async (prompt, value, type = 'text') => {
    try {
      const content = await api.post('/translate-content-api', { prompt, value: type === 'json' ? JSON.stringify(value) : value });
      addToast({ title: 'Traduzido com sucesso', type: 'success' });
      return type === 'json' ? JSON.parse(content.data?.translated) : content.data?.translated;

    }
    catch (err) {
      addToast({ title: 'Erro ao traduzir conteúdo', type: 'error' });
      return value;
    }

  }

  const translateTypes = {

    title: async (value, language) => { return await translateContent(`Traduza o texto para ${language}. Responsa no formato texto, somente com a informação traduzida, sem explicações adicionais.`, value) },
    subtitle: async (value, language) => { return await translateContent(`Traduza o texto para ${language}. Responsa no formato texto, somente com a informação traduzida, sem explicações adicionais.`, value) },
    text: async (value, language) => { return await translateContent(`Traduza o conteúdo deste html para ${language}, mantendo as tags html integras. Responda no formato texto, somente com a informação traduzida, sem explicações adicionais.`, value) },
    videoGallery: async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language}, sempre que identificar as propriedades title, subtitle ou text mantendo as tags html integras. Quando existirem. Nunca mude o nome das propriedades internas do json e, caso o json seja um Array, devolva ele como um array. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },
    ImageGallery: async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language}, sempre que identificar as propriedades title, subtitle ou text mantendo as tags html integras. Quando existirem. Nunca mude o nome das propriedades internas do json e, caso o json seja um Array, devolva ele como um array. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },
    table: async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language},  mantenha as tags html integras. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },
    buttons: async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language}, sempre que identificar as propriedades title, subtitle ou text mantendo as tags html integras. Quando existirem. Nunca mude o nome das propriedades internas do json e, caso o json seja um Array, devolva ele como um array. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },
    sponsors: async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language}, sempre que identificar as propriedades title, subtitle ou text mantendo as tags html integras. Quando existirem. Nunca mude o nome das propriedades internas do json e, caso o json seja um Array, devolva ele como um array. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },
    'speaker-grid': async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language}, sempre que identificar as propriedades title, subtitle ou text mantendo as tags html integras. Quando existirem. Nunca mude o nome das propriedades internas do json e, caso o json seja um Array, devolva ele como um array. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },
    'video_list': async (value, language) => { return await translateContent(`Analise o conteúdo deste JSON e traduza para ${language}, sempre que identificar as propriedades title, subtitle ou text mantendo as tags html integras. Quando existirem. Nunca mude o nome das propriedades internas do json e, caso o json seja um Array, devolva ele como um array. Responda no formato json em texto, para que ele possa sofrer JSON.parse no front-end, somente com a informação traduzida, sem explicações adicionais e sem marcações, apenas o mesmo objeto no formato texto.`, value, 'json') },

  }


  const updateContentItem = async (containerItems: IContainer[], indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }, type: string, value: any, language: string) => {


    // Crie uma cópia profunda dos containers
    const newContainers = [...containerItems];
    const { containerIndex, sectionIndex, rowIndex, contentIndex } = indices;

    if (containerIndex !== undefined) {
      if (sectionIndex !== undefined) {
        if (rowIndex !== undefined) {
          if (contentIndex !== undefined) {

            if (translateTypes?.[type]) {

              const translatedEn = await translateTypes?.[type](value, 'Inglês');
              const translatedEs = await translateTypes?.[type](value, 'Espanhol');
              newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex][`value`] = value;
              newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex][`value_en`] = translatedEn;
              newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex][`value_es`] = translatedEs;


            }
            else {

              newContainers[containerIndex].sections[sectionIndex].rows[rowIndex].content[contentIndex].value = value;

            }



          }
        }
      }
    }

    return newContainers;
  }

  const updateContent = useCallback(async (indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }, type: string, value: any) => {

    const ptBr = await updateContentItem(containers, indices, type, value, '');



    setContainers(ptBr);

  }, [containers]);


  return (
    <PageCreatorContext.Provider value={{
      containers,
      addContainer,
      addRow,
      addContent,
      moveContainerUp,
      moveContainerDown,
      removeContainer,
      moveSectionUp,
      moveSectionDown,
      removeSection,
      moveRowUp,
      moveRowDown,
      removeRow,

      moveContentUp,
      moveContentDown,
      removeContent,
      currentLanguage,
      changeLanguage,

      updateContainerName,
      updateStyle,
      page,
      changePage,
      updateContent,
      saveContent
    }}>
      {children}
    </PageCreatorContext.Provider>
  );
};

export const usePageCreatorContext = () => {
  const context = useContext(PageCreatorContext);
  if (!context) {
    throw new Error('usePageCreatorContext must be used within a PageCreatorProvider');
  }
  return context;
};