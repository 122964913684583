import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import whatsIcon from '../../assets/whats.png';

import { Whats } from './styles';

interface IWhatsapp {
  number?: string;
  text?: string;
  message?: string;
  iconSize?: string;
  fontSize?: string;
}

const WhatsappCompartilharButton: React.FC<IWhatsapp> = ({
  number = '',
  text = 'Oi, estou em seu website!',
  message = 'Tire suas dúvidas pelo WhatsApp',
  iconSize = '30px',
  fontSize = '16px',
}) => {
  return (
    <Whats
      target="_BLANK"
      iconSize={iconSize}
      fontSize={fontSize}
      href={`https://api.whatsapp.com/send?text=${encodeURI(text)}`}
      className="lineIcon"
    >
      <FaWhatsapp />
    </Whats>
  );
};

export default WhatsappCompartilharButton;
