import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { urlWebsite } from '../../../../config';
import { FaLink } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/speakers_term';
  const title = 'Gerenciador de termos';
  const { projectHash, eventHash } = useParams<Record<string, any>>();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    header_text: { ref: 'header_text', column: 'header_text', label: 'Cabeçalho', show: false },
    language: { ref: 'language', column: 'language', label: 'Idioma' },
    term: { ref: 'term', column: 'term', label: 'Termo', show: false },

    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    header_email: { model: 'singleImage', type: 'text', name: 'header_email', label: 'Cabeçalho do e-mail' },
    footer_email: { model: 'singleImage', type: 'text', name: 'footer_email', label: 'Rodapé do e-mail' },

    title: { model: 'input', type: 'text', name: 'title', label: 'Título em Português' },
    title_en: { model: 'input', type: 'text', name: 'title_en', label: 'Título em Inglês' },
    title_es: { model: 'input', type: 'text', name: 'title_es', label: 'Título em Espanhol' },

    description: { model: 'richText', type: 'text', name: 'description', label: 'Informações em Português' },
    description_en: { model: 'richText', type: 'text', name: 'description_en', label: 'Informações em Inglês' },
    description_es: { model: 'richText', type: 'text', name: 'description_es', label: 'Informações em Espanhol' },


    footer_description: { model: 'richText', type: 'text', name: 'footer_description', label: 'Informações pós programação em Português' },
    footer_description_en: { model: 'richText', type: 'text', name: 'footer_description_en', label: 'Informações pós programação em Inglês' },
    footer_description_es: { model: 'richText', type: 'text', name: 'footer_description_es', label: 'Informações pós programação em Espanhol' },

    support_files: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'support_files',
      label: 'Arquivos de apoio',
      list: [
        { label: 'Arquivo', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Título EN', name: 'title_en' },
        { label: 'Título ES', name: 'title_es' },

      ],
    },
    send_full_program: {
      model: 'selectSimple',
      name: 'send_full_program',
      label: 'Enviar programação inteira?',

      defaultValue: 'Sim',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },
    link_drive: { model: 'input', type: 'text', name: 'link_drive', label: 'Link do Drive' },
    link_program: { model: 'input', type: 'text', name: 'link_program', label: 'Link da programação' },

    reply_to: { model: 'input', type: 'text', name: 'reply_to', label: 'Permitir responder para:' },
    send_reply: { model: 'input', type: 'text', name: 'send_reply', label: 'Mandar cópia da confirmação para o e-mail' },

  };

  const formSchemaUpdate = {

    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${projectHash}-${eventHash}-endpoint`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) => {
        return <CopyToClipboard Icon={FiCopy} text={`${urlWebsite}/app/${lineData?.project_id}/accept_terms/${lineData?.hash}`} />


      },
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
