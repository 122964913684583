import React, {
  useState,
  createContext,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { uuid } from 'uuidv4';
import PrinterContainer from '../pages/DashboardPages/core/components/PrinterContainer';

export interface PrinterMessage {
  id: string;
  title: string;
  type?: 'info' | 'success' | 'error';
  description?: string;
}

interface PrinterContextData {
  addPrint(content: Array<JSX.Element>): void;
  printIt(): void;
  clearPrint(): void;
  printMessage: Array<JSX.Element>;
}

const PrinterContext = createContext<PrinterContextData>(
  {} as PrinterContextData,
);

const PrinterProvider: React.FC = ({ children }) => {
  const ref = useRef(null);
  const [printMessage, setPrintMessage] = useState<Array<JSX.Element>>([]);

  const addPrint = useCallback(Item => {
    setPrintMessage(Item);
  }, []);

  const clearPrint = () => setPrintMessage([]);

  const printIt = () => {
    window.print();
  };

  return (
    <PrinterContext.Provider
      value={{ addPrint, clearPrint, printIt, printMessage }}
    >
      <PrinterContainer>{children}</PrinterContainer>
    </PrinterContext.Provider>
  );
};

function usePrinter(): PrinterContextData {
  const context = useContext(PrinterContext);

  if (!context) {
    throw new Error('usePrinter must be used within a PrinterProvider');
  }

  return context;
}

export { usePrinter, PrinterProvider };
