import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}     
interface IParams {
  projectHash : string;
  eventHash : string;
}



const Exam: React.FC = () => {
  const endpoint = '/exams';
  const title = 'Provas';
    const {projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    image: { ref: 'image', column: 'image', label: 'Capa' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    description: { ref: 'description', column: 'description', label: 'Descritivo' },

    success_title : { ref: 'success_title', column: 'success_title', label: 'Título de Sucesso' },
    success_description: { ref: 'success_description', column: 'success_description', label: 'Descritivo de sucesso' },

    failure_title : { ref: 'failure_title', column: 'failure_title', label: 'Título de Sucesso' },
    failure_description: { ref: 'failure_description', column: 'failure_description', label: 'Descritivo de sucesso' },

    correct_answers : { ref: 'correct_answers', column: 'correct_answers', label: 'Acertos para passar' }, 

    questions : { ref: 'questions', column: 'questions', label: 'Perguntas', show: false },

    
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },

    description: {
      model: 'richText',
      type: 'text',
      name: 'description',
      label: 'Descritivo',
    },

    success_title: {
      model: 'input',
      type: 'text',
      name: 'success_title',
      label: 'Título de Sucesso',
    },

    success_description: {
      model: 'richText',
      type: 'text',
      name: 'success_description',
      label: 'Descritivo de Sucesso',
    },

    failure_title: {
      model: 'input',
      type: 'text',
      name: 'failure_title',
      label: 'Título de Falha',
    },

    failure_description: {
      model: 'richText',
      type: 'text',
      name: 'failure_description',
      label: 'Descritivo de Falha',
    },
    
    correct_answers: {
      model: 'selectSimple',
      type: 'text',
      name: 'correct_answers',
      label: 'Acertos para aprovação',
      options: [
        {label : '01', text: '01', value : 1},
        {label : '02', text: '02', value : 2},
        {label : '03', text: '03', value : 3},
        {label : '04', text: '04', value : 4},
        {label : '05', text: '05', value : 5},
        {label : '06', text: '06', value : 6},
        {label : '07', text: '07', value : 7},
        {label : '08', text: '08', value : 8},
        {label : '09', text: '09', value : 9},
        {label : '10', text: '10', value : 10},
        {label : '11', text: '11', value : 11},
        {label : '12', text: '12', value : 11},
        {label : '13', text: '13', value : 13},
        {label : '14', text: '14', value : 14},
        {label : '15', text: '15', value : 15},
        {label : '16', text: '16', value : 16},
        {label : '17', text: '17', value : 17},
        {label : '18', text: '18', value : 18},
        {label : '18', text: '18', value : 19},
        {label : '20', text: '20', value : 20},
        {label : '21', text: '21', value : 21},
        {label : '22', text: '22', value : 22},
        {label : '23', text: '23', value : 23},
        {label : '24', text: '24', value : 24},
        {label : '25', text: '25', value : 25},
        {label : '26', text: '26', value : 26},
        {label : '27', text: '27', value : 27},
        {label : '28', text: '28', value : 28},
        {label : '29', text: '29', value : 29},
        {label : '30', text: '30', value : 30},

      ]
    },

    questions: {
      model: 'jsonInputList',
      type: 'text',
      name: 'questions',
      label: 'Questões',
      show : 'title',
      list : [
        { label: 'Título', name : 'title', type : 'input' },
        { label: 'Descritivo', name : 'description', type : 'richText' },
        { label: 'options', name : 'options', type : 'jsonList', list : [
          { label : 'Pergunta', name: 'title', type: 'input'},
          { label : 'É a correta?', name: 'correct', type: 'select', options: [ 
            { text:'Sim', label: 'Sim', value: 'yes'},
            { text:'Não',label: 'Não', value: 'no'},
          ]},
        ] },
      ]
    },

     
  };


  



  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },
  };


  const formValidation: Record<string, ValidationSchema> = {
  
  };



  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Exam;
