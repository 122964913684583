import styled from 'styled-components';


interface ILink {
  fontSize: string;

}

export const Container = styled.div`
   width: 100%;
   display: flex ;
   align-items: center;
   justify-content: center;
   gap: 7.5px;

   nav {
     width: 30px;
     min-width: 30px;
     border-radius: 50%;
     height: 30px;
     background: #eee ;
     transition: 0.1s all ;
     color:  #282f4c;
     display: flex ;
   align-items: center;
   justify-content: center;

     a{
       width: 100%;
       display: flex ;
   align-items: center;
   justify-content: center;
     }

     svg{
      transition: 0.1s all ;
       font-size: 16px;
       color: #777;
     }

     &:hover{
      background: #282f4c;
      color: #fff;
      svg{
        color: #fff;
      }
     }
   }
`;
