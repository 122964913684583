import React from 'react';
import Marquee from 'react-fast-marquee';

import { ContainerLineUI } from '../../../../../../components/UIComponents/ContainerLine/styles';
import Paragraph from '../../../../../../components/UIComponents/Paragraph';
import Subtitle from '../../../../../../components/UIComponents/Subtitle';
import { TitleUI } from '../../../../../../components/UIComponents/Title/styles';
import { urlWebsite } from '../../../../../../config';
import { BallListContainer } from './styles';
import { useModal } from '../../../../../../hooks/Modal';
interface IImage {
    url: string;
    title?: string;
    subtitle?: string;
    link?: string;
    link_url?: string;
    description?: string;
}

interface IProps {
    images: Array<IImage>,
    template?: string;
}
const RenderIcons: React.FC<IProps> = ({ images }) => {

    return <><div className="computerOnly row-to-column gap" style={{ padding: '100px 15px', alignItems: 'flex-start' }}>

        {images.map(image => {
            return <BallListContainer className='column max-200'>

                <div className=' circleContent shadow'>
                    <img src={`${urlWebsite}/${image.url}`} className="max-200 " />
                </div>
                {image?.title ? <Paragraph style={{ marginTop: '15px', textAlign: 'center' }}>{image?.title}</Paragraph> : <></>}
                {image?.subtitle ? <TitleUI style={{ marginTop: '10px', textAlign: 'center' }}>{image?.subtitle}</TitleUI> : <></>}
            </BallListContainer>
        })}

    </div>
        <div className='full-width mobileOnly' style={{ padding: '50px 15px' }}>
            <Marquee pauseOnHover gradientWidth={30} speed={40} style={{ width: '100%' }}>
                {images.map(image => {
                    return <BallListContainer className='column max-200 pad'> <div className=' circleContent shadow'>
                        <img src={`${urlWebsite}/${image.url}`} style={{ maxWidth: '150px' }} className="" />
                    </div>
                        {image?.title ? <Paragraph style={{ marginTop: '15px', fontSize: '14px', textAlign: 'center' }}>{image?.title}</Paragraph> : <></>}
                        {image?.subtitle ? <TitleUI style={{ marginTop: '10px', fontSize: '18px', textAlign: 'center' }}>{image?.subtitle}</TitleUI> : <></>}
                    </BallListContainer>
                })}

            </Marquee>
        </div>

    </>

}

const RenderFullImages: React.FC<IProps> = ({ images }) => {

    return <><div className="row-to-column gap" style={{ padding: '25px 15px', alignItems: 'flex-start' }}>

        {images.map(image => {
            return <a className='column full-width max-1000' href={image?.link_url} target={'_BLANK'}><ContainerLineUI style={{ background: 'none' }} className='column full-width max-1000'>

                <div className=' '>
                    <img src={`${urlWebsite}/${image.url}`} className="scaleOnHover animateOpacity  cursor full-width  shadow" style={{ background: '#fff', borderRadius: '15px', padding: '15px' }} />
                </div>
                {image?.title ? <Paragraph style={{ marginTop: '15px' }}>{image?.title}</Paragraph> : <></>}
                {image?.subtitle ? <TitleUI style={{ marginTop: '10px' }}>{image?.subtitle}</TitleUI> : <></>}
            </ContainerLineUI></a>
        })}

    </div>


    </>

}

const GalleryImages: React.FC<IProps> = ({ images }) => {
    const { addModal, removeModal } = useModal();
    return <><div className="row-to-column flex-wrap " style={{ padding: '25px 15px', alignItems: 'flex-start' }}>

        {images.map(image => {
            return <ContainerLineUI onClick={() => addModal({
                title: '', content: <>

                    <h2 style={{ fontSize: '14px' }}>{image?.title}</h2>
                    <p style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: image?.description || '' }} />

                </>
            })} style={{ background: 'none', padding: '0px', cursor: 'pointer', position: 'relative' }} className='column  max-400'>

                <div style={{ background: 'none', padding: '0px', width: '100%' }} className=' scaleOnHover '>
                    <img src={`${urlWebsite}/${image.url}`} className=" cursor full-width " style={{}} />
                    <div style={{ position: 'absolute', padding: '5px 15px', bottom: '5px', left: '0%', width: '100%', background: 'rgba(0,0,0,0.4)' }}>


                        {image?.title ? <TitleUI style={{ marginTop: '10px', fontSize: '14px', color: '#fff' }}>{image?.title}</TitleUI> : <></>}

                        {/* image?.subtitle ? <Paragraph style={{ marginTop: '15px', fontSize: '12px', color: '#fff' }}>{image?.subtitle}</Paragraph> : <></> */}
                    </div>

                </div>
            </ContainerLineUI>
        })}

    </div>


    </>

}


const RenderPageImages: React.FC<IProps> = ({ images, template = 'icons' }) => {

    const items = images?.reduce((prev: JSX.Element[], image) => {
        prev.push(<img src={`${urlWebsite}/${image.url}`} className="full-width" />);
        return prev;
    }, [] as JSX.Element[]);


    const templates: Record<string, any> = {
        default: () => items?.length > 0 ? <div style={{ width: '100%', maxWidth: '400px', borderRadius: '15px', overflow: 'hidden' }} className={"ImageSlider"}>{images?.length === 1 ? <img src={`${urlWebsite}/${images?.[0]?.url}`} /> : <img src={`${urlWebsite}/${images?.[0]?.url}`} />
        } </div> : <></>,
        icons: () => <RenderIcons images={images} />,
        full: () => <RenderFullImages images={images} />,
        gallery: () => <GalleryImages images={images} />
    }


    if (!images || !templates?.[template] || images.length < 1) {
        return <></>
    }

    return <>{templates?.[template]()}</>


}

export default RenderPageImages;