import React, { useEffect, useState } from 'react';
import { FaCheck, FaCheckCircle, FaChevronDown, FaChevronRight, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { string } from 'yup';
import FadeInOnScroll from '../../../../../../components/FadeInOnScroll';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import { ParagraphHighlightUI, TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useModal } from '../../../../../../hooks/Modal';
import api from '../../../../../../services/api';
import { formatReal } from '../../../../../../utils/format';
import { scrollToID } from '../../../../../../utils/scrollTo';

import RenderPageList from '../../modules/RenderList';
import RenderPageImages from "../../modules/RenderPageImages";
import { Ticket } from './styles';
function useProducts() {
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const loadProducts = async ({ project_id }) => {

    const products = await api.get(`/products-list/${project_id}`, { params: { limitItems: 10000 } });
    products?.data?.rows?.sort((a, b) => a?.price < b?.price ? -1 : a?.price > b?.price ? 1 : 0)
    setProducts(products?.data?.rows || []);

  }

  return { loadProducts, products };

}
const TicketsTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;
  const { configuration } = useConfiguration();
  const { addModal } = useModal();




  return (
    <>

      <ContainerLineUI className=' full-width row-to-column backgroundImagePreset gap ' id={'tickets'} style={{ background: pageContent.background_image ? `url("${urlWebsite}/${pageContent?.background_image}")` : pageContent?.theme_data?.background ? pageContent?.theme_data?.background : '#fff', alignItems: 'flex-start', flexWrap: 'wrap', padding: '50px 30px', paddingTop: '100px' }} >


        {pageContent?.data_list?.map((item) => {

          return <Ticket className=' scaleOnHover'>
            <div>
              <h2 style={{ color: 'rgb(2,28,60)' }}  >{item?.title?.toUpperCase()}</h2>


              <p style={{ fontSize: '14px', position: 'relative', marginTop: '15px', minHeight: '16px', width: '100%', textAlign: 'center', color: '#777' }}>{item?.price_before && parseInt(item?.price_before, 10) > 0 ? <>{`de: R$  ${formatReal(item?.price_before)}`} <div style={{ width: '120px', height: '1px', background: '#ddd', position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%)' }} /></> : ''}</p>
              <h2 style={{ fontSize: '24px' }}>{item?.price_before && parseInt(item?.price_before, 10) > 0 ? 'por: ' : ''}R$  {formatReal(item?.price)}</h2>
              <p style={{ marginBottom: '15px' }}>Em até 12x no cartão de crédito</p>

              <Link className='full-width' to={item?.url}><button style={{ color: 'rgb(2,28,60)' }} className='highlightButtonGreenArrow'>COMPRAR</button></Link> : <button style={{ color: 'rgb(2,28,60)' }} className='highlightButtonGreenArrow'>COMPRAR</button>
            </div>

            <div style={{ borderTop: '2px dashed #ddd', display: 'flex', paddingTop: '15px', flexDirection: 'column', gap: '10px', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              {item?.list?.map(listItem => {
                return <div className='row gap-sm'>
                  <FaCheck /> <p style={{ fontWeight: listItem?.bold === 'yes' ? 'bold' : 400 }}>{listItem?.title}</p>
                </div>
              })}



            </div>
          </Ticket>







        })}

      </ContainerLineUI>
    </>
  );


}

export default TicketsTemplate;