import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, defaultProjectHash, urlWebsite } from '../../../../../../config';
import { FaLink, FaList, FaMoneyBill, FaReceipt, FaTable, FaTicketAlt, FaUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';
import CopyToClipboard from '../../../../core/components/CopyToClipboard';
import WhatsappCompartilhar from '../../../../../../components/WhatsAppCompartilhar';
import WhatsappCompartilharSimple from '../../../../../../components/WhatsAppCompartilharSimple';

interface ICreateModule {
    lineData: Record<string, any>;
}

export const CodigoAfiliado: React.FC<ICreateModule> = ({ lineData }) => {

    const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
    const { addToast } = useToast();
    const { addModal, removeModal } = useModal();
    const { reloadSearchAll } = useSearch();




    const listar = async (data) => {

        try {
            const projects = await api.get(`/projects`, { params: { limitItems: 1000 } });


            addModal({
                theme: 'whiteModal', key: "modal", title: ' ', content: <>
                    <h2 style={{ color: '#333', fontSize: '16px' }}>Códigos de afiliado</h2>
                    <table className='tableRef'>
                        <tbody>
                            {projects?.data?.rows?.map(project => {
                                return <tr key={`afiliado-${lineData?._id}-${project?.url}`}><td>
                                    <h2 style={{ color: '#333', fontSize: '14px' }}>{project?.title}</h2>
                                    <p style={{ fontSize: '12px' }}>{`${urlWebsite}/app/${project?.url}?indicado-por=${lineData?.seller_hash}`}</p>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}> <CopyToClipboard text={`${urlWebsite}/app/${project?.url}?indicado-por=${lineData?.seller_hash}`} />
                                        <WhatsappCompartilharSimple message='' text={`Seu link de afiliado para o projeto ${project?.title} é ${urlWebsite}/app/${project?.url}?indicado-por=${lineData?.seller_hash}`} />
                                    </div>
                                </td></tr>

                            })}

                        </tbody>
                    </table>

                </>
            })


        }
        catch (err) {
            addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao verificar', type: 'error' })
        }




    }



    const moduleUpdate = (): JSX.Element => (
        lineData?.seller_hash ? <> <button title='Códigos de afiliado' className="lineIcon" type="button" onClick={listar}>
            <FaLink />
        </button>

        </>
            : <></>
    );

    return moduleUpdate();
}
export default CodigoAfiliado;