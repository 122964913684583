import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from "../../../../../../hooks/Language"

import SignIn from '../../../../../SignIn';

interface IProps {
    product_hash: string;
}

const UserNotSigned: React.FC<IProps> = ({ product_hash }) => {

    const { projectHash, eventHash } = useParams<Record<string, any>>();

    const { translate } = useLanguage();

    return <div className="onlineMessage" style={{ display: 'flex', minHeight: 'auto', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}>

        <p style={{ color: '#555', textAlign: 'center', margin: '20px 0px' }}>
            {translate(
                'Você precisa logar ou se inscrever para finalizar a compra.',
            )}</p>

        <SignIn redirect={eventHash ? `/app/${projectHash}/event/${eventHash}/checkout/${product_hash}` : `/app/${projectHash}/checkout/${product_hash}`} /><br /><br />

    </div>
}

export default UserNotSigned;