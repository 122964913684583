import { useState } from "react";
import { useLoading } from "../../../../../hooks/LoadingHook";
import useSendPaymentRequest from "./paymentFunctions/sendPaymentRequest";
import useFieldsList from "./salesFields/salesFields";

type IPaymentMethod = 'credit_card' | 'boleto' | 'pix' | 'cortesy';

export default function usePaymentContext() {
    /* this hook manage the payment request */

    const [installmentValue, setInstallmentValue] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>('pix');

    const { removeLoading } = useLoading();
    const { checkFieldListValidation, fieldsListGetData } = useFieldsList();
    const { finishPaymentRequest } = useSendPaymentRequest();

    const [cardInfo, setCardInfo] = useState({
        card_number: '',
        card_holder_name: '',
        card_expiration_date: '',
        card_cvv: ''
    })

    const changeCardInfo = (property, value) => {

        setCardInfo({ ...cardInfo, [property]: value })

    }

    const changePaymentMethod = (newPaymentMethod) => {
        if (newPaymentMethod === 'creditCard') {
            setPaymentMethod(newPaymentMethod);
        }
        else {
            setPaymentMethod(newPaymentMethod);
            setInstallmentValue(1);
        }
    }

    const changeInstallment = (newInstallment) => {
        setInstallmentValue(newInstallment);
    }

    const sendPaymentRequest = async ({ moveToCart, data, cart, paymentMethod, coupon, courtesy, total, price, discount }) => {

        /*
        1. verify payment_method
          1.1 if credit_card, check card validation
        2. check sales_fields
        3. prepare call
        4. send to confirmation
        */

        const { fields, fields_text } = fieldsListGetData({ product: cart?.[0] || {}, data });

        data.fields = fields;
        data.fields_text = fields_text;

        const paymentContext = {
            ...data,
            card: { ...cardInfo },
            paymentMethod: paymentMethod,
            cart,
            coupon,
            cortesy_hash: courtesy,
            priceInformation: {
                total,
                discount,
                price
            },
            billing: {
                document_type: data?.document_type,
                paymentMethod: paymentMethod,
                installments: installmentValue
            }
        };





        try {
            await checkFieldListValidation({ product: cart?.[0] || {}, data })
        }
        catch (err) {
            removeLoading('loading');
            return;

        }


        await finishPaymentRequest(paymentContext, moveToCart)



    }

    return { installmentValue, changeInstallment, paymentMethod, changePaymentMethod, sendPaymentRequest, changeCardInfo };

}
