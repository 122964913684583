import React, { useEffect, useState } from 'react';
import { addHours, getDate, getDay, getMonth } from "date-fns"
import { monthNumber, simpleDateBR, simpleDateUS } from "../../../../../../utils/date";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Chart from '../../../../../../components/ChartModule';
import { FaCalendar, FaChartBar, FaChartPie, FaCheck, FaCreditCard, FaFilter, FaTicketAlt } from 'react-icons/fa';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInputSearch';
import { useModal } from '../../../../../../hooks/Modal';
import { FiFilter } from 'react-icons/fi';
interface IProps {
    sales?: Array<Record<string, any>>,
    salesProducts?: Array<Record<string, any>>,
    students?: Array<Record<string, any>>,
    products?: Array<Record<string, any>>,
}

interface IOption {
    id: string;
    label: string;
    value: string;
}

const SalesProductsByDay: React.FC<IProps> = ({ sales, salesProducts = [], students, products = [] }) => {
    const { addModal, removeModal } = useModal();
    const [chartType, setChartType] = useState('bar');
    const [sortBy, setSortBy] = useState('brDate');
    const [type, setType] = useState('day');
    const [product, setProduct] = useState<Array<string>>(() => {
        const items: Array<string> = []
        products.map(item => {
            items.push(item?._id?.toString());
        })

        return items;


    });
    const [paymentStatus, setPaymentStatus] = useState<Array<string>>([]);
    const [paymentMethod, setPaymentMethod] = useState<Array<string>>([]);


    // Define the target time zone as GMT-3
    const targetTimeZone = 'America/Sao_Paulo'; // Example for GMT-3, adjust as necessary


    const sortByType = [
        { label: 'Data', value: 'brDate' },
        { label: 'Status de pagamento', value: 'payment_status_id' },
        { label: 'Forma de pagamento', value: 'payment_method_id' },
        { label: 'Produto', value: 'product' },
    ]


    const dayType = [
        { label: 'Por dia', value: 'day' },
        { label: 'Por hora', value: 'hour' },
        { label: 'Por mês', value: 'month' },
        { label: 'Por ano', value: 'year' },
    ]

    const payment_status_id = [
        { label: 'Pago', value: 'paid', id: 'paid' },
        { label: 'Aguardando pagamento', value: 'waiting', id: 'waiting' },
    ]

    const payment_method_id = [
        { label: 'Cartão de crédito', value: 'credit_card', id: 'credit_card' },
        { label: 'Boleto', value: 'boleto', id: 'boleto' },
        { label: 'PIX', value: 'pix', id: 'pix' },
        { label: 'Cortesia', value: 'cortesy', id: 'cortesy' },
        { label: 'Gratuidade', value: 'cortesia', id: 'cortesia' },
    ]

    const currentDayType = dayType?.find(item => item?.value === type);

    const currentProduct = products?.find(item => item?._id?.toString() === product);





    const typesBR = {
        day: 'dd/MM/yyyy',
        hour: 'HH',
        year: 'yyyy',
        month: 'MM',
    }

    const typesUSA = {
        day: 'yyyy-MM-dd',
        hour: 'HH',
        year: 'yyyy',
        month: 'MM',
    }

    const salesProductsList = salesProducts?.reduce((prev, item) => {

        const userId = item?.client_id_response?._id ? item?.client_id_response?._id?.toString() : item?.payer_id_response?._id?.toString();
        const student = students?.find(userInfo => userInfo?._id?.toString() === userId)

        if (product && product?.length >= 0 && product?.[0]) {

            if (product?.findIndex(productHash => productHash === item?.product_id_response?._id?.toString()) < 0) {

                return prev;
            }
        }

        if (paymentStatus && paymentStatus?.length >= 0 && paymentStatus?.[0]) {
            if (paymentStatus?.findIndex(status => status === item?.payment_status_id) < 0) {
                return prev;
            }
        }

        if (paymentMethod && paymentMethod?.length >= 0 && paymentMethod?.[0]) {
            if (paymentMethod?.findIndex(method => method === item?.payment_method_id) < 0) {
                return prev;
            }
        }

        const dateInTargetTimeZone = utcToZonedTime(item.createdAt, targetTimeZone);

        // Format date to dd/MM/yyyy for dateBr
        let brDate = format(dateInTargetTimeZone, typesBR[type]);

        if (sortBy === 'product') {
            brDate = item?.product_id_response?.title
        }

        if (sortBy === 'payment_status_id') {
            brDate = payment_status_id?.find(status => status?.value === item?.payment_status_id)?.label || '';
        }

        if (sortBy === 'payment_method_id') {
            brDate = payment_method_id?.find(method => method?.value === item?.payment_method_id)?.label || '';
        }

        if (sortBy === 'state') {
            brDate = student?.state || 'Não informado';
        }

        if (sortBy === 'city') {
            brDate = student?.city ? `${student?.city} (${student?.state})` : 'Não informado';
        }

        if (sortBy === 'company') {
            brDate = student?.company || 'Não informado';
        }

        if (sortBy === 'disability_type') {
            brDate = student?.disability_type ? student?.disability_type?.trim() : 'Não';
        }

        if (sortBy === 'know_about') {
            brDate = student?.know_about || 'Não informado';
        }

        if (sortBy === 'seller_hash') {
            brDate = item?.seller_hash || 'Sem indicação';
        }

        // Format date to yyyy-MM-dd for dateUS
        const date = format(dateInTargetTimeZone, typesUSA[type]);



        //   const index = prev?.findIndex(i => i?.date === date);

        {/*   if (index >= 0) {
            prev[index].count = prev[index].count + 1;
        }
        else {
            prev.push({ date: date, brDate, count: 1 });
        }
    */}

        const produto = products?.find(i => i?._id?.toString() === item?.product_id);

        if (produto && produto?.price < 1) {
            item.payment_method_id = 'cortesia';

        }
        prev.push({ date: date, brDate, count: 1, seller_hash: item?.seller_hash, company: student?.company, disability_type: student?.disability_type, know_about: student?.know_about, state: student?.state, city: `${student?.city} (${student?.state})` });
        return prev;

    }, []);



    const sortedList = salesProductsList.sort((a, b) => a.date < b.date ? 1 : a.date > b.date ? -1 : 0);

    const productList = products ? products?.reduce((prev: Array<IOption>, item): Array<IOption> => {
        prev.push({ label: item?.title, value: item?._id?.toString(), id: item?._id?.toString() });
        return prev;
    }, [] as Array<IOption>) : [];



    return <>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', width: '100%', gap: '10px', background: '#eee', padding: '10px 16px', }}>
            <div className=' full-width'>



                <div className='row row-start full-width gap'>
                    <div>
                        <div onClick={() => addModal({
                            theme: 'whiteModalMini',
                            key: 'product',
                            title: '', content: <>
                                <Form initialData={{
                                    product
                                }} onSubmit={(data) => { setProduct(data?.product); removeModal('product') }}>
                                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>

                                    <CheckboxInput name='product' options={productList} />
                                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>
                                </Form>
                            </>
                        })}><button className="greenButton2 buttonColored"><FiFilter /> Produto {product?.length > 0 ? <aside style={{ padding: '5px', minWidth: '20px', fontSize: '10px', minHeight: '20px', borderRadius: '50%', color: '#2c3e4e', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{product?.length || '0'}</aside> : <></>}</button></div>

                    </div>
                    <div>
                        <div onClick={() => addModal({
                            theme: 'whiteModalMini',
                            key: 'payment_status_id',
                            title: '', content: <>
                                <Form initialData={{
                                    payment_status_id: paymentStatus
                                }} onSubmit={(data) => { setPaymentStatus(data?.payment_status_id); removeModal('payment_status_id') }}>
                                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>

                                    <CheckboxInput name='payment_status_id' options={payment_status_id} />
                                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>
                                </Form>
                            </>
                        })}><button className="greenButton2 buttonColored"><FiFilter /> Status de Pagamento {paymentStatus?.length > 0 ? <aside style={{ padding: '5px', minWidth: '20px', fontSize: '10px', minHeight: '20px', borderRadius: '50%', color: '#2c3e4e', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{paymentStatus?.length || '0'}</aside> : <></>}</button></div>
                    </div>
                    <div>
                        <div onClick={() => addModal({
                            theme: 'whiteModalMini',
                            key: 'payment_method_id',
                            title: '', content: <>
                                <Form initialData={{
                                    payment_method_id: paymentMethod
                                }} onSubmit={(data) => { setPaymentMethod(data?.payment_method_id); removeModal('payment_method_id') }}>
                                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>

                                    <CheckboxInput name='payment_method_id' options={payment_method_id} />
                                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>
                                </Form>
                            </>
                        })}><button className="greenButton2 buttonColored"><FiFilter /> Forma de pagamento {paymentMethod?.length > 0 ? <aside style={{ padding: '5px', minWidth: '20px', fontSize: '10px', minHeight: '20px', borderRadius: '50%', color: '#2c3e4e', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{paymentMethod?.length || '0'}</aside> : <></>}</button></div>

                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', width: '100%', gap: '20px' }}>
                <div className='borderBlock'>
                    <p style={{ padding: '5px 0px' }}>Agrupar por:</p>
                    <div style={{ width: 'auto', gap: '5px', display: 'flex', minWidth: 'auto' }}>
                        <div title='Período' onClick={() => setSortBy('brDate')} className={sortBy === 'brDate' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Período
                        </div>
                        <div title='Ingressos' onClick={() => setSortBy('product')} className={sortBy === 'product' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Produto
                        </div>
                        <div title='Método de pagamento' onClick={() => setSortBy('payment_method_id')} className={sortBy === 'payment_method_id' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Forma de pagamento
                        </div>
                        <div title='Status de pagamento' onClick={() => setSortBy('payment_status_id')} className={sortBy === 'payment_status_id' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Status de pagamento
                        </div>
                        <div title='Estado' onClick={() => setSortBy('state')} className={sortBy === 'state' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Estado
                        </div>
                        <div title='Município' onClick={() => setSortBy('city')} className={sortBy === 'city' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Município
                        </div>
                        <div title='Como soube' onClick={() => setSortBy('know_about')} className={sortBy === 'know_about' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Como Soube
                        </div>
                        <div title='Empresa' onClick={() => setSortBy('company')} className={sortBy === 'company' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Empresa
                        </div>
                        <div title='Deficiência' onClick={() => setSortBy('disability_type')} className={sortBy === 'disability_type' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                            Deficiência
                        </div>

                    </div>

                </div>
                {sortBy === 'brDate' ? <div className='borderBlock' >
                    <p style={{ padding: '5px 0px' }}>Período:</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                        {dayType?.map(item => {
                            return <button onClick={() => setType(item?.value)} className={type === item?.value ? 'greenButton2Mini  buttonColored' : 'greenButton2Mini'}>{item?.label}</button>
                        })}


                    </div>


                </div> : <></>}
            </div>
            <div style={{ width: '100%', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '3px' }} >

                <div style={{ width: '100%', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '3px', flexWrap: 'wrap' }} >
                    <nav style={{ fontSize: '12px', color: '#333' }}>Filtros</nav>

                    {type ? <nav className='labelTag'>{currentDayType?.label}</nav> : <></>}
                    {product ? product?.map(productHash => <nav className='labelTag'>{productList?.find(item => item?.value === productHash)?.label}</nav>) : <></>}
                    {paymentStatus ? paymentStatus?.map(status => <nav className='labelTag'>{payment_status_id?.find(item => item?.value === status)?.label}</nav>) : <></>}

                    {paymentMethod ? paymentMethod?.map(method => <nav className='labelTag'>{payment_method_id?.find(item => item?.value === method)?.label}</nav>) : <></>}
                </div>

                <div style={{ width: '100px', gap: '5px', display: 'flex', minWidth: '100px' }}>
                    <div onClick={() => setChartType('bar')} className={chartType === 'bar' ? 'IconBallActive shadow' : 'IconBall shadow'}>
                        <FaChartBar />
                    </div>
                    <div onClick={() => setChartType('pie')} className={chartType === 'pie' ? 'IconBallActive shadow' : 'IconBall shadow'}>
                        < FaChartPie />
                    </div>
                </div>
            </div>
        </div>
        {/*    <table className="table">
            <tr><th>Data</th><th>Vendas</th></tr>
            {sortedList?.map(day => {
                return <tr key={`resumo-vendas-${day?.date}`}><td>{day?.brDate}</td><td>{day?.count}</td></tr>
            })}

        </table> */}

        <Chart sortBy='brDate' data={sortedList} group={'brDate'} chartType={chartType} />
    </>


}

export default SalesProductsByDay;