import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaMinus, FaPlus, FaSpinner, FaTrash } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../../hooks/Language';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import { Container, ProductItemStyle } from './styles';



interface IProductRender {
    cart: Array<Record<string, any>>;
    product: Record<string, any>;
    setCallback: Function;
    unique: boolean;
    notChange: boolean;
    minimum?: number;

}

interface IAddProductComponent {
    cart: Array<Record<string, any>>;
    setCallback: Function;
    unique: boolean;
    showAdditional?: boolean;
}

function useProducts({ project_id }) {

    const loadProducts = async () => {

        const products = await api.get(`/products-list/${project_id}`, { params: { limitItems: 10000 } });

        return products?.data?.rows || [];

    }

    return { loadProducts };

}

const ProductItem: React.FC<IProductRender> = ({ minimum = 0, notChange, unique, product, setCallback, cart }) => {
    const { product_hash } = useParams<Record<string, any>>();
    const productReference = cart?.find(item => item?._id?.toString() === product?._id?.toString());


    return product?.title ? <ProductItemStyle >

        <div className='addProductInfo'><p style={{ fontWeight: 'bold' }}>{product?.title} </p>
            {product_hash !== 'courtesy' ? <p>Valor unitário: R$ {format(product?.price)}</p> : <></>}
        </div>

        <div className='addProductAction'>
            <div><p>{productReference?.quantity || '0'}</p></div>
            {unique ? <div className={notChange ? 'activeProduct' : productReference?.quantity > 0 ? 'activeProduct changeble' : 'inactiveProduct changeble'}>

                {notChange ? <FaCheck /> :

                    (!productReference || productReference?.quantity < 1) ? <FaCheck /> : <> <FaCheck className='showIcon' /> <FiX className='notShowIcon' /> </>}
            </div> : <>
                <div> <FaPlus style={{ opacity: !productReference || productReference?.quantity >= product?.available_quantities ? 0.5 : 1 }} onClick={() => setCallback(product, 'add')} /> </div>
                <div>  <FaMinus style={{ opacity: !productReference || productReference?.quantity <= minimum ? 0.5 : 1 }} onClick={() => !productReference || productReference?.quantity <= minimum ? false : setCallback(product, 'remove')} /> </div>

            </>}
        </div>
    </ProductItemStyle> : <></>




}

const AddProductsComponent: React.FC<IAddProductComponent> = ({ cart, setCallback, unique = true, showAdditional = true }) => {
    const { projectHash } = useConfiguration();
    const { product_hash } = useParams<Record<string, any>>()
    const [products, setProducts] = useState<Array<Record<string, any>>>([])

    const { loadProducts } = useProducts({ project_id: projectHash });

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        loadProducts().then(productsArray => {
            setProducts([...productsArray]);
            setLoading(false);
        });

    }, [])


    const { translate } = useLanguage();

    const listProducts = products?.filter((product) => (product?.available_quantities > 0 || product?.limited_quantities !== 'Sim') && product?.hash_link !== product_hash && cart?.[0]?.hash_link !== product?.hash_link);

    return <Container  >

        <h2 style={{ margin: '10px 0px', fontSize: '14px' }}>{translate('Produto selecionado')}</h2>

        <ProductItem unique={false} notChange={false} minimum={0} cart={cart} key={cart?.[0]?._id?.toString()} product={cart?.[0]} setCallback={setCallback} />

        {showAdditional ? <>
            {listProducts?.length > 0 ? <h2 style={{ margin: '10px 0px', fontSize: '14px' }}>{translate('Adicionar mais produtos')}</h2> : <></>}

            {loading ? <p style={{ margin: '5px 0px', color: '#333', textAlign: 'right', width: '100%' }}><FaSpinner className='spin' size={20} style={{ minWidth: '20px', width: '20px', color: '#333', marginRight: '10px' }} />  {translate(`Carregando`)}</p> : <></>}

            {listProducts?.map((product) => <ProductItem notChange={false} unique={unique} cart={cart} key={product?._id?.toString()} product={product} setCallback={setCallback} />)}

        </> : <></>}
    </Container>

}

export default AddProductsComponent;
