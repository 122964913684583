import React, { useCallback, useRef, useEffect, useState } from 'react';
import { format, utcToZonedTime } from "date-fns-tz";
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../../services/api';

import { Container, Sidebar, Body } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../../hooks/Toast';

import { Form } from '@unform/web';
import DatePicker from '../../DashboardPages/core/components/Forms/DatePicker';
import Button from '../../DashboardPages/core/components/Forms/Button';
import SelectSimple from '../../DashboardPages/core/components/Forms/SelectSimple';
import ChartBar from '../../DashboardPages/core/components/Charts/Bar';

import { searchable } from '../../DashboardPages/pages/Students/index';
import { apiUrl, getToken } from '../../../config';
import downloadit from '../../../utils/download';

interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface IChartData {
  hora: string;
  usuarios: number;
}

const Reporter: React.FC = () => {
  const [percentage, setPercentage] = useState('0.00');
  const [active, setActive] = useState(false);
  const [lessons, setLessons] = useState<Array<IOptions>>([]);
  const [agruparPor, setAgruparPor] = useState<Array<IOptions>>([]);
  const [graphData, setGraphData] = useState<Array<IChartData>>([]);
  const [responsive, setResponsive] = useState('Sim');
  const [type, setType] = useState('students');
  const [exportType, setExportType] = useState('xlsx');

  const [indexChart, setIndexChart] = useState('hora');
  const [headerChart, setHeaderChart] = useState('usuarios');

  const [status, setStatus] = useState({
    room_id: 'a',
    date: new Date().toISOString(),
    enter: 'Entrou',
    period: 'day',
    distinct: 'user',
    responsive: 'Sim'
  });

  const { addToast } = useToast();

  const callReport = async (data) => {

    setStatus(state => ({ ...state, ...data }));

    data.date = addHours(data.date, -3);
    if (data.room_id === 'a') {
      delete data.room_id;

    }
    const response = await api.post('/reporter-checkin', { ...data });

    if (response) {
      setIndexChart('hora');
      setHeaderChart('usuarios');





      setGraphData(response.data);
    }


  }

  const changePeriod = (value) => {
    setStatus(state => ({ ...state, period: value }));
  }

  const changeAction = (value) => {
    setStatus(state => ({ ...state, enter: value }));
  }


  const changeDistinct = (value) => {
    setStatus(state => ({ ...state, distinct: value }));
  }


  const loadLessons = async () => {

    const response = await api.get('/lessons?limitItems=100');
    if (response) {

      const data: Array<IOptions> = [{ label: 'Todos', text: 'Todos', value: 'a' }];
      response.data.rows.map(m => {
        data.push({ label: m.title, text: m.title, value: m.id });
      })

      setLessons(data);

    }


  }

  useEffect(() => {

    loadLessons();
    setTimeout(() => {
      callReport({ ...status, responsive, date: new Date() });
    }, 3000)

    const agrupar: Array<IOptions> = [];

    Object.keys(searchable).map(key => {
      agrupar.push({ label: searchable[key].label, text: searchable[key].label, value: searchable[key].column })
    })

    setAgruparPor(agrupar)


  }, [])

  return (
    <Container>

      <Sidebar>

        <Form onSubmit={callReport} initialData={{ ...status, responsive }}>
          <h2 style={{ color: "#333", padding: '15px' }}>Relatórios</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: '15px' }}>
            <div style={{ width: '100%', maxWidth: '300px' }}><label>Ação</label><SelectSimple setCallback={(value) => value !== status.enter ? changeAction(value) : false} label="" options={[{ label: "Entrada", value: 'Entrou' }, { label: "Saída", value: 'Saiu' }]} name="enter" /></div>

            <div style={{ width: '100%', maxWidth: '300px' }}><label>Período</label><SelectSimple setCallback={(value) => value !== status.period ? changePeriod(value) : false} label="" options={[{ label: "Diário", value: 'day' }, { label: "6 horas", value: '360' }, { label: "4 horas", value: '240' }, { label: "3 horas", value: '180' }, { label: "60 minutos", value: '60' }, { label: "30 minutos", value: '30' }, { label: "15 minutos", value: '15' }, { label: "10 minutos", value: '10' }]} name="period" /></div>
            <div style={{ width: '100%', maxWidth: '300px', display: status.period === 'day' ? 'none' : 'flex', flexDirection: 'column' }}><label>Data</label><DatePicker name="date" /></div>
            <div style={{ width: '100%', maxWidth: '300px' }}><label>Responsivo?</label><SelectSimple setCallback={(value) => { setResponsive(value) }} label="" options={[{ label: "Sim", value: 'Sim' }, { label: "Não", value: 'Não' }]} name="responsive" /></div>
            <div style={{ width: '100%', maxWidth: '300px' }}> <Button>
              Filtrar
            </Button></div>


          </div>
        </Form>


      </Sidebar>

      <Body size={graphData.length} responsive={responsive}>

        {graphData && <div style={{ width: '100%', height: '50vh' }}><ChartBar indexBy={indexChart} headers={[headerChart]} data={graphData} /></div>}

      </Body>
    </Container>
  );
};
export default Reporter;
