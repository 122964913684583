import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform:translateX(-50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  background: none;

  .titleSkewRight {
    display: flex;
    border-radius: 10px;
    background: rgb(0, 102, 178);
    transition: 0.5s all;
    padding: 10px 30px;
    justify-content: center;
    > h2,
    > button {
      color: #fff;
      font-size: 24px;
    }

    &:hover {
      background: rgb(0, 102, 178);
      > h2,
      > button {
        background: rgb(0, 102, 178);
      }
    }
  }
`;

export const Expositor = styled(Link)<IBackground>`
  width: 400px;
  height: auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  margin: 10px 10px;
  border-radius: 10px;
  transition: 0.5s all;

  > div {
    background: url(${props => props.background || ''}) no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 56%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 750px) {
    width: 300px;
  }

  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: translateY(-10px) translateX(-10px);
  }

  > div.footerExpositor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    padding: 0 10px;

    > div.logoExpositor {
      width: 75px;

      background: none;

      position: relative;
      border-radius: 8px;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
      transform: translateY(50%);
      border: 2px solid #fff;

      min-height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      > img {
        width: 100%;
      }
    }
  }
`;

export const Feira = styled.div`
  width: 100%;
  min-height: 100vh;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 5%;

  @media (max-width: 750px) {
    padding: 0;
  }
  > div {
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: center;
    border: 0;
    flex-wrap: wrap;

    padding: 10px;
    transition: 0.5s all;
    cursor: pointer;
    width: 100%;

    > img {
      width: 100%;
      max-width: 100%;
    }

    > svg {
      font-size: 45px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    > p {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }

    > .entrar {
      opacity: 0;
      background: rgba(0, 0, 0, 0.3);
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0px;
      right: 0;
      cursor: pointer;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
