import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../../../../../components/Whatsapp';
import { logo, authTitle, urlWebsite, supportNumber } from '../../../../../config';
import { useConfiguration } from '../../../../../hooks/Configuration';
import {

  Capa,

} from './styles';

interface IParams {
  projectHash: string;
}

const SidebarTheme: React.FC = () => {
  const { configuration } = useConfiguration();

  const { projectHash } = useParams<IParams>();
  return (
    <>
      <Capa>
        <div style={{ padding: configuration?.home_image_is_full === 'yes' ? '0px' : '20px', width: '100%' }} >

          {configuration.header_image && <div style={{ width: '100%' }}>
            <img style={{ width: '100%', opacity: 1, maxWidth: configuration?.home_image_is_full === 'yes' ? '100%' : '600px', margin: '0px auto', marginTop: configuration?.home_image_is_full === 'yes' ? '0px' : '150px' }}
              src={`${urlWebsite}/${configuration.header_image}`} />

          </div>}
        </div>
        <div className="p51 backgroundWhiteGradient" style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', flexDirection: 'column' }}>
          <div className="titleCapa">
            {configuration.data_image ? <img src={`${urlWebsite}/${configuration.data_image}`} style={{ width: '100%', opacity: 1, maxWidth: '500px', margin: '0px auto' }} /> : <></>}


            {configuration?.active_pages?.map(button => {
              return button.button === 'yes' ? <a href={`/app/${projectHash}/${button.url}`} style={{ margin: '5px 10px' }}><button className={button.class_name || 'defaultButton'} >{button.title}</button></a> : <></>
            })}

            {/*   <Link to={`/app/${projectHash}/login`} style={{margin: '5px 10px'}}><button className="defaultButton">Entrar</button></Link> */}
            <Whatsapp className="fixedWhatsapp" number={supportNumber} message="Suporte técnico" text={`Olá, estou no ${authTitle}`} />

          </div>
        </div>
      </Capa>

    </>
  );
};
export default SidebarTheme;
