import React from 'react';
import { Link } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from "../../../../../../hooks/Language"

const ProductNotFound: React.FC = () => {

    const { translate } = useLanguage();
    const { configuration } = useConfiguration();

    return <div className="onlineMessage" style={{ display: 'flex', background: '#fff', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>

        <h2 className="defaultMarked">{translate('Ingresso não encontrado')}</h2>
        <p style={{ color: '#555', textAlign: 'center', marginTop: '20px' }}>
            {translate(
                'Verifique se este ingresso ainda está disponível.',
            )}
        </p><br /><br />
        <Link to={`/app/${configuration?.url}`}>
            <button className="greenButton2 buttonColored" type="button">
                {' '}
                {translate('Voltar')}
            </button>
        </Link>
    </div>

}

export default ProductNotFound;