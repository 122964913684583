import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}     
interface IParams {
  projectHash : string;
  eventHash : string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/image-gallery';
  const title = 'Lista de Imagens';
    const {projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    description: { ref: 'description', column: 'description', label: 'Descritivo' , show: false},
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    order_id: { ref: 'order_id', column: 'order_id', label: 'Ordem' },
    url: { ref: 'url', column: 'url', label: 'Link', show: false },
    image: { ref: 'image', column: 'image', label: 'Imagem' , show: false},
    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Status',
      default: 2,

      endpoint,
    },
    order_id: {
      model: 'selectSimple',
      name: 'order_id',
      label: 'Ordem',
      defaultValue: '1',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
      ],
    },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },

    url: { model: 'input', type: 'text', name: 'url', label: 'Link' },
    social: {
      model: 'jsonList',
      type: 'text',
      name: 'social',
      label: 'Social Icons',
      list: [
        { label: 'Whatsapp', name: 'whatsapp' },
        { label: 'Website', name: 'website' },
        { label: 'Linkedin', name: 'linkedin' },
      ],
    },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },

  };

  const formSchemaUpdate = {
   
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
  
  };


  
  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema : formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
