import styled, { css, keyframes } from 'styled-components';

interface IProps {
  active: boolean;
}

const fadeIn = keyframes`
0% {
  opacity: 0;
  filter: blur(10deg);
  transform: translateY(-15px);
}
100% {
  opacity: 1;
  filter: blur(0deg);
  transform: translateY(0px);
}
`;


export const Container = styled.nav<IProps>`
  padding: 0px;
  border: 2px solid #fff;
  border-radius:  0px ;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff ;
 position: relative ;
   z-index:1000000000;

  h2{
    font-size: 16px !important;
    color: #777  !important;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  h3{
  
    color: #777;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  input,textarea, select{
    border-radius: 10px;
    padding: 10px !important;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
  }

  label{
    font-size: 14px;
    margin-bottom: 5px;
    color: #777;
    padding: 5px 0px !important;
   
  }

  div.actionDiv{
    display: flex !important;
    align-items: center !important;
    justify-content:center !important;
    flex-direction: column !important;
    margin-top: 25px !important;
    width: 100%;

  }



  button.closeAdminButton{
      border: 0px !important;
      font-size: 16px !important;
      padding: 5px 10px !important;
      align-self: center;
      border: 1px solid #dbdbdb !important;
  border-radius: .375em !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  color: #777 !important;
  margin-bottom: 10px !important;
  background: #fff !important ;
  width: 100px !important;

  >svg{
    min-width: 15px !important;
    min-height: 15px !important;
    color: #777 !important;
    transition: 0.5s all !important;
    
    margin-left: 10px !important;

  }


  &:hover {
  border-color: #b5b5b5 !important;
  color: #333 !important;

  >svg{
 
    color: #333 !important;

    
  }
  }
    }


    button.actionAdminButton{
      border: 0px !important;
      font-size: 16px !important;
      padding: 10px  !important;
      
      border: 1px solid #dbdbdb !important;
  border-radius: .375em !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  color: #fff !important;
  margin-bottom: 10px !important;
 
  

background: #ababab;
font-weight: bold;
 
   
  width: 100% !important;
  max-width: 200px;
  transition: 0.5s all !important;
  >svg{
    min-width: 15px !important;
    min-height: 15px !important;
    color: #fff !important;
    transition: 0.5s all !important;
    
    margin-right: 10px !important;

  }


  &:hover {
    background: #777!important;
  
  

  >svg{
 
   

    
  }
  }
    }

 
  .roomConfigActivation{
    display : flex ;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;

    >svg{
      min-width: 25px;
      width: 25px;
      color: #333;
      cursor: pointer;
    }


  }
  .roomConfigSelector {
    display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    width: 100% ;
    max-width: 400px;
    margin: 0px auto;
    padding: 50px 10px;

    
    

    position: relative ;

    display: ${props => props.active ? 'flex' : 'none'} ;

    div{
      width: 100%;
      max-width: 300px;
      display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    }

    ${props => props.active && css`
    
     
      width: 100%;
      bottom: 0px;
      background: #fff ;
   
      animation: ${fadeIn} 0.5s forwards;
    `}

    button, nav{
     animation-delay: 0.5s;
      animation: ${fadeIn} 0.5s forwards;
      background: #fff;
       
    }

  

    nav {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #777;
  cursor: pointer;
  display: inline-flex;
   font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: left !important;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  width: 300px;

  margin: 5px auto;
  transition: 0.5s all;

  >svg{
    min-width: 15px;
    min-height: 15px;
    color: #777;
    transition: 0.5s all;
    
    margin-right: 10px;

  }


  &:hover {
  border-color: #b5b5b5;
  color: #333;

  >svg{
    min-width: 20px;
    min-height: 20px;
    color: #333;

    
  }
  }

}

  }

 

 

 

`;
