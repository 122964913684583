import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { useAuth } from '../../../hooks/Auth';
import { useConfiguration } from '../../../hooks/Configuration';
import { loadFromJson } from '../../../utils/loadFromJson';
import { hash } from '../../../config';
import api from '../../../services/api';

const PageLayout: React.FC = ({ children }) => {
  const { token, user } = useAuth();
  const { setConfiguration, projectHash, eventHash } = useConfiguration();
  const { handleApiErrors } = useAuth();
  const load = async () => {
    // setLoading(true);

    try {


      let data: Record<string, any> = {};

      const project = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Project' });

      if (project?.[0]) {
        data = project[0];

        const event = await loadFromJson({ hash: hash, project_id: data.url, table: 'Event' });
        if (event?.[0]) {


          data.current_event_id_response = { ...event[0] };
        }
        else {

          const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);
          data = response?.data;
        }
      }
      else {

        const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);

        data = response?.data;
      }



      setConfiguration(data);



    }
    catch (err) {

      handleApiErrors(err);

    }
  }



  return <Wrapper>{children}</Wrapper>;
};

export default PageLayout;
