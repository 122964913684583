import React, { useState, useRef } from 'react';

import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';

import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';

import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import api from '../../../../../../services/api';
import { decodeCracha } from '../Cracha';
import { string } from 'yup';


interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}

export default function CrachaLote({ idKey = '' }): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const { configuration } = useConfiguration();
  const key = uuid();



  const print = () => {
    printIt();
  };

  const getLastName = (last_name) => {

    if (!last_name) {
      return last_name || '';
    }

    if (configuration?.theme_data?.cracha_split_last_name === "yes") {

      const index = last_name.indexOf(' ');

      if (index >= 0) {
        const splits = last_name.split(" ");
        const length = splits.length - 1;
        return splits?.[length] || '';
      }
      else {
        return last_name;
      }

    }
    else {

      return last_name;
    }


  }


  const handleQR = async (): Promise<void> => {





    let html: Array<JSX.Element> = [];

    const data = await api.get('/students', { params: { limitItems: 10000, where: { project_id: configuration?.url }, order: { orderBy: 'name', direction: 'asc' } } });

    const dataRows = data?.data?.rows || [];

    if (dataRows) {
      dataRows?.map((line, index) => {
        const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;
        html.push(<>  <div className="qrHolder2" style={{ display: 'flex', width: '100%', minWidth: '8.7cm', justifyContent: 'space-between', alignItems: 'center', padding: '15px', paddingBottom: '0px', paddingTop: configuration?.theme_data?.cracha_margin_top_line ? configuration?.theme_data?.cracha_margin_top_line : '0px' }}>

          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingRight: '20px' }}>
            {decodeCracha(line, configuration?.cracha_config)}
          </div>
          {configuration?.cracha_have_qrcode !== 'no' && line?.user_hash ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
            size={80}
            value={line.user_hash} renderAs="svg" /></div> : <></>}

        </div><div className="breakPage" /></>);

      });

    }


    addPrint(
      html
    );


    const content = <><h2 style={{ color: '#fff' }}>CRACHÁ</h2><Button onClick={() => print()}>Imprimir</Button><div style={{ background: '#fff', width: '9cm', textAlign: 'center' }}>{html}</div> </>;

    if (content) {
      const keyModal = addModal({ title: 'Imprimir Lote', content, key });
    }



  };

  const moduleQRCode = (): JSX.Element => (


    <button className="searchButton" title="Imprimir Lote de Crachás" type="button" onClick={handleQR}>
      <FaBarcode />
    </button>
  );

  return moduleQRCode();
}
