import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
  FaDownload,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../core/components/Forms/Input';
import Button from '../../../core/components/Forms/Button';
import Loader from '../../../core/components/Loader';
import MaskedInput from '../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../core/components/ResponseMessage';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Error } from './styles';
import { AnimatedDiv } from '../styles';
import RichText from '../../../core/components/Forms/RichText';
import api from '../../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../../config';

import { useToast } from '../../../../../hooks/Toast';
import CheckboxInput from '../../../core/components/Forms/CheckboxInput';

import { useLanguage } from '../../../../../hooks/Language';
import SelectSimple from '../../../core/components/Forms/SelectSimple';
import { countries } from './countries';
import Program from 'src/pages/Program';
import ImageSingleGalleryInput from 'src/pages/DashboardPages/core/components/Forms/ImageSingleGalleryInput';
import { useModal } from 'src/hooks/Modal';

interface ICaptcha {
  getValue(): string;
}

interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

interface IParams {
  hash: string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { hash } = useParams<IParams>();
  const { language } = useLanguage();
  const [failure, setFailure] = useState(false);
  const [ready, setReady] = useState(false);

  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const [term, setTerm] = useState<Record<string, any>>({
    hash: '',
    header_text: '',
    term: '',
    language: 'ptBr',

  })

  const load = async () => {
    setLoading(true);

    if (hash) {
      try {
        const response = await api.get(`/get-speaker-term/${hash}`);
        setTerm(response?.data);
        setLoading(false);
        setReady(true);
      }
      catch (err) {
        setFailure(true);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    load();
  }, [hash])

  const { addToast } = useToast();
  const { translate } = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const { addModal, removeModal } = useModal();




  const sendUpdate = async (data, hash) => {

    try {

      await api.put(`update-speaker-content/${hash}`, { ...data });

      addToast({ title: 'Atualizado com sucesso', type: 'success' });
      removeModal('update');

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao atualizar', type: 'error' })
    }

  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        status: Yup.string().required(term?.language === 'en' ? 'We ask that you confirm whether you will participate in the event.' : term?.language === 'es' ? 'Le pedimos que confirme si participará en el evento.' : 'Pedimos que confirme se participará do evento.'),

      });



      data.status = data.status[0];


      await schema.validate(data, { abortEarly: false });


      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;



      const newUser = await api
        .post(`/terms-send-confirmation/${hash}`, {
          ...data,
          captcha: captchaValue,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || term?.language === 'en' ? 'Error sending' : term?.language === 'es' ? 'Error al enviar' : 'Erro ao enviar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);

      window?.location?.reload();


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: term?.language === 'en' ? 'Validation error' : term?.language === 'es' ? 'Error de validacion' : 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  if (failure) {
    return <AnimatedDiv> <ResponseMessage
      active
      type="error"
      title=""
      description={
        <p style={{ color: '#333' }}>
          <h2 className="titleH2" style={{ color: '#333' }}> {term?.language === 'en' ? 'Failed to identify term' : term?.language === 'es' ? 'No se pudo identificar el término' : 'Falha ao identificar o termo'} </h2>
          <br />
          {term?.language === 'en' ? 'Please try again in a few minutes or contact the organization' : term?.language === 'es' ? 'Vuelva a intentarlo en unos minutos o póngase en contacto con la organización.' : 'Tente novamente em alguns minutos ou entre em contato com a organização'}

        </p>
      }
    /> </AnimatedDiv>
  }

  const openSpeaker = async (hash, term) => {

    const speaker = await api.get(`get-speaker-data/${hash}`)


    addModal({
      title: '', content: <>

        <Form initialData={{ country: 'BRA', ...term, ...speaker?.data }} onSubmit={(data) => sendUpdate(data, hash)}>
          <aside style={{ padding: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className='greenButton2'>{translate('Salvar')}</button>
          </aside>

          <ImageSingleGalleryInput name='photo' list={[{ name: 'url', label: 'Foto' }]} placeholder="Foto" />
          <Input name='name' placeholder='Nome completo' />
          <Input name='email' placeholder='E-mail' />
          <Input name='phone' placeholder='Telefone com DDD e Código do País' />

          <Input name='company' placeholder='Empresa' />

          <SelectSimple name='country' label='País' options={countries} />

          <Input name='state' placeholder='Estado' />
          <Input name='city' placeholder='Cidade' />

          <RichText name='description' placeholder='Minicurrículo' />
          <aside style={{ padding: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className='greenButton2'>{translate('Salvar')}</button>
          </aside>
        </Form>

      </>, theme: 'whiteModalMedium', key: 'update'
    })




  }

  return (
    <>
      {loading === true && (
        <Loader message={term?.language === 'en' ? 'Loading...' : term?.language === 'es' ? 'Cargando...' : 'Carregando...'}
          position="fixed" />
      )}

      <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', gap: '30px', flexWrap: 'wrap', background: 'rgba(0,0,0,0.3)', width: '100%' }}>
        <>
          {ready && <>
            <div style={{ width: '100%' }}>
              <p style={{ fontSize: '18px', color: '#333', background: '#fff', padding: '15px', textAlign: 'center', marginBottom: '10px' }}>{language === 'en' ? 'Welcome ' : language === 'es' ? 'Bienvenido ' : 'Seja bem-vindo(a) '} <strong>{term?.name}</strong></p>
            </div>





            <AnimatedDiv>


              {term?.status === 'Sim' ? <h2 style={{ background: 'rgb(0,100,0)', color: '#fff', padding: '20px', margin: '10px auto', textAlign: 'center' }}>

                {term?.language === 'en' ? `Your participation is confirmed at the event.` : language === 'es' ? `Tu participación queda confirmada en el evento.` : `Sua participação está confirmada no evento.`}


              </h2> : term?.status === 'Não' ? <h2 style={{ background: 'rgb(100,0,0)', color: '#fff', padding: '20px', margin: '10px auto', textAlign: 'center' }}>

                {term?.language === 'en' ? `You have canceled your participation in the event.` : language === 'es' ? `Has cancelado tu participación en el evento.` : `Você cancelou sua participação no evento.`}


              </h2> :
                <Form ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>





                  <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
                    <br />
                    <p style={{ color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '16px' }}>{language === 'en' ? `Do you confirm your participation in the event?` : language === 'es' ? `¿Confirmas tu participación en el evento?` : `Você confirma sua participação no evento?`}</p>
                    <CheckboxInput
                      style={{ display: 'flex' }}
                      name="status"
                      unique={true}

                      options={[
                        {
                          id: `Sim`,
                          label: language === 'en' ? `Yes` : language === 'es' ? `Si` : `Sim`,
                          value: `Sim`,
                        },
                        {
                          id: `Não`,
                          label: language === 'en' ? `No` : language === 'es' ? `No` : `Não`,
                          value: `Não`,
                        },
                      ]}
                    />

                  </div>
                  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                  </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="defaultButton" type="submit">


                      {language === 'en' ? 'Send confirmation' : language === 'es' ? 'Enviar confirmación' : 'Enviar confirmação'}
                    </button>
                  </aside>
                </Form>}</AnimatedDiv>

            <AnimatedDiv style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>

              <div style={{ maxWidth: '250px', width: '100%', height: '250px', borderRadius: '50%', overflow: 'hidden' }}>
                <img onClick={() => {
                  openSpeaker(term?.hash, term)
                }} style={{ width: '100%', margin: '0px', cursor: 'pointer' }} src={term?.speaker_id_response?.photo ? `${urlWebsite}/${term?.speaker_id_response?.photo}` : '/apoio/user.jpg'} />
              </div>    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <strong>{term?.name}</strong>
                <button onClick={() => {
                  openSpeaker(term?.hash, term)
                }} style={{ cursor: 'pointer' }} className='greenButton2'>{language === 'en' ? 'Update profile ' : language === 'es' ? 'Atualizar persona ' : 'Atualizar perfil'}</button>
              </div>

            </AnimatedDiv>
            <AnimatedDiv>


              <p style={{ color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '16px' }}>{language === 'en' ? `Support files` : language === 'es' ? `Archivos de soporte` : `Arquivos de apoio`}</p>
              {term?.term_id_response?.support_files?.map(item => {

                return <a style={{ margin: '0px' }} href={`${urlWebsite}/${item?.url}`} download><button className="defaultButton"><FaDownload style={{ fontSize: '18px', minWidth: '20px', marginRight: '10px' }} />{item?.[`title_${language}`] ? item?.[`title_${language}`] : item?.[`title`]}</button></a>
              })}
            </AnimatedDiv>

            <Program eventCode={term?.event_id} />


          </>}
        </>

      </aside>
    </>
  );
};

export default FormContainer;
