import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0;
  height: auto;
  margin: 0;

  > div {
    @media print {
      display: none;
    }
  }
  > div.printMessage {
    @media print {
      display: block;
    }
  }
`;
