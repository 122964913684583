import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const AccountIntegration: React.FC = () => {
  const endpoint = '/accounts-integration';
  const title = 'Integração de Contas';
  const formSchema = {

    project: {
      model: 'input',
      name: 'project',
      label: 'Projeto',
      defaultValue: '*',
    },
    url: {
      model: 'input',
      name: 'url',
      label: 'Endpoint',
      defaultValue: '',
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',
      defaultValue: 'custom',
      options: [
        { label: 'Personalizado', value: 'custom' },
        { label: 'Monday', value: 'monday' },
        { label: 'RD Station', value: 'rdstation' },
      ]
    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]

    },
    data: {
      model: 'jsonInputList',
      type: 'text',
      name: 'data',
      label: 'Data',
      show: ['label', 'value'],
      list: [
        { label: 'Coluna', name: 'label', type: 'input' },
        { label: 'Valor', name: 'value', type: 'input' },


      ]
    }
  };


  const formSchemaUpdate = {

    ...formSchema
  };
  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },

    project: { ref: 'project', column: 'project', label: 'Projeto' },
    url: { ref: 'url', column: 'url', label: 'Endpoint' },
    type: { ref: 'type', column: 'type', label: 'Tipo' },
    status: { ref: 'status', column: 'status', label: 'Status' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };



  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default AccountIntegration;
