import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { AiFillMoneyCollect } from 'react-icons/ai';
import Button from '../../../../../../components/Button';
import { useModal } from '../../../../../../hooks/Modal';
import { FaMoneyBill } from 'react-icons/fa';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function RefundSale({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { addLoading, removeLoading } = useLoading();
  const handleSend = async (id: string): Promise<void> => {
    try {
      addLoading({ title: 'Carregando', key: 'estorno' });
      const response = await api.post(`/v3/sale-refund/${id}`);

      removeLoading('estorno');
      removeModal('estorno');
      addToast({ title: 'Enviado com sucesso!', type: 'success' });
    }
    catch (err) {
      removeLoading('estorno');
      handleApiErrors(err);
    }
  };


  const handleDelete = (id: string): void => {
    addModal({
      title: '',
      key: 'estorno',
      content: (<>
        <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Confirmar estorno?</h2>
        <Button type="button" onClick={() => handleSend(id)}>
          Sim
        </Button>
      </>
      ),
      theme: "whiteModalMini"
    });
  };

  const moduleUpdate = (id: string): JSX.Element => (
    lineData?.payment_status_id === 'paid' && (lineData?.payment_method_id === 'credit_card' || lineData?.payment_method_id === 'pix') ? <button className="lineIcon" title="Estornar pagamento" type="button" onClick={() => handleDelete(id)}>
      <FaMoneyBill style={{ color: 'red' }} />
    </button> : <></>
  );

  return moduleUpdate(lineData.sale_hash);
}
