import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/programs';
  const title = 'Programação';

  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    date: { ref: 'date', column: 'date', label: 'Data', mask: 'date' },

    start_hour: {
      ref: 'start_hour',
      column: 'start_hour',
      label: 'Hora de início',
      mask: 'hour',
    },
    end_hour: {
      ref: 'end_hour',
      column: 'end_hour',
      label: 'Hora de encerramento',
      mask: 'hour',
    },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    title: { ref: 'title', column: 'title', label: 'Título' },

    description: {
      ref: 'description',
      column: 'description',
      label: 'Descritivo',
      show: false,
    },

    room_reference_id: {
      ref: 'room_reference_id',
      column: 'room_reference_id_response',
      includeColumn: 'title',
      label: 'Sala',
      filter: true,
    },
    status_id: { ref: 'status_id', column: 'status_id', label: 'Status' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    status_id: {
      model: 'selectSimple',
      name: 'status_id',
      label: 'Status',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ],
    },

    room_reference_id: {
      model: 'input',
      type: 'text',
      name: 'room_reference_id',
      label: 'Sala',
    },

    date: { model: 'datePickerHour', type: 'text', name: 'date', label: 'Data' },
    start_hour: {
      model: 'datePickerHour',
      type: 'text',
      name: 'start_hour',
      label: 'Hora de início',
      mask: 'hora',
    },
    end_hour: {
      model: 'datePickerHour',
      type: 'text',
      name: 'end_hour',
      label: 'Hora de Encerramento',
      mask: 'hora',
    },
    day_reference: { model: 'input', type: 'text', name: 'day_reference', label: 'Texto abaixo da data do dia' },
    category: {
      model: 'input',
      type: 'text',
      name: 'category',
      label: 'Categoria',
    },
    mark: { model: 'input', type: 'text', name: 'mark', label: 'Marcação de Restrição' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: {
      model: 'richText',
      type: 'text',
      name: 'description',
      label: 'Descritivo',
    },
    speakers: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong style={{ color: '#333' }}>


            {data.name}
            <br />
            {data.position ? <>{data.position}<br /></> : <></>}
            <p style={{ color: '#333', fontSize: '10px' }}>{data._id}</p>
          </strong>

        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong style={{ color: '#333' }}>

            {data.name}<br />
            {data.position ? <>{data.position}<br /></> : <></>}
            <p style={{ color: '#333', fontSize: '10px' }}>{data._id}</p>
          </strong>
          <br />

        </>
      ),
      storageType: 'json',
      endpoint: `/speakers`,
      name: 'speakers',
      label: 'Palestrantes',
    },

    video_id: {
      alias: 'video_id',
      model: 'selectApi',
      name: 'video_id',
      label: 'Vídeo de Referência',

      endpoint,
    },

    images: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'images',
      label: 'Fotos',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },
        { label: 'Link', name: 'link_url' },


      ],
    },
  };

  const formSchemaUpdate = {
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
