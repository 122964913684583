import React, { useEffect, useState } from 'react';


import SocialShare, { SocialShareElements } from '../components/ShareOptions';

interface IProps {
  content: Record<string, any>;
}
const ContactItem: React.FC<IProps> = ({ content }) => {

  return <div className="  column-start footerContact " style={{ display: 'flex', gap: '15px', width: '100%', border: '1px solid #ddd', padding: '15px', borderRadius: '5px', maxWidth: '300px', justifyContent: 'space-between' }}>
    <div className='column full-width gap-sm'> <h2 className='paragraph color6  text-center full-width' >
      <strong>{content?.title}</strong><br />
    </h2>
      <p style={{ fontSize: '12px' }} className='color6  text-center' dangerouslySetInnerHTML={{ __html: content?.description || '' }} />
    </div>
    <SocialShareElements list={content?.social} />

  </div>

}

const ContactList: React.FC<Record<string, any>> = ({ theme, pageContent }) => {
  const { hash } = pageContent;
  return (
    <div className='full-width row-to-column flex-wrap gap-sm' style={{ alignItems: 'stretch' }}>

      {pageContent.data_list?.map((item: Record<string, any>) => <ContactItem content={item} />)}


    </div>
  );
};
export default ContactList;
