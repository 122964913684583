import styled from "styled-components";

export const ContainerClassroom = styled.div`
    @media (max-width: 750px) {
       flex-direction: column;

       .classroom-header{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
       }
  }

  .navClassRoom{
    max-width: 130px;
    @media (max-width: 750px) {
      max-width: 100%;
    }
  }
h2{
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-align: left;
    width: 100%;
    max-width:1200px;
    padding: 15px 30px;

    @media (max-width: 750px) {
        font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}
`;