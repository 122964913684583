import React from 'react';

export const scrollToID = (

    targetID2: string,

) => {

    var targetElement = document.getElementById(targetID2);

    // Verificar se o elemento alvo existe
    if (targetElement) {
        // Rolar até o elemento
        if (window.innerWidth <= 768) {
            targetElement.scrollIntoView();
        }
        else {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

}

