import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  background: #fff;
  border-radius: 15px;
  max-width: 600px;
  padding: 20px 20px;
  color: #333;

  z-index: 1;

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;
