const resizeImage = (file: File): Promise<Blob> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const maxWidth = 800; // Desired width
                const scaleFactor = maxWidth / img.width;
                const newWidth = maxWidth;
                const newHeight = img.height * scaleFactor;

                // Create a canvas element to draw the resized image
                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, newWidth, newHeight);
                const mimeType = file.type;
                // Convert the canvas content to a Blob object
                canvas.toBlob((blob) => resolve(blob as Blob), mimeType, 0.9);
            };
            img.src = event?.target?.result as string;
        };
        reader.readAsDataURL(file);
    });
};

export default resizeImage;