import React, { useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container, HeaderButton } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import { FaChartBar, FaQrcode, FaUser } from 'react-icons/fa';

import RecordCheckin from '../../../AreaExpositor/Reporter';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

 
const Checkins: React.FC = () => {
  const endpoint = '/checkin-content';
  const title = 'Checkins';
  const formSchema = {
    
  };


  const formSchemaUpdate = {
   
  };
  const formValidation: Record<string, ValidationSchema> = {
  
  };


  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    room_id: { ref: 'room_id', column: 'room_id', label: 'Referência' },
    extra_1: { ref: 'extra_1', column: 'extra_1', label: 'Nome' },
    extra_2: { ref: 'extra_2', column: 'extra_2', label: 'Email' },
    extra_3: { ref: 'extra_3', column: 'extra_3', label: 'Empresa' },
    extra_4: { ref: 'extra_4', column: 'extra_4', label: 'Telefone' },
    posted_by: {
      ref: 'posted_by',
      column: 'posted_by_response',
      includeColumn: 'email',
      label: 'Leitor',
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
 
    ],
    bodyModules: [
    
    ],
  };

  const configVisitors: ISearchComponent = {
    idKey: '/checkin-content-group',
    title,
    endpoint : '/checkin-content-group',
    schema,
    autoStart: true,
    headModules: [
 
    ],
    bodyModules: [
    
    ],
  };

  const [currentPage,setCurrentPage] = useState('visitors');



  return (
    <Container>

      <HeaderButton>
        <button className={currentPage === 'visitors' ?'active': ''} onClick={() => setCurrentPage('visitors')}><FaUser/> Visitantes</button>
        <button className={currentPage === 'qrcode' ?'active': ''} onClick={() => setCurrentPage('qrcode')}><FaQrcode/> Leituras</button>
        <button className={currentPage === 'chart' ?'active': ''} onClick={() => setCurrentPage('chart')}><FaChartBar/> Gráficos</button>
      </HeaderButton>
 
      { currentPage === 'qrcode' ? <SearchComponent {...config} /> : <></>}
      { currentPage === 'visitors' ? <SearchComponent {...configVisitors} /> : <></>}
      { currentPage === 'chart' ? <RecordCheckin/> : <></>}
    </Container>
  );
};

export default Checkins;
