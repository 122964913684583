import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiPackage, FiSend } from 'react-icons/fi';
import { Form } from '@unform/web';
import Input from '../../../../../../components/Forms/Input';
import Button from '../../../../../../components/Button';
import { useModal } from '../../../../../../hooks/Modal';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();


  const handleSend = async (data): Promise<void> => {

    if (!data?.transaction_id) {

      return addToast({ title: 'Informe um ID de transação', type: 'error' });


    }


    try {
      const response = await api.post(`/v3/recreate-sale-from-log/${lineData._id}`,
        { transaction_id: data.transaction_id });


      addToast({ title: 'Recriado com sucesso!', type: 'success' });
    }
    catch (err) {

      return addToast({ title: err.response.message || err.response.error || 'Erro ao recriar', type: 'error' });

    }
  };

  const { addModal } = useModal();

  const mod = () => {


    addModal({
      title: 'Adicionar',
      content: <>

        <Form onSubmit={handleSend}>
          <Input name="transaction_id" placeholder="ID da transação" />
          <Button>Recriar</Button>
        </Form>

      </>
    })


  }

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Editar" type="button" onClick={() => mod()}>
      <FiPackage />
    </button> : <></>
  );

  return moduleUpdate(lineData.sale_hash);
}
