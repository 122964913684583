import styled from 'styled-components';
import { shade } from 'polished';
import theme from '../../../styles/ThemeComponents';

export const Button = styled.button`
  background: ${theme.button.background} !important;
  color: ${theme.button.color} !important;
  height: 56px;

  border-radius: 0px;
  border: 0;
  padding: 0 5px;
  min-width: 100px;
  max-width: 300px;
  font-weight: 500;
  margin: auto;
  margin-top: 5px;

  transition: 0.2s all;

  border: 1px solid ${theme.button.color} !important;
  border-radius: 10px;
  margin-right: 5px !important;
  margin-left: 5px !important ;
  &:hover {
    background: ${theme.button.backgroundHover} !important;
    color: ${theme.button.colorHover} !important;
  }
`;
