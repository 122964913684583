import React from 'react';

import { FaCheck, FaEye, FaPlus, FaUpload } from 'react-icons/fa';

import { hash } from '../../../../../../config';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({
  lineData
}: ICreateModule): JSX.Element {




  const handleCreate = (): void => {
    window.open(`http://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${lineData.hash}`)
  };

  const moduleCreate = (): JSX.Element => (
    lineData.hash ? <button className="lineIcon" type="button" onClick={handleCreate}>
      <FaEye />
    </button> : <></>
  );

  return moduleCreate();
}
