import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import FormContainer from './FormContainer';
import { useLanguage } from '../../hooks/Language';
import { useConfiguration } from '../../hooks/Configuration';
import { urlWebsite } from '../../config';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const { projectHash, eventHash } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { translate } = useLanguage();
  const { configuration } = useConfiguration();
  const { language } = useLanguage();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container>
      {configuration?.current_event_id_response?.product_heade || configuration?.current_event_id_response?.product_header_image ? <Content>

        <AnimatedDiv>
          {configuration?.current_event_id_response?.product_header ? <p style={{ maxWidth: '400px', fontSize: '12px', padding: '15px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.[`product_header_${language}`] ? configuration?.current_event_id_response?.[`product_header_${language}`] : configuration?.current_event_id_response?.product_header || '' }} /> : <></>}
          {configuration?.current_event_id_response?.product_header_image ? <img style={{ maxWidth: '400px', fontSize: '12px', padding: '15px', textAlign: 'center' }} src={`${urlWebsite}/${configuration?.current_event_id_response?.product_header_image}`} /> : <></>}

        </AnimatedDiv>
      </Content> : <></>}
      <Content>
        <AnimatedDiv>
          <Link to={eventHash ? `/app/${projectHash}/event/${eventHash}/login/` : `/app/${projectHash}/login/`} style={{ color: 'rgb(0,15,64)' }}>
            <FiArrowLeft />
            {translate('Voltar para login')}
          </Link>


          <FormContainer />
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default SignUp;
