export default function calculateCartPrice(cart) {

    const currentValue = cart?.reduce((prev, item) => {


        prev = prev + (parseInt(item?.price, 10) * item?.quantity);
        return prev;

    }, 0);

    return currentValue;


}