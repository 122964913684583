import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import { FaPlus } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

import { ContainerLineUI } from '../../../../../../components/UIComponents/ContainerLine/styles';
import ParagraphDangerous from '../../../../../../components/UIComponents/ParagraphDangerous';
import { TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';

import { urlWebsite } from '../../../../../../config';

interface IList {

    title?: string;
    text?: string;

}

interface IProps {
    list: Array<IList>,
    title?: string;
}
const RenderIcons: React.FC<IProps> = ({ title, list }) => {

    return <>
        <div className='full-width column' style={{ padding: '50px 15px' }}>

        </div>

    </>

}
const RenderPageList: React.FC<IProps> = ({ list, title }) => {

    const [activeIndex, setActiveIndex] = useState(-1);




    return <ContainerLineUI className='full-width max-600 column' style={{ gap: '5px', width: '100%' }}>

        {list.map((item, index) => {
            return <div className='pad row full-width shadow' style={{ justifyContent: 'space-between', transition: '0.5s all', background: '#fff', borderRadius: '5px', alignItems: 'flex-start' }}>
                <div className='column'><h2 style={{ width: '100%', textAlign: 'left', cursor: 'pointer' }} onClick={() => { setActiveIndex(activeIndex === index ? -1 : index) }}>{item?.title}</h2>
                    {activeIndex === index ? <ParagraphDangerous style={{ width: '100%', padding: '10px 0px', textAlign: 'left' }} className='fadeIn' text={item?.text || ''} /> : <></>}
                </div>
                <div onClick={() => { setActiveIndex(activeIndex === index ? -1 : index) }} style={{ minWidth: '40px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <FaPlus style={{ fontSize: '16px', color: '#333', transform: `rotate(${index === activeIndex ? '45deg' : '0deg'})` }} />
                </div>
            </div>

        })}

    </ContainerLineUI>


}

export default RenderPageList;