import { defaultTheme } from 'react-select';
import styled, { keyframes } from 'styled-components';
import themeDefault from '../../styles/ThemeComponents';

interface IBackground {
  background?: string;
}

export const Fixed = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100000;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items:  center;
  background: #fff;
 
  padding: 0px;
  width: 100%;


  margin:  auto;

  .borderBlue {
    border-bottom: 5px solid rgb(0, 100, 150);
  }

  .borderPurple {
    border-bottom: 5px solid rgb(100, 0, 150);
  }

  .borderGreen {
    border-bottom: 5px solid rgb(0, 150, 50);
  }

  .posterSelector {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    
    > form {
      > input {
        padding-left: 10px;
      }

      margin: 0 5px;
      > button {
        margin: 0;
        font-size: 12px;
      }
    }

    > button.active {
      background: #fff !important;
      color: #026271 !important;
    }

    > button {
      margin: 0;
      font-size: 12px;

      @media (max-width: 750px) {
        min-width: auto;
        margin-bottom: 10px;
      }
    }
  }

  

  .posterHeader{
    display: flex ;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100% ;
    max-width: 1200px;
    margin-top: 50px;
    background: #0088c4;
    padding: 50px;
    border-radius: 15px;

    >h1{
      font-size: 18px;
      color: #fff;
      width: 100%;
      text-align: left;
      padding: 0px 15px;
    }

    .separator{
      background: #fff ;
      width: 100%;
      height: 1px;
      margin: 25px 0px;
    }

    @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      }

    .posterSearch {
      display: flex ;
    align-items: center;
    justify-content: stretch;
    width: 100%;
 gap: 15px;
    background: none;
    border-radius: 15px;
    padding: 0px 15px;
     select {
  
font-size: 16px;
font-weight: bold;
      background: #00aaef ;
      
      width: 100% ;
      min-height: 42px;
 
     
      padding: 0px 10px;
      border: 0px;
  
      padding: 0px 15px;
     
      color: #fff;

     
      option{
        color: #333;
        background: #fff !important ;
        padding: 0px 15px;
        min-height: 42px;

        &:hover{
          color: #333;
        background: #fff !important ;
        }

        &:focus{
          color: #333;
        background: #fff !important ;
        }

        &:checked{
          color: #333;
        background: #fff !important ;
        }
      }
    }

    form{
      gap: 15px;
    }


    form>div,>div {
       border: 0px !important;
       margin-top: 0px;
 
      background: #00aaef !important ;
      box-shadow: none !important;
    
      width: 100% ;
      min-height: 42px;
      border-radius: 5px;
     
   
      border: 0px;
  
     
     
      color: #fff;

      .react-select__control{
        border-color:#00aaef !important;
          box-shadow: none !important;
        &:focus{
          border-color:#00aaef !important;
          box-shadow: none !important;
        }
        &:hover{
          border-color:#00aaef !important;
          box-shadow: none !important;
        }
      }

      .react-select__single-value{
        color: #fff;
      }

      .react-select__indicators{
        >span {
          background-color: #fff;
  
        }
        svg{
          color: #fff;
        }

        &:hover{
          >span {
          background-color: #fff;
  
        }
        svg{
          color: #fff;
        }
        }
      }

      svg{
        margin: 0px !important;
      }
    }

    input{
      width: 100% ;
      min-height: 42px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      padding: 0px 10px;
      border: 0px;
      min-width: 250px;
      padding: 0px 15px;
      background: #00aaef ;
      color: #fff;

      &::placeholder {
      color: #eee;
    }

    &:focus{
      border-color: #00aaef !important;
    }
    }

    button{
      width: 120px;
      min-height: 42px;
      border-radius: 5px;
     
      padding: 0px 15px;
     
      border: 0px;
      background:#fff ;
      color: #0088c4;

      >svg{
        min-width: 20px;
        color: #fff;
      }
    }
    @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      }
    }
  }

  .mainPoster {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr 1fr ;

    color : ${themeDefault.color};



  @media (max-width: 1120px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  }

  .mainFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .posterDisplay {
    max-width: 700px;
    width: 100% ;
    min-width: 300px;
    margin: 10px;
    background: none;

    @media (max-width: 1310px) {
      max-width: 600px;
  }

    display: flex ;
    justify-content: flex-start;
    align-items: center;

    color: #fff;
    padding: 10px;

    @media (max-width: 540px) {
      padding: 0px ;
  }
  }



  .posterTitle {
    font-size: 8px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    font-weight: bold;
  }

  .posterAuthor,
  .posterCoAuthor {
    font-size: 8px;
    width: 100%;
   
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .posterImage {
    width: 100%;
  
  }

.posterContentGroup{
   display:flex;
   align-items:stretch;
    justify-content:center;
     position:relative;


}

  .posterImageDiv{
    overflow-y: hidden ;
    height: 224px;
    width: 300px;
    min-width: 300px;
    border-radius: 10px;
    border: 2px solid #fff;

    @media (max-width: 1310px) {
      height: 150px;
    width: 200px;
    min-width: 200px;
  }

  @media (max-width: 540px) {
    width: 120px;
    min-width: 120px;
    height: 90px;
  }
  }

  .starsTime {
    max-width: 100%;
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    color: ${themeDefault.textComponent.title.color};

    svg{
      margin: 0px;
      margin-right: 10px;
      min-width: 15px;
      max-width: 15px;
      margin: 0px 10px;
      color: ${themeDefault.textComponent.title.color};
    }

    @media (max-width: 550px) {

      flex-wrap:  wrap;

      .starCase {
        margin-bottom: 10px;
      }

    svg{
      margin: 0px;
    
      margin-right: 10px;
      min-width: 15px;
      max-width: 15px;
    }
  }

  }

  .posterContent {
    padding: 20px;
    display: flex ;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    max-width: 310px;

    h2{
      font-size: 12px;
      color: ${themeDefault.textComponent.title.color}
    }

    p{
      font-size: 12px;
      color: ${themeDefault.textComponent.paragraph.color};
    }

    @media (max-width: 540px) {
      padding: 10px 20px;
  }
  }


  .posterKeywords{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    >strong{
      padding: 2px 5px ;
      background: ${themeDefault.alternativeButton.background};
      color: ${themeDefault.alternativeButton.color};
      border-radius: 3px;
      margin-right: 3px;
      font-size: 9px;
      font-weight: bold;
    }
  }

  .starCase{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
     

    >svg{
      margin-right: 3px;

    }
  }

  > h2 {
    display: flex;
    justify-content: center;
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 750px) {
    padding: 0px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    > tbody {
      > tr {
        border-top: 1px solid #555;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  button{
    background: #333;
  }
`;
