import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  min-height: auto;
  display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  margin-top: 100px;
  border: 2px solid #ddd;
  padding: 15px ;
  border-radius: 15px;

  .number-pad-row {

 
  display: flex;
 
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 15px;
  margin-bottom: 20px;


    aside {
        display: flex;
 
 justify-content: center;
 align-items: center;
        width: 70px;
        height: 70px;
        padding: 15px;
        font-size: 28px;

    

        cursor: pointer;

        color: #333;
        border: 2px solid #aaa;
        border-radius: 15px;


    }

    .limpar{
        font-size: 18px;
    }

  }

 
`;