import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../DashboardPages/core/components/SearchComponent';

import lineModules from '../DashboardPages/core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import api from '../../services/api';
import { FaFileExcel, FaPaperPlane, FaTicketAlt } from 'react-icons/fa';
import downloadit, { convertToXLSX } from '../../utils/download';
import { useToast } from '../../hooks/Toast';
import states from '../SignUp/FormContainer/states';
import { urlWebsite } from '../../config';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IPropsListCredenciados {
  projectHash: string;

}

const ListCredenciados: React.FC<IPropsListCredenciados> = ({ projectHash }) => {

  const { configuration } = useConfiguration();
  const [confirmados, setConfirmados] = useState(0);
  const { addToast } = useToast();
  const { user, token } = useAuth();
  const load = async () => {

    setInterval(async () => {
      const count = await api.get(`/v3/count-credenciados/${projectHash}`)

      setConfirmados(count?.data?.count || 0);
    }, 3000)

  }



  const handleDownload = async () => {

    if (user?.profile !== 1 && user?.profile !== 4) {
      addToast({ title: 'Download não autorizado', type: 'error' });
      return;
    }

    const response = await api.get(`/v3/list-credenciados/${configuration?.url}`);

    const info = [['Ticket', 'Ingresso', 'Credenciado?', 'Nome', 'Email', 'Telefone', 'CPF', 'Comprador / Cortesia', 'Empresa', 'CNPJ']];
    response?.data?.rows?.map(item => {

      info.push([item?.ticket_hash, item?.ticket, item?.checkin, item?.name, item?.email, item?.phone, item?.document_number, item?.comprador, item?.company, item?.company_document_number])

    })

    convertToXLSX(info)

    addToast({ title: 'Carregando relatório', type: 'success' });
  }

  useEffect(() => {

    load();

  }, [projectHash])

  return <aside style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}><button onClick={() => { handleDownload() }} className='defaultButton'><FaFileExcel /> {`${confirmados} credenciados`}</button>
    {/* <button onClick={() => { handleDownloadNoCourtesy() }} className='defaultButton'><FaFileExcel /> {`Sem Cortesia`}</button> */}
  </aside>

}


const AreaAtendimento: React.FC = () => {
  const { configuration } = useConfiguration();
  const endpoint = `/v3/search-credenciados/${configuration?.url}`;
  const title = 'Inscritos';
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    client_id: { ref: 'client_id', column: 'client_id', label: 'ID do cliente', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
    ticket_hash: { ref: 'ticket_hash', column: 'ticket_hash', label: 'Ticket' },
    ticket: {
      ref: 'ticket',
      column: 'ticket',
      label: 'Ingresso',
    },
    checkin: { ref: 'checkin', column: 'checkin', label: 'Credenciado?' },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    category_id: { ref: 'category_id', column: 'category_id', label: 'Categoria', show: false },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone', show: false },
    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF'
    },
    comprador: { ref: 'comprador', column: 'comprador', label: 'Comprador / Cortesia' },


    company: {
      ref: 'company',
      column: 'company',
      label: 'Empresa',

    },


    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date', show: false
    },
  };

  const formSchema = {



    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },


    company: { model: 'input', type: 'text', name: 'company', label: 'Empresa' },

  };

  const formSchemaCheckin = {



    checkin: {
      model: 'selectSimple',
      name: 'checkin',
      label: 'Credenciado',
      defaultValue: 'Não',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },

      ]
    }

  };

  const formSchemaCheckinAdmin = {



    checkin: {
      model: 'selectSimple',
      name: 'checkin',
      label: 'Credenciado',
      defaultValue: 'Não',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },

      ]
    },
    client_id: {
      alias: 'client_id',
      model: 'selectApi',
      name: 'client_id',
      label: 'Cliente',
      endpoint: 'sales-products',
    },

    product_id: {
      alias: 'product_id',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto',

      endpoint: 'sales-products',
    },

  };



  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    showExcel: false,
    showPagination: false,
    headModules: [

      () =>
        lineModules.crachaLote({
          idKey: endpoint
        }),
      () =>
        lineModules.ChangeCrachaConfig(),
      () =>
        lineModules.UploadStudents(),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineData?.client_id ? lineModules.updateUnique({
          lineData: { _id: lineData?.client_id },
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint: 'students',
        }) : <></>,
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData: lineData,
          title,
          formSchema: user?.profile === 1 ? formSchemaCheckinAdmin : formSchemaCheckin,
          validationSchema: {},
          endpoint: 'sales-products',
          Icon: FaTicketAlt
        }),


      /* (lineData: Record<string, any>) =>
         AplicarCortesia({
           lineData,
 
         }), */
      (lineData: Record<string, any>) =>
        lineModules.cracha({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint: 'students',
        }),


      (lineData: Record<string, any>) =>
        lineModules.credenciar({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint: 'students',
        }),
      (lineData: Record<string, any>) =>
        lineModules.Balls({
          lineData,

        }),
    ],
  };

  if (!user || (user?.profile !== 1 && user?.profile !== 4 && user?.profile !== 35)) {
    window.location.href = '/';
  }


  config.bodyModules?.push((lineData: Record<string, any>) => <a href={`${urlWebsite}/app/${lineData?.project_id}/validar-ingresso/${lineData?.ticket_hash}`} target="_BLANK"> <button style={{ padding: '5px' }} title='Transferir Ingresso' className='greenButton2 buttonColored lineIcon' ><FaPaperPlane style={{ color: '#fff', fontSize: '18px' }} /></button></a>)




  return (
    <Container>
      <ListCredenciados projectHash={projectHash} />
      <SearchComponent {...config} />
    </Container>
  );
};

export default AreaAtendimento;
